import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-auditLogs.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AdminAuditLogsService } from '../services/admin-auditLogs.service';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminAuditLogsEffects');

@Injectable()
export class AdminAuditLogsEffects {
    constructor(
        private actions$: Actions,
        private service: AdminAuditLogsService,
    ) {}

    getAuditLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAuditLogs.type),
            mergeMap((payload) => {
                return this.service.getAuditLogs(payload).pipe(
                    map((response) => {
                        return fromActions.onGetAuditLogsSuccessfully({ payload: response });
                    }),
                    catchError((error) => {
                        return of(fromActions.onGetAuditLogsFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnGetAuditLogs$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onGetAuditLogsFailed.type),
                tap((action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)),
            ),
        { dispatch: false },
    );

    handleAllErrors(error) {
        debug(error);
        return error;
    }
}
