import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminManageProductCategoryService } from '../services/admin-manageProductCategory.service';
import * as fromActions from './../actions/admin-manageProductCategory.actions';
import * as fromFeature from './../reducers/admin.reducer';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminManageProductCategoryEffects');

@Injectable()
export class AdminManageProductCategoryEffects {
    constructor(
        private actions$: Actions,
        private service: AdminManageProductCategoryService,
    ) {}

    getAllProductCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllProductCategory.type),
            mergeMap(() => {
                return this.service.getAllCategories().pipe(
                    map((response: any) => {
                        return fromActions.onGetAllProductCategorySuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onGetAllProductCategoryFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    createCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.createCategory.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.create(payload).pipe(
                    map((response: any) => {
                        return fromActions.onCreateCategorySuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onCreateCategorySuccessfully({ payload: error }));
                    }),
                );
            }),
        ),
    );

    editCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateCategory.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.update(payload).pipe(
                    map((response: any) => {
                        return fromActions.onUpdateCategorySuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUpdateCategoryFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    deleteCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteCategory.type),
            mergeMap((payload: any) => {
                debug(payload);
                return this.service.delete(payload).pipe(
                    map((response: any) => {
                        return fromActions.onDeleteCategorySuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onDeleteCategoryFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnProductCategory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    fromActions.onGetAllProductCategoryFailed.type,
                    fromActions.onCreateCategoryFailed.type,
                    fromActions.onUpdateCategoryFailed.type,
                    fromActions.onDeleteCategoryFailed.type,
                ),
                tap((action: fromActions.IActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllCartErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}
