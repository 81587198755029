export class ProductContentUpdate {
    public locale: string;
    public description: string;
}

export class ProductContentUpdateRequest {
    constructor(
        public productLineName: string,
        public locale: string,
        public description: string,
    ) {}
}
export class ProductContentUpdateResponse {
    public productLineName: string;
    public locale: string;
    public description: string;
}

export class ProductContentUpdateRequestModel {
    constructor(
        public locale: string,
        public description: string,
    ) {}
}
