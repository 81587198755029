import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, map, take } from 'rxjs';
import { CouponFilterDTO, CouponListingDTO } from '@modeso/types__twint-lib-coupons';
import { Output, EventEmitter } from '@angular/core';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { ConfirmFeatureDialog } from '../../dialog/confirmFeatureDialog/confirmFeatureDialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ExportCostFileDialog } from '../../dialog/ExportCostFileDialog/export-cost-file-dialog.component';
import { ExportCostFileType } from '../../models/enums/export-cost-file-type.enum';
import { ExportCostFileRequest } from '../../models/interfaces/export-cost-file-request.interface';
import { IFinancingParty } from '../../models/interfaces/finance';
import { ExportCostFile } from '../../models/interfaces/export-cost-file.interface';

@Component({
    selector: 'app-page-manage-coupons',
    templateUrl: './manage-coupons.page.html',
    styleUrls: ['./manage-coupons.page.scss'],
})
export class ManageCouponsPage implements OnInit, OnDestroy {
    dataSource = new MatTableDataSource<CouponListingDTO>();
    coupons$: Observable<CouponListingDTO[]> | undefined;
    couponsFeature: boolean;
    couponsFeature$: Observable<boolean>;
    initializationState$: Observable<boolean>;
    initializationState: boolean = false;
    navLinks;
    activeLink;
    archiveState;
    searchErrors: boolean = false;
    searchForm: FormGroup;
    private couponFilterData: CouponFilterDTO = {};
    @Input() hasReadPermission = false;
    @Input() hasWritePermission = false;
    @Output() addCouponClick = new EventEmitter<string>();
    protected subscriptions = [];

    constructor(
        injector: Injector,
        private couponProvider: TwintCouponsProvider,
        public dialog: MatDialog,
    ) {
        // dispatch fetching coupons action
        this.checkCouponsFeature();

        // get coupons from store as observable and map it to set paginator
        this.coupons$ = this.couponProvider.getCoupons$();
        this.couponsFeature$ = this.getCouponsFeature();
        this.initializationState$ = this.couponProvider.getCouponsFeatureInitializationState$();
        this.subscriptions.push(
            this.couponsFeature$.subscribe((state) => {
                this.couponsFeature = state;
            }),
        );
        this.searchForm = new FormGroup({ couponName: new FormControl(), couponCode: new FormControl() });
        this.subscriptions.push(
            this.initializationState$.subscribe((state) => {
                setTimeout(() => {
                    this.initializationState = state;
                }, 250);
            }),
        );
    }

    ngOnInit() {
        this.populateNavLinks();
        this.fetchCoupons();
        this.couponProvider.dispatchGetFinancingParties();
    }

    addCoupon() {
        this.addCouponClick.emit('OpenAddCoupon');
    }

    toggleCouponsFeature() {
        const dialogRef = this.dialog.open(ConfirmFeatureDialog, { data: { activate: !this.couponsFeature } });

        this.subscriptions.push(
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.couponProvider.toggleCouponsFeature(!this.couponsFeature);
                }
            }),
        );
    }

    checkCouponsFeature() {
        return this.couponProvider.checkCouponsFeature();
    }
    getCouponsFeature() {
        return this.couponProvider.getCouponsFeature$();
    }

    populateNavLinks(): void {
        this.navLinks = [
            { label: 'Coupons', link: 'all', index: 0, archiveState: false },
            { label: 'Archived Coupons', link: 'archived', index: 1, archiveState: true },
        ];
        this.activeLink = this.navLinks[0].link;
        this.archiveState = this.navLinks[0].archiveState;
    }

    changeNavigation(archiveState): void {
        this.activeLink = this.navLinks.find((link) => link.archiveState === archiveState).link;
        this.archiveState = archiveState;
    }

    changeSortColumnAndDirection(data: CouponFilterDTO): void {
        this.fetchCoupons(data);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscribe) => {
            subscribe.unsubscribe();
        });
    }

    searchCoupons(): void {
        if (this.checkIfFormIsValid()) {
            // If both are filled, return an error and nullify their values
            if (
                this.couponName != null
                && this.couponName.length > 0
                && this.couponCode != null
                && this.couponCode.length > 0
            ) {
                this.searchForm.get('couponName')?.setValue(null);
                this.searchForm.get('couponCode')?.setValue(null);
                this.searchErrors = true;
            } else {
                this.fetchCoupons({ couponCode: this.couponCode, couponName: this.couponName });
                this.searchErrors = false;
            }
        }
    }

    clearSearchResults(): void {
        this.searchForm.get('couponName')?.setValue(null);
        this.searchForm.get('couponCode')?.setValue(null);
        this.fetchCoupons({ couponCode: this.couponCode, couponName: this.couponName });
        this.searchErrors = false;
    }

    exportCostFile(type: ExportCostFileType): void {
        const loading$ = this.couponProvider.getSuccessState$();
        const error$ = this.couponProvider.getExportErrorState$();
        const success$ = this.couponProvider.getExportSuccessState$();
        const financialParties$ = this.couponProvider.selectFinancingParties();
        const title = type === ExportCostFileType.FULL ? 'Export Cost Report' : 'Export Cost Report Summary';

        const dialogRef = this.dialog.open(ExportCostFileDialog, {
            // this is added as it focuses the mat select as well when the dialog opens
            autoFocus: false,
            disableClose: true,
            data: {
                title,
                coupons: this.coupons$.pipe(
                    map((coupons: CouponListingDTO[]) =>
                        coupons.map((coupon) => ({ id: coupon.id, name: coupon.name })),
                    ),
                ),
                financeParties: financialParties$.pipe(
                    map((financeParties: IFinancingParty[]) =>
                        financeParties.map((financeParty) => ({ id: financeParty.name, name: financeParty.name })),
                    ),
                ),
                loading: loading$,
                error: error$,
                success: success$,
                export: (data: ExportCostFileRequest): void => {
                    this.couponProvider.exportCostFile({ ...data, type });
                },
            } as ExportCostFile,
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.couponProvider.resetExportCostState();
            });
    }

    private fetchCoupons(data?: CouponFilterDTO): void {
        if (data != null) {
            this.couponFilterData = { ...this.couponFilterData, ...data };
        }

        return this.couponProvider.fetchCoupons(this.couponFilterData);
    }

    private checkIfFormIsValid(): boolean {
        const couponNameValue = this.couponName?.trim();
        const couponCodeValue = this.couponCode?.trim();
        // Enable the submit button if at least one field has a value
        return couponNameValue?.length > 0 || couponCodeValue?.length > 0;
    }

    private get couponName(): string {
        return this.searchForm.get('couponName')?.value;
    }

    private get couponCode(): string {
        return this.searchForm.get('couponCode')?.value;
    }

    get exportCostType(): typeof ExportCostFileType {
        return ExportCostFileType;
    }
}
