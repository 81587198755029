/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminMerchantProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-merchant.provider';
import { IMerchant, IMerchantResponse, MerchantType } from '@modeso/types__dgoods-products';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-create-merchant',
    templateUrl: './create-merchant.page.html',
    styleUrls: ['./create-merchant.page.scss'],
})
export class CreateMerchantPage implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    merchantFrom: FormGroup;
    subscription = new Subscription();
    merchantObj: any;
    showDuplicateTypeErroMessage = false;
    merchantType: string;
    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private adminMerchantProvider: AdminMerchantProvider,
    ) {}
    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
    ngOnInit(): void {
        this.createFrom();
        const merchantId = this.activatedRoute.snapshot.params.id;
        if (merchantId) {
            this.adminMerchantProvider
                .getMerchantList()
                .pipe(takeUntil(this.destroyed$))
                .subscribe((merchants: IMerchantResponse[]) => {
                    if (merchants && merchants.length) {
                        this.merchantObj = merchants.find((merchant) => merchant.externalMerchantId === merchantId);
                        if (this.merchantObj) {
                            this.populateFields(this.merchantObj);
                        }
                    }
                });
        }
    }

    createFrom() {
        this.merchantFrom = this.formBuilder.group({
            name: this.formBuilder.control('', Validators.required),
            externalMerchantId: this.formBuilder.control('', Validators.required),
            type: this.formBuilder.control('', Validators.required),
        });
    }

    populateFields(merchant) {
        const { name, externalMerchantId, type } = merchant;

        this.merchantFrom.setValue({
            name: name || null,
            externalMerchantId: externalMerchantId || null,
            type: type || null,
        });
    }

    saveMerchant() {
        const merchant: IMerchant = {
            name: this.merchantFrom.value.name.trim(),
            internalMerchantId: this.merchantObj ? this.merchantObj.internalMerchantId : uuidv4(),
            externalMerchantId: this.merchantFrom.value.externalMerchantId.trim(),
            type: this.merchantFrom.value.type,
        };
        this.merchantType = merchant.type;
        this.adminMerchantProvider.createOrUpdateMerchant$(merchant);

        this.adminMerchantProvider
            .getError$()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((error) => {
                if (error.error.duplicateKey) {
                    this.showDuplicateTypeErroMessage = true;
                }
            });
    }

    get MerchantType(): typeof MerchantType {
        return MerchantType;
    }
}
