<div class="container" *ngIf="!hasWritePermission()">Sorry you don't have read permission.</div>
<div class="container" *ngIf="hasWritePermission()">
    <form class="changePassword-form" [formGroup]="changePasswordForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Current Password</label>
                    <div class="input-wrapper">
                        <input
                            id="currentPassword"
                            class="form-control"
                            type="password"
                            formControlName="currentPassword"
                            placeholder="Current Password"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>New Password</label>
                    <div class="input-wrapper">
                        <input
                            id="newPassword"
                            class="form-control"
                            type="password"
                            formControlName="newPassword"
                            placeholder="New Password"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-group">
                    <label>Confirm Password</label>
                    <div class="input-wrapper">
                        <input
                            id="confirmNewPassword"
                            class="form-control"
                            type="password"
                            formControlName="confirmNewPassword"
                            placeholder="Confirm Password"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row messages">
            <div class="col-lg-12">
                <span class="success" *ngIf="isPasswordChangedSuccessfully"
                    >{{passwordChangedSuccessfullyMessage}}</span
                >
                <span class="failure" *ngIf="getMatchedPasswords()">{{passwordAndConfirmedNotMatchedErrorMsg}}</span>
                <span class="failure" *ngIf="isBadRequest || isCurrentPasswordWrong">{{errorMessage}}</span>
                <span class="failure" *ngIf="isNewPasswordWeak">{{passwordWeakErrorMessage}}</span>
                <span class="failure" *ngIf="isServerError">{{serverError}}</span>
            </div>
        </div>

        <div class="login-button">
            <button id="saveAddressButton" [disabled]="buttonStatus()" (click)="onSubmit()">Submit</button>
        </div>
    </form>
</div>
