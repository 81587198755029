import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pascalcase' })
export class PascalCasePipe implements PipeTransform {
    transform(value: string) {
        return value
            .split(/ |_/)
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
}
