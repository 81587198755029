import { Component, TemplateRef, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
    IDGoodsPriceOptionDelegate,
    DGoodsPriceOptionModel,
} from '../dgoods-price-option/dgoods-price-option.component';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsPriceOptionGroupComponent');

export interface IDGoodsPriceOptionGroupDelegate {
    onPriceSelected(priceOption: DGoodsPriceOptionModel): void;
    onPriceUnselected(priceOption: DGoodsPriceOptionModel): void;
}

@Component({
    selector: 'dgoods-price-option-group',
    templateUrl: './dgoods-price-option-group.component.html',
    styleUrls: ['./dgoods-price-option-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DGoodsPriceOptionGroupComponent implements IDGoodsPriceOptionDelegate, OnInit {
    @Input() templateRef: TemplateRef<DGoodsPriceOptionModel>;
    @Input() prices: DGoodsPriceOptionModel[] = [];
    @Input() delegate?: IDGoodsPriceOptionGroupDelegate;

    constructor() {}

    ngOnInit(): void {
        // sort by value then sort by denomenation value
        this.prices.sort((a, b) => (a.value && a.value > b.value ? 1 : -1));
        this.prices.sort((a, b) => (a.isDenomination as any) - (b.isDenomination as any));
    }

    private filterByReference(reference: string): DGoodsPriceOptionModel {
        let selectedPriceOptions = this.prices.filter(
            (element: DGoodsPriceOptionModel) => element.reference === reference,
        );
        let selectedPriceOption;
        if (selectedPriceOptions.length === 0) {
            selectedPriceOptions = this.prices.filter(
                (element: DGoodsPriceOptionModel) => element.isDenomination === true,
            );
            if (selectedPriceOptions.length === 0) {
                debug('could find the selected price... and there is no denomination field!');
                return undefined;
            } else {
                selectedPriceOption = selectedPriceOptions[0];
            }
        } else {
            selectedPriceOption = selectedPriceOptions[0];
        }
        return selectedPriceOption;
    }

    onPriceSelected(price: number, reference: string) {
        debug('onPriceSelected - ' + price + ' - ' + reference);
        this.prices.forEach((value: DGoodsPriceOptionModel) => (value.selected = false));
        const selectedPriceOption = this.filterByReference(reference);
        selectedPriceOption.selected = true;
        if (selectedPriceOption.isDenomination) {
            selectedPriceOption.value = price;
        }
        if (this.delegate) {
            this.delegate.onPriceSelected(selectedPriceOption);
        } else {
            debug('onPriceUnselected event not fired. There is no delegate attached to the component.');
        }
        debug('selected price:' + selectedPriceOption.value);
        this.prices = this.prices.slice(0);
    }

    onPriceUnselected(price: number, reference: string) {
        debug('onPriceUnselected - ' + price + ' - ' + reference);
        debug('#########');
        debug(this.prices);
        this.prices.forEach((value: DGoodsPriceOptionModel) => (value.selected = false));
        const priceOption = this.filterByReference(reference);
        if (this.delegate) {
            this.delegate.onPriceUnselected(priceOption);
        } else {
            debug('onPriceUnselected event not fired. There is no delegate attached to the component.');
        }
        this.prices = this.prices.slice(0);
    }

    hasEvenPrices() {
        return this.prices.length % 2 === 0;
    }
}
