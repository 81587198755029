import { createAction, props, Action } from '@ngrx/store';
import { SortingOrderRequest } from '@modeso/types__dgoods-products';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductsStateActionsSource } from '@modeso/dgoods-lib-admin-fe';

export const onCreatOrUpdateSortingOrder = createAction(
    '[TDV SortingOrder] CreatOrUpdateSortingOrder',
    props<{ payload: SortingOrderRequest }>(),
);
export const onCreatOrUpdateSortingOrderSuccessfully = createAction(
    '[TDV SortingOrder] onCreatOrUpdateSortingOrderSuccessfully',
);

export const onCreatOrUpdateSortingOrderFailed = createAction(
    '[TDV SortingOrder]  onCreatOrUpdateSortingOrderFailed',
    props<{ payload: HttpErrorResponse; source: ProductsStateActionsSource.SORTING_PRODUCTS }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
