<ng-container>
    <div class="form" *ngIf="merchantFrom" [formGroup]="merchantFrom">
        <div class="mb-2">Merchant Detail</div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>
                    Name
                    <span class="input_required">*</span>
                </mat-label>
                <input formControlName="name" matInput />
                <mat-error> Name is Required </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>
                    External Merchant Id
                    <span class="input_required">*</span>
                </mat-label>
                <input formControlName="externalMerchantId" matInput />
                <mat-error> External Merchant Id is Required </mat-error>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field appearance="fill">
                <mat-label>Merchant Type</mat-label>
                <select matNativeControl formControlName="type">
                    <option *ngFor="let enum of MerchantType | keyvalue" [value]="enum.value">{{enum.key}}</option>
                </select>
            </mat-form-field>

            <!---->
        </div>
    </div>
    <button class="btn save-btn m-3" [disabled]=" merchantFrom.invalid" (click)="saveMerchant()">Save</button>

    <div *ngIf="showDuplicateTypeErroMessage">
        <mat-error>Merchant of type {{ merchantType}} already exists</mat-error>
    </div>
</ng-container>
