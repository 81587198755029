import { Injectable } from '@angular/core';
import * as fromActions from '../actions/admin-newsticker.action';
import * as fromFeature from '../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { INewsticker, INewstickerResponse } from '@modeso/types__dgoods-products';
import { map } from 'rxjs/operators';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminNewsTickerProvider');
@Injectable({ providedIn: 'root' })
export class AdminNewstickerProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public addNewsticker(newsticker: INewsticker) {
        this.store.dispatch(fromActions.addNewstickers({ payload: newsticker }));
    }

    public updateNewsticker(newsticker: INewstickerResponse) {
        this.store.dispatch(fromActions.updateNewsticker({ payload: newsticker }));
    }

    public getNewstickerList(): Observable<INewstickerResponse[]> {
        this.store.dispatch(fromActions.getAllNewstickers());
        return this.store.pipe(select(fromFeature.selectFeatureNewstickerList));
    }

    public getError$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureError),
            map((error: any) => {
                return error;
            }),
        );
    }
}
