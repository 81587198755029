import { Component, Injector, OnInit } from '@angular/core';
import { AdminManageProductContentProvider, ManageProductsResponse } from '@modeso/dgoods-lib-admin-fe';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ProductLineForManagementDto } from '@modeso/types__dgoods-products';
import { SettingsEditDialogComponent } from './confirmation-dialog.component';
import { UpdateProductSettingsRequest } from '@modeso/dgoods-lib-admin-fe/models/manageProductContent/updateProductSettings.model';

const debug = Debug('modeso:dgoods-admin:productSettings');
@Component({
    selector: 'app-product-settings',
    templateUrl: './productSettings.page.html',
    styleUrls: ['./productSettings.page.scss'],
})
export class ProductSettingsPage extends BasePageComponent implements OnInit {
    products: ProductLineForManagementDto[] = [];
    displayedColumns: string[] = ['productLineName', 'couponsEnabled', 'displayOnIOS', 'displayOnAndroid', 'Action'];

    isError = false;
    errorMessage = 'Error occured';

    isSettingsUpdatedSuccesfully = false;
    settingsUpdatedSuccsfullyMessage = 'Settings Updated successfully';

    constructor(
        injector: Injector,
        private adminManageProductProvider: AdminManageProductContentProvider,
        private localStorageService: LocalStorageService,
        public dialog: MatDialog,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getAllProducts();
        this.initErrors();
    }

    getAllProducts() {
        this.subscriptions.push(
            this.adminManageProductProvider.getAllProducts$().subscribe((response: ManageProductsResponse) => {
                debug(response);
                if (response.action == 'UPDATE_SETTINGS') {
                    this.isSettingsUpdatedSuccesfully = true;
                }
                this.isError = false;
                this.products = response.products;
            }),
        );
    }

    displayDialog(product: ProductLineForManagementDto) {
        const dialogRef = this.dialog.open(SettingsEditDialogComponent, { data: { product } });

        this.subscriptions.push(
            dialogRef.afterClosed().subscribe((result: ProductLineForManagementDto) => {
                debug('afterClosed');
                if (result) {
                    const requestData: UpdateProductSettingsRequest = {
                        productLineName: result.productLineName,
                        displayOnIOS: result.displayOnIOS,
                        displayOnAndroid: result.displayOnAndroid,
                        couponsEnabled: result.couponsEnabled,
                    };

                    this.adminManageProductProvider.updateSettingsByProductLine$(requestData);
                }
            }),
        );
    }

    initErrors() {
        this.subscriptions.push(
            this.adminManageProductProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {
                this.isError = true;
                this.isSettingsUpdatedSuccesfully = false;
            }),
        );
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
