<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="row">
        <div class="col-lg-3">
            <div>
                <h4>Select Action</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Actions</mat-label>
                    <mat-select [formControl]="actionControl" (selectionChange)="getSelectedAction($event)" required>
                        <mat-option *ngFor="let action of actions" [value]="action"> {{action}} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="actionControl.hasError('required')">Please choose an action</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-3">
            <div>
                <h4>Select Time Filter</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Time Filter</mat-label>
                    <mat-select
                        [formControl]="timeFrameControl"
                        (selectionChange)="getSelectedTimeFilter($event)"
                        required
                    >
                        <mat-option *ngFor="let timeFrame of timeFrames" [value]="timeFrame">
                            {{timeFrame}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeFrameControl.hasError('required')">Please choose a time period</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-3">
            <div>
                <h4>Select Date</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Select Date</mat-label>
                    <input matInput [matDatepicker]="datepicker" [(ngModel)]="selectedDate" disabled />
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-3">
            <div>
                <h4>Select Time</h4>
                <mat-form-field appearance="fill" class="custom_mat_form_time">
                    <div class="time-picker">
                        <input
                            matInput
                            [ngxTimepicker]="toggleTimepicker"
                            [disableClick]="true"
                            readonly
                            [format]="24"
                            disabled
                            #timepicker
                            placeholder="Select time"
                        />
                        <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
                    </div>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="search-container">
            <div class="search-button">
                <button id="searchButton" [disabled]="buttonStatus()" (click)="onSearch()">Search</button>
            </div>
        </div>
    </div>

    <div class="row errorMessages">
        <span class="error-text" *ngIf="isError">{{errorMessage}}</span>
    </div>

    <div *ngIf="auditLogs" class="table-container">
        <table mat-table [dataSource]="auditLogsdataSource">
            <!-- UserId Column -->
            <ng-container matColumnDef="UserId">
                <th mat-header-cell *matHeaderCellDef>User Id</th>
                <td mat-cell *matCellDef="let element">{{element.userId}}</td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">{{element.action}}</td>
            </ng-container>

            <!-- Timestamp Column -->
            <ng-container matColumnDef="Timestamp">
                <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                <td mat-cell *matCellDef="let element">{{element.timestamp}}</td>
            </ng-container>

            <!-- Payload Column -->
            <ng-container matColumnDef="Payload">
                <th mat-header-cell *matHeaderCellDef>Payload</th>
                <td mat-cell *matCellDef="let element">{{element.payload}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="auditLogsColmuns;  sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: auditLogsColmuns;"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
    </div>
</div>
