import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromActions from '../actions/admin.fraudDetectionProducts.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { IBlockedProductModel } from '../models/fraudDetectionProducts/blockedProducts.model';
import { UnBlockProductResponse } from '../services/admin.fraudDetectionProducts.service';
import { HttpErrorResponse } from '@angular/common/http';
const debug = Debug('dgoods-lib-admin-fe');
@Injectable({ providedIn: 'root' })
export class AdminFraudDetectionProductsProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public getBlockedProducts$(): Observable<any> {
        this.store.dispatch(fromActions.getBlockedProducts());
        return this.store.pipe(
            select(fromFeature.selectFeatureProducts),
            map((productsResponse: IBlockedProductModel) => {
                if (productsResponse) {
                    return productsResponse;
                } else {
                    debug('get blocked products response is undefined');
                    return undefined;
                }
            }),
        );
    }
    public unBlockProduct$(productLineName: string): Observable<any> {
        this.store.dispatch(fromActions.unBlockProduct({ payload: productLineName }));
        return this.store.pipe(
            select(fromFeature.selectFeatureUnBlockResponse),
            filter(
                (unblockStatus: UnBlockProductResponse) =>
                    unblockStatus.message != null && unblockStatus.message !== '',
            ),
        );
    }

    public unblockShop$(): Observable<UnBlockProductResponse> {
        this.store.dispatch(fromActions.unblockShop());
        return this.store.pipe(
            select(fromFeature.selectFeatureUnBlockResponse),
            filter(
                (unblockStatus: UnBlockProductResponse) =>
                    unblockStatus.message != null && unblockStatus.message !== '',
            ),
        );
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(
            select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null),
        );
    }
}
