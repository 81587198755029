<div mat-dialog-content>
    <form class="update-user-by-id-form" [formGroup]="updateByKeyForm">
        <div class="form-group">
            <label>Name</label>
            <div class="input-wrapper">
                <input id="name" class="form-control" type="text" formControlName="name" />
            </div>
        </div>

        <div class="form-group">
            <label>Role</label>
            <div class="input-wrapper">
                <select id="role" class="form-control" formControlName="role">
                    <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
                </select>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="dialog-buttons">
    <div class="action-button">
        <button mat-button (click)="onNoClick()" class="button">Cancel</button>
        <button
            mat-button
            [mat-dialog-close]="data"
            [disabled]="buttonStatus()"
            cdkFocusInitial
            (click)="save()"
            class="button"
        >
            Confirm
        </button>
    </div>
</div>
