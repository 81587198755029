<form [formGroup]="formGroup">
    <div class="center-content">
        <h2 mat-dialog-title>Add financing party</h2>

        <div class="flex-row-4">
            <mat-label class="label">Finance party name</mat-label>
            <mat-form-field appearance="outline">
                <input formControlName="name" matInput placeholder="party name" />
                <mat-error *ngIf="name.errors?.required">This is a mandatory field. Please update and retry.</mat-error>
                <mat-error *ngIf="name.invalid && !name.errors?.required"
                    >The input does not match with the defined criteria. Please update the entry.</mat-error
                >
            </mat-form-field>
        </div>

        <mat-dialog-actions>
            <button [disabled]="formGroup.invalid" mat-button (click)="confirm()">Ok</button>
            <button mat-button (click)="cancel()">Cancel</button>
        </mat-dialog-actions>
    </div>
</form>
