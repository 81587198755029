<div class="dgoods-table-cell">
    <div class="dgoods-table-cell-inner-row">
        <span class="dgoods-table-cell-title">{{ datasource.title }}</span>
        <span class="dgoods-table-cell-amount"> CHF {{ datasource.amount | number: '2.2-2' }}</span>
    </div>
    <div class="dgoods-table-cell-inner-row">
        <div class="dgoods-table-cell-date-reference">
            <span *ngIf="datasource.discounts" style="margin-right: 2px">{{
                'dgoods_shop_add_code_placeholder' | injectedText | async
            }}</span>
            <span *ngIf="datasource.discounts?.discountPercentage"
                >(-{{ datasource.discounts.discountPercentage.toFixed(0) }}%)</span
            >
            <span class="reference"
                >{{ 'dgoods_shared_tablecellcompontent_reference' | injectedText | async }}
                {{ datasource.reference | slice: 0 : 8 }}</span
            >
            <span>{{ datasource.date | date: 'dd.MM.yyyy, HH:mm' }}</span>
        </div>

        <div class="dgoods-table-cell-date-reference">
            <div class="dgoods-table-cell-discount" *ngIf="datasource.discounts">
                -CHF {{ datasource.discounts.discountAmount | number: '1.2-2' }}
            </div>
            <div class="dgoods-table-cell-link" (click)="onClick()">
                {{ 'dgoods_shared_tablecellcompontent_code' | injectedText | async }}
            </div>
        </div>
    </div>
</div>
