import { Component, OnInit, Input } from '@angular/core';
import Debug from 'debug';

const debug = Debug('dgoods:shared:DGoodsTutorialOverlayComponent');

export interface IDGoodsTutorialOverlayDelegate {
    onOverlayButtonClicked(): void;
}
@Component({
    selector: 'dgoods-tutorial-overlay',
    templateUrl: './dgoods-tutorial-overlay.component.html',
    styleUrls: ['./dgoods-tutorial-overlay.component.scss'],
})
export class DGoodsTutorialOverlayComponent implements OnInit {
    @Input() delegate?: IDGoodsTutorialOverlayDelegate;
    @Input() hidden: boolean;

    tutorialData = [
        {
            image: 'assets/icn-overlay-digitalgoods.svg',
            number: '1',
            title: 'dgoods_shared_tutorialoverlaycompontent_title1',
            description: 'dgoods_shared_tutorialoverlaycompontent_description1',
        },
        {
            image: 'assets/icn-overlay-phone-hand.svg',
            number: '2',
            title: 'dgoods_shared_tutorialoverlaycompontent_title2',
            description: 'dgoods_shared_tutorialoverlaycompontent_description2',
        },
        {
            image: 'assets/icn-overlay-code.svg',
            number: '3',
            title: 'dgoods_shared_tutorialoverlaycompontent_title3',
            description: 'dgoods_shared_tutorialoverlaycompontent_description3',
        },
    ];
    constructor() {}

    ngOnInit() {}

    /**
     * click on button , tutorial overlay disappear
     */
    onOverlayButtonclick() {
        if (this.delegate) {
            this.delegate.onOverlayButtonClicked();
        } else {
            debug('onClick event not fired. No delegate defined for the component.');
        }
    }
}
