import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../actions/admin.whiteListingProducts.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';
import { ProductsWithAvailablePricesDto } from '@modeso/types__dgoods-products';
const debug = Debug('modeso:modeso-lib-admin-fe:AdminWhiteListingProductsProvider');
@Injectable({ providedIn: 'root' })
export class AdminWhiteListingProductsProvider {
    constructor(private readonly store: Store<fromFeature.AppState>) {}

    public dispatchGetAllProductsInAllLanguages$(): void {
        this.store.dispatch(fromActions.getAllProductsInAllLanguages());
    }

    public getAllProductsInAllLanguages$(): Observable<ProductsWithAvailablePricesDto[]> {
        return this.store.pipe(select(fromFeature.selectFeatureGetAllProductsAllLanguages));
    }

    public getAllProductLineNames$(): Observable<string[]> {
        this.store.dispatch(fromActions.getAllProductLineNames());
        return this.store.pipe(select(fromFeature.selectFeatureGetAllProductLineNames));
    }

    public whiteListProduct$(productLineName: string): Observable<WhiteListingResponseModel> {
        this.store.dispatch(fromActions.whiteListProduct({ payload: productLineName }));
        return this.store.pipe(select(fromFeature.selectFeatureWhiteListResponse));
    }
    public unWhiteListProduct$(productLineName: string): Observable<WhiteListingResponseModel> {
        this.store.dispatch(fromActions.unWhiteListProduct({ payload: productLineName }));
        return this.store.pipe(select(fromFeature.selectFeatureWhiteListResponse));
    }

    public synchronizeProducts$(): Observable<string> {
        this.store.dispatch(fromActions.synchronizeProducts());
        return this.store.pipe(select(fromFeature.selectFeatureSynchronizeProductsResponse));
    }

    public getError$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureError),
            map((error: any) => {
                return error;
            }),
        );
    }
}
