import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportOrdersHistory, OrderHistoryResponse, OrdersHistoryRequest } from '@modeso/types__dgoods-cart';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as OrderReportsActions from '../actions/admin-order-history.actions';
import { AdminOrderReportsService } from '../service/admin-order-reports.service';

@Injectable()
export class OrderReportsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly orderReportsService: AdminOrderReportsService,
    ) {}

    loadOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderReportsActions.loadOrders),
            mergeMap((filters: OrdersHistoryRequest) =>
                this.orderReportsService.getOrdersReport(filters).pipe(
                    map((orders: OrderHistoryResponse) => OrderReportsActions.loadOrdersSuccess({ orders })),
                    catchError((error: HttpErrorResponse) => of(OrderReportsActions.loadOrdersFailure({ error }))),
                ),
            ),
        ),
    );

    exportOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderReportsActions.exportOrdersHistory),
            mergeMap((filters: ExportOrdersHistory) =>
                this.orderReportsService.exportOrdersReport(filters).pipe(
                    map(() => OrderReportsActions.exportOrdersHistorySuccess()),
                    catchError((error: HttpErrorResponse) =>
                        of(OrderReportsActions.exportOrdersHistoryFailure({ error })),
                    ),
                ),
            ),
        ),
    );
}
