<div class="coupon-container">
    <div [hidden]="couponsDataSource.length === 0">
        <table mat-table [dataSource]="dataSource" matSort class="mat-table" (matSortChange)="onSort($event)">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fixed-width-column">Name</th>
                <td mat-cell *matCellDef="let coupon" class="fixed-width-column">
                    <span>
                        {{ coupon.name }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid From</th>
                <td mat-cell *matCellDef="let coupon">
                    <span>
                        {{ coupon.startFrom | date: 'dd.MM.y HH:mm:ss' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="validTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid To</th>
                <td mat-cell *matCellDef="let coupon">
                    <span>
                        {{ coupon.endAt | date: 'dd.MM.y HH:mm:ss' }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="numberOfCreatedCodes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr. of Created Codes</th>
                <td mat-cell *matCellDef="let coupon">
                    <span>
                        {{ coupon.noOfCreatedCodes }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="maxUsePerUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Max Use Per User</th>
                <td mat-cell *matCellDef="let coupon">
                    <span>
                        {{ coupon.maxUsePerUser }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="activationState">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Activation State</th>
                <td mat-cell *matCellDef="let coupon">
                    <span>
                        {{ coupon.state }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="controlState">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Control State</th>
                <td mat-cell *matCellDef="let coupon">
                    <span *ngIf="!coupon.controlled; else control">{{ controlState.UNCONTROLLED }}</span>
                    <ng-template #control>
                        <span>{{ controlState.CONTROLLED }} </span>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef class="fixed-width-column"></th>
                <td class="actions fixed-width-column" mat-cell *matCellDef="let coupon">
                    <button
                        *ngIf="coupon.state === couponActivationState.Active && hasWritePermission"
                        class="states activate-button"
                        (click)="changeCouponState(coupon)"
                    >
                        Disable
                    </button>
                    <button
                        *ngIf="
                            coupon.state === couponActivationState.Inactive
                            && archivedState !== true
                            && hasWritePermission
                        "
                        class="states activate-button"
                        (click)="changeCouponState(coupon)"
                    >
                        Activate
                    </button>
                    <button *ngIf="hasWritePermission" class="states edit-button" (click)="editCoupon(coupon.id)">
                        Edit
                    </button>
                    <button
                        *ngIf="hasReadPermission && !hasWritePermission"
                        class="states edit-button"
                        (click)="editCoupon(coupon.id)"
                    >
                        View
                    </button>
                    <span *ngIf="hasWritePermission">
                        <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
                            <mat-icon class="mat-icon">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button
                                *ngIf="archivedState !== true"
                                mat-menu-item
                                (click)="archiveCoupon(coupon.id, coupon.archived)"
                            >
                                Archive
                            </button>
                            <button
                                *ngIf="archivedState === true"
                                mat-menu-item
                                (click)="archiveCoupon(coupon.id, coupon.archived)"
                            >
                                Unarchive
                            </button>
                            <button
                                *ngIf="archivedState === true"
                                mat-menu-item
                                [disabled]="coupon.state === couponActivationState.Active"
                                (click)="deleteCoupon(coupon)"
                            >
                                Delete
                            </button>
                        </mat-menu>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>

    <div [hidden]="couponsDataSource.length !== 0">
        <span class="no-search-results">No coupons found matching your search criteria.</span>
    </div>
</div>
