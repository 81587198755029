import { Injectable } from '@angular/core';
import * as fromActions from '../actions/admin-content-box.action';
import * as fromFeature from '../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { IContentBox, IContentBoxResponse } from '@modeso/types__dgoods-products';

@Injectable({ providedIn: 'root' })
export class AdminContentBoxProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    getAllContentBoxes(): Observable<IContentBoxResponse[]> {
        this.store.dispatch(fromActions.getAllContentBoxes());
        return this.store.pipe(select(fromFeature.selectFeatureContentBoxesList));
    }
    selectAllContentBoxes(): Observable<IContentBoxResponse[]> {
        return this.store.pipe(select(fromFeature.selectFeatureContentBoxesList));
    }

    getContentBoxById(contentBoxId: string): Observable<IContentBoxResponse> {
        this.store.dispatch(fromActions.getContentBoxById({ payload: contentBoxId }));
        return this.store.pipe(select(fromFeature.selectFeatureContentBox));
    }

    addContentBox(contentBox: IContentBox): void {
        this.store.dispatch(fromActions.addContentBox({ payload: contentBox }));
    }

    updateContentBox(contentBox: IContentBoxResponse): void {
        this.store.dispatch(fromActions.updateContentBox({ payload: contentBox }));
    }

    deleteContentBox(contentBoxId: string): Observable<IContentBoxResponse[]> {
        this.store.dispatch(fromActions.deleteContentBox({ payload: contentBoxId }));
        return this.store.pipe(select(fromFeature.selectFeatureContentBoxesList));
    }

    getError$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureError),
            map((error: any) => {
                return error;
            }),
        );
    }
}
