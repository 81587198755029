import { Component, OnInit, Input } from '@angular/core';
import Debug from 'debug';

const debug = Debug('dgoods:shared:DGoodsNavigationComponent');

export interface IDGoodsNavigationDelegate {
    onBackClicked(sender: DGoodsNavigationComponent): void;
    onAccountClicked?(sender: DGoodsNavigationComponent): void;
}

@Component({
    selector: 'dgoods-navigation',
    templateUrl: './dgoods-navigation.component.html',
    styleUrls: ['./dgoods-navigation.component.scss'],
})
export class DGoodsNavigationComponent implements OnInit {
    @Input() delegate?: IDGoodsNavigationDelegate;

    // input to check account button is displayed or not.
    @Input() account: boolean;

    constructor() {}

    ngOnInit() {}

    /**
     * click on back button.
     */
    onBackClick() {
        if (this.delegate) {
            this.delegate.onBackClicked(this);
        } else {
            debug('onClick event not fired. No delegate defined for the component.');
        }
    }
    /**
     * click on Account button.
     */
    onAccountClick() {
        if (this.delegate) {
            this.delegate.onAccountClicked(this);
        } else {
            debug('onClick event not fired. No delegate defined for the component.');
        }
    }
}
