import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { SortingOrderRequest } from '@modeso/types__dgoods-products';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminSortingOrderService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly sortingOrder = 'sortingOrder';

    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    public createOrUpdateSortingOrder(sortingOrder: SortingOrderRequest): Observable<void> {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.sortingOrder}`;
        return this.http.post<void>(url, sortingOrder);
    }
}
