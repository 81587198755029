import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IContentBox, IContentBoxResponse } from '@modeso/types__dgoods-products';

export const getAllContentBoxes = createAction('[Admin Content Boxes] Get All Content Boxes');

export const getAllContentBoxesSuccess = createAction(
    '[Admin Content Boxes] Get All Content Boxes Success',
    props<{ payload: IContentBoxResponse[] }>(),
);

export const getAllContentBoxesFailure = createAction(
    '[Admin Content Boxes] Get All Content Boxes Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const getContentBoxById = createAction(
    '[Admin Content Boxes] Get Content Box By Id',
    props<{ payload: string }>(),
);

export const getContentBoxByIdSuccess = createAction(
    '[Admin Content Boxes] Get Content Box By Id Success',
    props<{ payload: IContentBoxResponse }>(),
);

export const getContentBoxByIdFailure = createAction(
    '[Admin Content Boxes] Get Content Box By Id Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const addContentBox = createAction('[Admin Content Boxes] Add Content Box', props<{ payload: IContentBox }>());

export const addContentBoxSuccess = createAction(
    '[Admin Content Boxes] Add Content Box Success',
    props<{ payload: IContentBoxResponse }>(),
);

export const addContentBoxFailure = createAction(
    '[Admin Content Boxes] Add Content Box Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateContentBox = createAction(
    '[Admin Content Boxes] Update Content Box',
    props<{ payload: IContentBoxResponse }>(),
);

export const updateContentBoxSuccess = createAction(
    '[Admin Content Boxes] Update Content Box Success',
    props<{ payload: IContentBoxResponse }>(),
);

export const updateContentBoxFailure = createAction(
    '[Admin Content Boxes] Update Content Box Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const deleteContentBox = createAction('[Admin Content Boxes] Delete Content Box', props<{ payload: string }>());

export const deleteContentBoxSuccess = createAction(
    '[Admin Content Boxes] Delete Content Box Success',
    props<{ payload: string }>(),
);

export const deleteContentBoxFailure = createAction(
    '[Admin Content Boxes] Delete Content Box Failure',
    props<{ payload: HttpErrorResponse }>(),
);
