import { HttpErrorResponse } from '@angular/common/http';
import { FraudRuleBaseDTO } from '@modeso/types__dgoods-cart';
import { createAction, props } from '@ngrx/store';
export const loadFraudRules = createAction('[Fraud Rules] Load Rules');

export const loadFraudRulesSuccess = createAction(
    '[Fraud Rules] Load Rules Success',
    props<{ rules: FraudRuleBaseDTO[] }>(),
);

export const loadFraudRulesFailure = createAction(
    '[Fraud Rules] Load Rules Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const enableFraudRule = createAction('[Fraud Rules] Enable Rule', props<{ id: string }>());

export const enableFraudRuleSuccess = createAction('[Fraud Rules] Enable Rule Success', props<{ id: string }>());

export const enableFraudRuleFailure = createAction(
    '[Fraud Rules] Enable Rule Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const disableFraudRule = createAction('[Fraud Rules] Disable Rule', props<{ id: string }>());

export const disableFraudRuleSuccess = createAction('[Fraud Rules] Disable Rule Success', props<{ id: string }>());

export const disableFraudRuleFailure = createAction(
    '[Fraud Rules] Disable Rule Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const deleteFraudRule = createAction('[Fraud Rules] Delete Rule', props<{ id: string }>());

export const deleteFraudRuleSuccess = createAction('[Fraud Rules] Delete Rule Success', props<{ id: string }>());

export const deleteFraudRuleFailure = createAction(
    '[Fraud Rules] Delete Rule Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const clearFraudRulesState = createAction('[Fraud Rules] Clear State');
