export class Users {
    public result: UsersDetails[];
    public summary: UsersSummaryDetails;
}
export class UsersSummaryDetails {
    public newUsers: number;
    public emailUsers: number;
    public twintTcUsers: number;
}

export class UsersDetails {
    public _id: string;
    public userToken: string;
    public userIssuer: string;
    public createdAt: string;
    public updatedAt: string;
    public email: string;
    public twintTC: boolean;
}
