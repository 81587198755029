<ng-container>
    <button style="margin: 1.5rem auto" (click)="createNewstickerPage()">Create Newsticker</button>

    <div *ngIf="newstickerList">
        <table mat-table [dataSource]="newstickerList" class="mat-elevation-z8 table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td class="fixed-height" mat-cell *matCellDef="let item">
                    <div class="fixed-height align-center">{{item.name}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="types">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td class="fixed-height" mat-cell *matCellDef="let item">
                    <div class="fixed-height align-center">{{item.type | pascalcase}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef>Text</th>
                <td class="fixed-height" mat-cell *matCellDef="let item">
                    <div class="fixed-height align-center">{{item.text['en-us']}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let item">
                    <span> {{item.status | getEnumKeyFromValue: PublishStatus | pascalcase}} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>Buttons</th>
                <td mat-cell *matCellDef="let item; index as i">
                    <div class="col align-center">
                        <button
                            class="mx-1 action-button"
                            [disabled]="item.status !== PublishStatus.UNPUBLISHED"
                            (click)="editNewsticker(item)"
                        >
                            Edit
                        </button>

                        <button
                            class="mx-1 action-button"
                            [disabled]="item.status !== PublishStatus.UNPUBLISHED || isDisabled && isDisabled !== 'btn'+i"
                            (click)="setStatus(item, PublishStatus.PUBLISHED, 'btn' + i)"
                        >
                            Publish
                        </button>
                        <button
                            class="mx-1 action-button"
                            [disabled]="item.status !== PublishStatus.PUBLISHED || isDisabled && isDisabled !== 'btn'+i"
                            (click)="setStatus(item, PublishStatus.UNPUBLISHED, 'btn' + i)"
                        >
                            Unpublish
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>
