import { Component, Input, Renderer2, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsPriceOptionComponent');

export interface IDGoodsPriceOptionDelegate {
    onPriceSelected(price: number, reference: string): void;
    onPriceUnselected(price: number, reference: string): void;
}

export class DGoodsPriceOptionModel {
    constructor(
        public reference: string,
        public value: number,
        public currency: string,
        public selected: boolean,
        public isDenomination?: boolean,
        public minValue?: number,
        public maxValue?: number,
    ) {
        if (isDenomination === undefined) {
            this.isDenomination = false;
            if (minValue === undefined) {
                this.minValue = value;
            }
            if (maxValue === undefined) {
                this.maxValue = value;
            }
        }
    }
}

@Component({
    selector: 'dgoods-price-option',
    templateUrl: './dgoods-price-option.component.html',
    styleUrls: ['./dgoods-price-option.component.scss'],
})
export class DGoodsPriceOptionComponent implements OnInit, OnChanges {
    @Input() delegate?: IDGoodsPriceOptionDelegate;
    @Input() selected = false;
    @Input() value?: number;
    @Input() currency?: string;
    @Input() reference?: string;
    @Input() price?: DGoodsPriceOptionModel;
    @Input() prices: DGoodsPriceOptionModel[];
    public hasValidationError = false;
    @ViewChild('rootelement', { static: true }) rootelement: ElementRef;
    @ViewChild('amount', { static: true }) amount: ElementRef;
    @ViewChild('description', { static: false }) description: ElementRef;

    constructor(protected renderer: Renderer2) {}

    ngOnInit(): void {
        this.updateView();
        if (
            (this.prices?.length === 1 && !this.prices[0].isDenomination)
            || (this.prices?.length && this.prices[0].reference === this.price.reference)
        ) {
            this.toggleSelected();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'price': {
                        this.price = changes.price.currentValue;
                        break;
                    }
                    case 'selected': {
                        this.selected = changes.selected.currentValue;
                        break;
                    }
                    case 'value': {
                        this.value = changes.value.currentValue;
                        break;
                    }
                    case 'currency': {
                        this.currency = changes.currency.currentValue;
                        break;
                    }
                }
            }
        }
        this.updateView();
    }

    protected updateView() {
        if (this.price) {
            this.value = this.price.value;
            this.currency = this.price.currency;
            this.selected = this.price.selected;
        }
        if (this.selected) {
            debug('set select class');
            this.renderer.addClass(this.rootelement.nativeElement, 'selected');
        } else {
            debug('remove select class');
            this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
        }
    }

    public onToggle() {
        debug('onToggle');
        // set value = 0 on price change
        this.value = 0;
        this.toggleSelected();
    }

    protected toggleSelected() {
        if (!this.selected) {
            this.selected = !this.selected;

            if (this.selected) {
                this.renderer.addClass(this.rootelement.nativeElement, 'selected');
            } else {
                this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
            }

            if (this.delegate) {
                let ref = this.reference;
                if (this.price && this.price.reference) {
                    ref = this.price.reference;
                }
                if (this.selected) {
                    this.delegate.onPriceSelected(this.value, this.reference);
                } else {
                    this.delegate.onPriceUnselected(this.value, this.reference);
                }
            } else {
                if (this.selected) {
                    debug('onPriceSelected event not fired. There is no delegate attached to the component.');
                } else {
                    debug('onPriceUnselected event not fired. There is no delegate attached to the component.');
                }
            }
        }
    }

    public onClickOnAmount(event?: Event) {
        debug('onClickOnAmount');
        this.toggleSelected();
        if (event) {
            event.stopPropagation();
        }
    }
}
