<ng-container>
    <div [formGroup]="contentBoxForm">
        <div class="mb-2">ContentBox Details</div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Product Line ID</mat-label>
                <mat-select
                    formControlName="productlineId"
                    [value]="contentBox.productlineId"
                    (selectionChange)="onProductLineNameSelectionChange($event)"
                >
                    <mat-option *ngFor="let product of allProductsLineNames" [value]="product"
                        >{{ product }}</mat-option
                    >
                </mat-select>
                <mat-error>Product Line ID is required</mat-error>
            </mat-form-field>
        </div>

        <mat-tab-group animationDuration="0ms" (selectedIndexChange)="languageTabSelectionChanged($event)">
            <mat-tab *ngFor="let item of languagesTabsData" label="{{item.label}}"></mat-tab>
        </mat-tab-group>

        <div style="margin-top: 15px">
            <mat-form-field appearance="fill">
                <mat-label> Title </mat-label>
                <input formControlName="title" matInput (input)="onTitleChange($event)" />
                <mat-error>Title is required</mat-error>
            </mat-form-field>
        </div>

        <div style="margin-top: 5px">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label> Content </mat-label>
                <textarea
                    formControlName="content"
                    matInput
                    placeholder="Enter Markdown Code here"
                    class="textarea-field"
                    rows="15"
                    (input)="onContentChange($event)"
                ></textarea>
                <!-- <app-markdown-editor [delegate]="this" [markdown]="" ></app-markdown-editor> -->
                <mat-error>Content is required</mat-error>
            </mat-form-field>
        </div>
        <div style="margin-top: 10px">
            <button class="button-blue-color" *ngIf="!showPreview" (click)="togglePreview()">Show Preview</button>
            <button class="button-red-color" *ngIf="showPreview" (click)="togglePreview()" style="margin-bottom: 10px">
                Hide Preview
            </button>
        </div>
        <div class="preview full-width" *ngIf="showPreview" [innerHtml]="getHtml()"></div>
    </div>

    <div class="save-buttons">
        <button
            class="btn btn-success save-button"
            [disabled]="contentBoxForm.invalid || isContentBoxInValid() || !isUnsavedChanges"
            (click)="save()"
        >
            Save
        </button>
        <button class="btn btn-secondary cancel-button" (click)="cancel()">Cancel</button>
    </div>
</ng-container>
