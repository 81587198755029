<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="row filters">
        <div class="col-lg-2">
            <div>
                <h4>Select Kpi</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Kpis</mat-label>
                    <mat-select [formControl]="kpiControl" (selectionChange)="getSelectedKpi($event)" required>
                        <mat-option *ngFor="let kpi of kpis" [value]="kpi"> {{kpi}} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="kpiControl.hasError('required')">Please choose a kpi</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div>
                <h4>Select Time Filter</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Time Filter</mat-label>
                    <mat-select
                        [formControl]="timeFrameControl"
                        (selectionChange)="getSelectedTimeFilter($event)"
                        required
                    >
                        <mat-option *ngFor="let timeFrame of timeFrames" [value]="timeFrame">
                            {{timeFrame}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeFrameControl.hasError('required')">Please choose a time period</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div>
                <h4>Select Date</h4>
                <mat-form-field appearance="fill">
                    <mat-label>Select Date</mat-label>
                    <input matInput [matDatepicker]="dp3" [(ngModel)]="selectedDate" disabled />
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div>
                <h4>Select Time</h4>
                <mat-form-field appearance="fill" class="custom_mat_form_time">
                    <div class="time-picker">
                        <input
                            matInput
                            [ngxTimepicker]="toggleTimepicker"
                            [disableClick]="true"
                            readonly
                            [format]="24"
                            disabled
                            #timePicker
                            placeholder="Select time"
                        />
                        <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
                    </div>
                </mat-form-field>
            </div>
        </div>

        <div class="col-lg-2">
            <div>
                <h4>Coupon</h4>
                <mat-checkbox class="checkbox-1" [checked]="checkBoxIsSelected" (change)="onChecked($event.checked)">
                    <mat-label style="font-weight: 400"> Show only Orders with Coupons </mat-label>
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div class="row search-btn">
        <div class="search-container">
            <div class="search-button">
                <button id="saveAddressButton" [disabled]="buttonStatus()" (click)="onSearch()">Search</button>
            </div>
        </div>
    </div>

    <div class="row errorMessages">
        <span class="error-text" *ngIf="isBadRequest">{{badRequestErrorMessage}}</span>
        <span class="error-text" *ngIf="isServerError">{{serverError}}</span>
    </div>

    <div *ngIf="isGoods" class="table-container">
        <div>
            <table mat-table [dataSource]="goodsDataSource" matTableExporter #goodsExporter="matTableExporter">
                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element._id.productLine || element._id}}</td>
                </ng-container>

                <!-- Terminal Id Column -->
                <ng-container matColumnDef="Terminal Id">
                    <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
                    <td mat-cell *matCellDef="let element">{{element._id.terminalId}}</td>
                </ng-container>

                <!-- Total Orders Column -->
                <ng-container matColumnDef="Total Orders">
                    <th mat-header-cell *matHeaderCellDef>Total Orders</th>
                    <td mat-cell *matCellDef="let element">{{element.totalOrders}}</td>
                </ng-container>

                <!-- Cancel by shop Column -->
                <ng-container matColumnDef="Cancel by shop">
                    <th mat-header-cell *matHeaderCellDef>Cancel by shop</th>
                    <td mat-cell *matCellDef="let element">{{element.canceldByShop}}</td>
                </ng-container>

                <!-- Order success Column -->
                <ng-container matColumnDef="Order success">
                    <th mat-header-cell *matHeaderCellDef>Order success</th>
                    <td mat-cell *matCellDef="let element">{{element.orderSuccess}}</td>
                </ng-container>

                <!-- Use as gift Column -->
                <ng-container matColumnDef="Use as gift">
                    <th mat-header-cell *matHeaderCellDef>Use as gift</th>
                    <td mat-cell *matCellDef="let element">{{element.useAsGfit}}</td>
                </ng-container>

                <!-- Used personal Column -->
                <ng-container matColumnDef="Used personal">
                    <th mat-header-cell *matHeaderCellDef>Used personal</th>
                    <td mat-cell *matCellDef="let element">{{element.usePersonal}}</td>
                </ng-container>

                <!-- Is own amount Column -->
                <ng-container matColumnDef="Is own amount">
                    <th mat-header-cell *matHeaderCellDef>Is own amount</th>
                    <td mat-cell *matCellDef="let element">{{element.isOwnAmount}}</td>
                </ng-container>

                <!-- Is predfined Column -->
                <ng-container matColumnDef="Is predfined value">
                    <th mat-header-cell *matHeaderCellDef>Is predfined value</th>
                    <td mat-cell *matCellDef="let element">{{element.isPredefinedValue}}</td>
                </ng-container>

                <!-- Sold Total Column -->
                <ng-container matColumnDef="Sold Total">
                    <th mat-header-cell *matHeaderCellDef>Sold Total</th>
                    <td mat-cell *matCellDef="let element">{{element.soldTotal}}</td>
                </ng-container>

                <!-- Sold Average Column -->
                <ng-container matColumnDef="Sold Average">
                    <th mat-header-cell *matHeaderCellDef>Sold Average</th>
                    <td mat-cell *matCellDef="let element">{{element.soldAvarage}}</td>
                </ng-container>

                <!-- iscounted Column -->
                <ng-container matColumnDef="Discounted">
                    <th mat-header-cell *matHeaderCellDef>Discounted</th>
                    <td mat-cell *matCellDef="let element">{{element.discounted}}</td>
                </ng-container>

                <!-- Total Discount Column -->
                <ng-container matColumnDef="Total Discounts">
                    <th mat-header-cell *matHeaderCellDef>Total Discounts</th>
                    <td mat-cell *matCellDef="let element">{{element.totalDiscounts}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="goodsAndSessionsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: goodsAndSessionsDisplayedColumns;"></tr>
            </table>

            <mat-paginator #goodsPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button id="goodsExportExcelButton" (click)="goodsExporter.exportTable(exportType, exportGoodsOptions)">
                Export Excel
            </button>
        </div>
    </div>

    <div *ngIf="isSales" class="table-container">
        <div>
            <table mat-table [dataSource]="salesDataSource" matTableExporter #salesExporter="matTableExporter">
                <!-- ID Column -->
                <ng-container matColumnDef="Reference">
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let element">{{element._id}}</td>
                </ng-container>
                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal</th>
                    <td mat-cell *matCellDef="let element">{{element.terminalId}}</td>
                </ng-container>
                <ng-container matColumnDef="User token">
                    <th mat-header-cell *matHeaderCellDef>User Token</th>
                    <td mat-cell *matCellDef="let element">{{element.userToken}}</td>
                </ng-container>

                <!-- Is predfined Column -->
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>Amount</th>
                    <td mat-cell *matCellDef="let element" style="text-align: right; padding-right: 10px">
                        {{element.amount}}
                    </td>
                </ng-container>

                <!-- Cancel by shop Column -->
                <ng-container matColumnDef="productConfig">
                    <th mat-header-cell *matHeaderCellDef>Product</th>
                    <td mat-cell *matCellDef="let element">{{element.productConfig}}</td>
                </ng-container>

                <!-- Total Orders Column -->
                <ng-container matColumnDef="retrievalReferenceNumber">
                    <th mat-header-cell *matHeaderCellDef>RRN (BHN)</th>
                    <td mat-cell *matCellDef="let element">{{element.retrievalReferenceNumber}}</td>
                </ng-container>

                <!-- Use as gift Column -->
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>createdAt</th>
                    <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
                </ng-container>

                <!-- Order success Column -->
                <ng-container matColumnDef="messageId">
                    <th mat-header-cell *matHeaderCellDef>messageId (Modeso)</th>
                    <td mat-cell *matCellDef="let element">{{element.messageId}}</td>
                </ng-container>

                <!-- Used personal Column -->
                <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell *matHeaderCellDef>updatedAt</th>
                    <td mat-cell *matCellDef="let element">{{element.updatedAt}}</td>
                </ng-container>

                <!-- Coupon name Column -->
                <ng-container matColumnDef="Coupon Name">
                    <th mat-header-cell *matHeaderCellDef>Coupon Name</th>
                    <td mat-cell *matCellDef="let element">{{element.couponName}}</td>
                </ng-container>

                <!-- Coupon Code Column -->
                <ng-container matColumnDef="Coupon Code">
                    <th mat-header-cell *matHeaderCellDef>Coupon Code</th>
                    <td mat-cell *matCellDef="let element">{{element.couponCode}}</td>
                </ng-container>

                <!-- Coupon Value Column -->
                <ng-container matColumnDef="Coupon Value">
                    <th mat-header-cell *matHeaderCellDef>Coupon Value</th>
                    <td mat-cell *matCellDef="let element">{{element.couponValue}}</td>
                </ng-container>

                <!-- Amount Coupon Column -->
                <ng-container matColumnDef="Amount Coupon">
                    <th mat-header-cell *matHeaderCellDef>Amount Payed by Customer</th>
                    <td mat-cell *matCellDef="let element">{{element.amountCoupon}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="salesDisplayColums"></tr>
                <tr mat-row *matRowDef="let row; columns: salesDisplayColums;"></tr>
            </table>

            <mat-paginator #salesPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button id="salesExportExcelButton" (click)="salesExporter.exportTable(exportType, exportSalesOptions)">
                Export Excel
            </button>
        </div>
    </div>

    <div *ngIf="isUsers" class="table-container">
        <div>
            <table mat-table [dataSource]="usersDataSource" matTableExporter #usersExporter="matTableExporter">
                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element._id}}</td>
                </ng-container>
                <!-- Terminal Column -->
                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal</th>
                    <td mat-cell *matCellDef="let element">{{element.terminalId}}</td>
                </ng-container>

                <!-- User token Column -->
                <ng-container matColumnDef="User token">
                    <th mat-header-cell *matHeaderCellDef>User token</th>
                    <td mat-cell *matCellDef="let element">{{element.userToken}}</td>
                </ng-container>

                <!-- User Issuer Column -->
                <ng-container matColumnDef="User Issuer">
                    <th mat-header-cell *matHeaderCellDef>User Issuer</th>
                    <td mat-cell *matCellDef="let element">{{element.userIssuer}}</td>
                </ng-container>

                <!-- Created at Column -->
                <ng-container matColumnDef="Created at">
                    <th mat-header-cell *matHeaderCellDef>Created at</th>
                    <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
                </ng-container>

                <!-- Updated at Column -->
                <ng-container matColumnDef="Updated at">
                    <th mat-header-cell *matHeaderCellDef>Updated at</th>
                    <td mat-cell *matCellDef="let element">{{element.updatedAt}}</td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">{{element.email}}</td>
                </ng-container>

                <!-- Twint T&C Column -->
                <ng-container matColumnDef="Twint T&C">
                    <th mat-header-cell *matHeaderCellDef>Twint T&C</th>
                    <td mat-cell *matCellDef="let element">{{element.twintTC}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></tr>
            </table>

            <mat-paginator #usersPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button id="usersExportExcelButton" (click)="usersExporter.exportTable(exportType, exportUsersOptions)">
                Export Excel
            </button>
        </div>
    </div>

    <div *ngIf="isSessions" class="table-container">
        <div>
            <table mat-table [dataSource]="sessionsDataSource" matTableExporter #sessionsExporter="matTableExporter">
                <!-- ID Column -->
                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal</th>
                    <td mat-cell *matCellDef="let element">{{element._id}}</td>
                </ng-container>

                <!-- Count of Sessions Column -->
                <ng-container matColumnDef="Count of Sessions">
                    <th mat-header-cell *matHeaderCellDef>Count of Sessions</th>
                    <td mat-cell *matCellDef="let element">{{element.count}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="sessionsDisplayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: sessionsDisplayColumns;"></tr>
            </table>
            <mat-paginator #sessionsPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button
                id="sessionsExportExcelButton"
                (click)="sessionsExporter.exportTable(exportType, exportSessionsOptions)"
            >
                Export Excel
            </button>
        </div>
    </div>

    <div *ngIf="isViews" class="table-container">
        <div>
            <table mat-table [dataSource]="viewsDataSource" matTableExporter #viewsExporter="matTableExporter">
                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element._id.product || element._id}}</td>
                </ng-container>

                <ng-container matColumnDef="Terminal Id">
                    <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
                    <td mat-cell *matCellDef="let element">{{element._id.terminalId}}</td>
                </ng-container>
                <!-- Total visits Column -->
                <ng-container matColumnDef="Total visits">
                    <th mat-header-cell *matHeaderCellDef>Total visits</th>
                    <td mat-cell *matCellDef="let element">{{element.totalVisits}}</td>
                </ng-container>

                <!-- Unique users Column -->
                <ng-container matColumnDef="Unique users">
                    <th mat-header-cell *matHeaderCellDef>Unique users</th>
                    <td mat-cell *matCellDef="let element">{{element.uniqueUsers}}</td>
                </ng-container>

                <!-- Average per person Column -->
                <ng-container matColumnDef="Average per person">
                    <th mat-header-cell *matHeaderCellDef>Average per person</th>
                    <td mat-cell *matCellDef="let element">{{element.avgPerUser}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="viewsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: viewsDisplayedColumns;"></tr>
            </table>

            <mat-paginator #viewsPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button id="viewsExportExcelButton" (click)="viewsExporter.exportTable(exportType, exportViewsOptions)">
                Export Excel
            </button>
        </div>
    </div>

    <div *ngIf="isBlocked">
        <div class="table-container">
            <label>Blocked Products Analytics</label>
            <table mat-table [dataSource]="blockedProductsAnalyticsDataSource">
                <!-- Blocked Products Analytics Column -->
                <ng-container matColumnDef="Blocked Products Analytics">
                    <th mat-header-cell *matHeaderCellDef>Blocked Products Analytics</th>
                    <td mat-cell *matCellDef="let element">{{element}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="blockedProductsAnalyticsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: blockedProductsAnalyticsColumns;"></tr>
            </table>

            <mat-paginator #blockedProductsPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="table-container">
            <label>Blocked Shop Analytics</label>
            <table mat-table [dataSource]="blockedShopAnalyticsDataSource">
                <!-- Blocked Shop Analytics Column -->
                <ng-container matColumnDef="Blocked Shop Analytics">
                    <th mat-header-cell *matHeaderCellDef>Blocked Shop Analytics</th>
                    <td mat-cell *matCellDef="let element">{{element}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="blockedShopAnalyticsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: blockedShopAnalyticsColumns;"></tr>
            </table>

            <mat-paginator #blockedShopPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="table-container">
            <label>Fraud limits</label>
            <table mat-table [dataSource]="fraudDetectionLimitsDataSource">
                <!-- Position Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let element">{{element.value}}</td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="Time frame name">
                    <th mat-header-cell *matHeaderCellDef>Time frame name</th>
                    <td mat-cell *matCellDef="let element">{{element.timeframeName}}</td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="Time frame value">
                    <th mat-header-cell *matHeaderCellDef>Time frame value</th>
                    <td mat-cell *matCellDef="let element">{{element.timeframeValue}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="fraudDetectionLimitsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: fraudDetectionLimitsColumns;"></tr>
            </table>

            <mat-paginator #fraudPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div>
            <h5>Total Amount Of Today Purchasing: {{totalAmountOfTodayPurchasing}}</h5>
        </div>

        <div class="table-container">
            <label>Today Purchasing Amount Of Prodcut Lines</label>
            <table mat-table [dataSource]="todayPurchasingAmountOfproductLinesDataSource">
                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element._id.productLine || element._id }}</td>
                </ng-container>

                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal</th>
                    <td mat-cell *matCellDef="let element">{{element._id.terminalId}}</td>
                </ng-container>

                <!-- Product ID Column -->
                <ng-container matColumnDef="Product ID">
                    <th mat-header-cell *matHeaderCellDef>Product ID</th>
                    <td mat-cell *matCellDef="let element">{{element.productLineEntityId}}</td>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef>Amount</th>
                    <td mat-cell *matCellDef="let element">{{element.amount}}</td>
                </ng-container>

                <!-- Count Column -->
                <ng-container matColumnDef="Count">
                    <th mat-header-cell *matHeaderCellDef>Count</th>
                    <td mat-cell *matCellDef="let element">{{element.count}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="todayPurchasingAmountOfproductLinesColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: todayPurchasingAmountOfproductLinesColumns;"></tr>
            </table>

            <mat-paginator #todayPurchasingProductPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons>
            </mat-paginator>
        </div>

        <div class="table-container">
            <label>Today Purchasing Amount of Users</label>
            <table mat-table [dataSource]="todayPurchasingAmountOfUsersDataSource">
                <!-- ID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{element._id}}</td>
                </ng-container>
                <!-- Amount Column -->
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef>Amount</th>
                    <td mat-cell *matCellDef="let element">{{element.amount}}</td>
                </ng-container>
                <!-- Terminal Id Column -->
                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal</th>
                    <td mat-cell *matCellDef="let element">{{element.terminalId}}</td>
                </ng-container>
                <!-- Count Column -->
                <ng-container matColumnDef="Count">
                    <th mat-header-cell *matHeaderCellDef>Count</th>
                    <td mat-cell *matCellDef="let element">{{element.count}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="todayPurchasingAmountOfUsersColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: todayPurchasingAmountOfUsersColumns;"></tr>
            </table>

            <mat-paginator #todayPurchasingUsersPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons>
            </mat-paginator>
        </div>

        <div class="table-container">
            <label>Product Limit</label>
            <table mat-table [dataSource]="productsLimitDataSource">
                <!-- Product Column -->
                <ng-container matColumnDef="Product">
                    <th mat-header-cell *matHeaderCellDef>Product</th>
                    <td mat-cell *matCellDef="let element">{{element.product}}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{element.status}}</td>
                </ng-container>

                <!-- Limits Column -->
                <ng-container matColumnDef="Limits">
                    <th mat-header-cell *matHeaderCellDef>Limits</th>
                    <td mat-cell *matCellDef="let element">{{element.limits}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="productsLimitcolumns"></tr>
                <tr mat-row *matRowDef="let row; columns: productsLimitcolumns;"></tr>
            </table>

            <mat-paginator #productLimitPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button id="blockedExportExcelButton" (click)="onExportBlockedToExcel()">Export Excel</button>
        </div>
    </div>

    <div *ngIf="isTerminal" class="table-container">
        <div>
            <table mat-table [dataSource]="terminalsDataSource" matTableExporter #terminalExporter="matTableExporter">
                <!-- Terminal ID Column -->
                <ng-container matColumnDef="Terminal">
                    <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
                    <td mat-cell *matCellDef="let element">{{element._id}}</td>
                </ng-container>

                <!-- Total visits Column -->
                <ng-container matColumnDef="Checkins">
                    <th mat-header-cell *matHeaderCellDef>Total Checkins</th>
                    <td mat-cell *matCellDef="let element">{{element.totalNumberOfCheckIn}}</td>
                </ng-container>

                <!-- Total Checkouts Column -->
                <ng-container matColumnDef="Total Checkouts">
                    <th mat-header-cell *matHeaderCellDef>Total Checkouts</th>
                    <td mat-cell *matCellDef="let element">{{element.totalCheckouts}}</td>
                </ng-container>

                <!-- SuccessFul CheckOuts Column -->
                <ng-container matColumnDef="SuccessFul CheckOuts">
                    <th mat-header-cell *matHeaderCellDef>SuccessFul CheckOuts</th>
                    <td mat-cell *matCellDef="let element">{{element.successFullCheckOuts}}</td>
                </ng-container>
                <!-- Failed CheckOuts Column -->
                <ng-container matColumnDef="Failed CheckOuts">
                    <th mat-header-cell *matHeaderCellDef>Failed CheckOuts</th>
                    <td mat-cell *matCellDef="let element">{{element.failedCheckouts}}</td>
                </ng-container>
                <!-- Total Amount of Succesful CheckOuts Column -->
                <ng-container matColumnDef="Total Amount of CheckOuts">
                    <th mat-header-cell *matHeaderCellDef>Total Amount of SuccessFul CheckOuts</th>
                    <td mat-cell *matCellDef="let element">{{element.totalPriceOfSuccessfullCheckouts}}</td>
                </ng-container>
                <!-- Conversion Rate-->
                <ng-container matColumnDef="Conversion Rate">
                    <th mat-header-cell *matHeaderCellDef>Conversion Rate %</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.conversionRate}} <span *ngIf="element.conversionRate !== 0">% </span>
                    </td>
                </ng-container>
                <!--Drop Off Rate-->
                <ng-container matColumnDef="Drop Off Rate">
                    <th mat-header-cell *matHeaderCellDef>Drop Off Rate %</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.dropOffRate}} <span *ngIf="element.dropOffRate !== 0">% </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="terminalColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: terminalColumns;"></tr>
            </table>

            <mat-paginator #terminalPaginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="export-excel-button">
            <button
                id="terminalsExportExcelButton"
                (click)="terminalExporter.exportTable(exportType, exportTerminalsOptions)"
            >
                Export Excel
            </button>
        </div>
    </div>
</div>
