<div class="spot-light-container">
    <div [formGroup]="spotLightForm" *ngIf="spotLightForm">
        <h4>Spotlight details:</h4>

        <div class="spotlight-name">
            <mat-form-field>
                <mat-label> Spotlight Name </mat-label>
                <input formControlName="name" matInput />
                <mat-error> Name is required </mat-error>
            </mat-form-field>
        </div>

        <div class="spotlight-dates">
            <mat-form-field class="startdate">
                <mat-label>Start Date</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" formControlName="startFrom" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds"></ngx-mat-datetime-picker>
                <mat-error> Date is required </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [ngxMatDatetimePicker]="endpicker" [min]="startFrom.value" formControlName="endAt" />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endpicker"
                    (click)="handleDateClicked()"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #endpicker [showSeconds]="showSeconds"></ngx-mat-datetime-picker>
                <mat-error> Date is required </mat-error>
            </mat-form-field>
        </div>

        <h4>Choose spotlight redirection type:</h4>

        <div class="radio">
            <mat-radio-group
                formControlName="navigationType"
                aria-label="Select an option"
                (change)="changeNavigateTo()"
                class="column"
            >
                <mat-radio-button value="{{ spotlightTypes.PRODUCT }}" class="btns">Go To Product</mat-radio-button>
                <mat-radio-button value="{{ spotlightTypes.CONTENT }}" class="btns"
                    >Go To Content Page</mat-radio-button
                >
                <mat-radio-button value="{{ spotlightTypes.URL }}" class="btns">Go To External URL</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="mobile-settings">
            <mat-slide-toggle formControlName="displayOnAndroid" color="success"> Display on Android </mat-slide-toggle>
            <mat-slide-toggle formControlName="displayOnIOS" color="success"> Display on iOS </mat-slide-toggle>
        </div>

        <div class="margin-btm">
            <div *ngIf="navigationType.value === spotlightTypes.PRODUCT">
                <mat-form-field appearance="outline">
                    <mat-label>Product</mat-label>
                    <mat-select formControlName="productLineId">
                        <mat-option *ngFor="let product of products" [value]="product.productLineName">
                            {{ product.productLineName }}</mat-option
                        >
                    </mat-select>
                    <mat-error> Product is required </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="navigationType.value === spotlightTypes.CONTENT">
                <mat-form-field appearance="outline">
                    <mat-label> Content </mat-label>
                    <mat-select formControlName="contentPageId">
                        <mat-option *ngFor="let contentPage of contentPages" [value]="contentPage._id">
                            {{ contentPage.title.en_us }}</mat-option
                        >
                    </mat-select>
                    <mat-error> Content is required </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="navigationType.value === spotlightTypes.URL">
                <mat-form-field appearance="fill" class="url">
                    <mat-label> Url </mat-label>
                    <input formControlName="navigationUrl" matInput />
                    <mat-error> Url is required and must be with correct format.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <h4>Upload spotlight image for all languages:</h4>

        <span class="recommendation-text"
            >It is recommended that you upload an image with the following ratio (343 x 130, or 2.63 : 1)</span
        >

        <div class="tabs">
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="onChangeImageTab($event)" class="tab-group">
                <mat-tab
                    label="{{ language.key }} ({{ language.value }})"
                    *ngFor="let language of languages | keyvalue: originalOrder"
                >
                    <span *ngIf="uploadError" class="error-msg">[{{ errorMessageForImagesUpload }}]</span>

                    <div>
                        <label (click)="imgFileDropElement.openFileSelector()">Choose file</label>
                    </div>

                    <div class="image-list-item" *ngFor="let image of images | keyvalue">
                        <div *ngIf="image.key === language.value">
                            <img [src]="image.value" />
                        </div>
                    </div>

                    <mat-spinner value="25" diameter="40" *ngIf="inProgress"></mat-spinner>
                </mat-tab>
            </mat-tab-group>

            <ngx-file-drop
                [hidden]="true"
                #imgFileDrop
                dropZoneLabel="Drop 8 here"
                (onFileDrop)="onFileSelected($event)"
                [disabled]="isReadOnly"
            >
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector"> </ng-template>
            </ngx-file-drop>
        </div>

        <div class="save-buttons">
            <button [disabled]="spotLightForm.invalid || areImagesEmpty()" (click)="save()">Save</button>
            <button (click)="cancel()" class="cancel">Cancel</button>
        </div>
    </div>
</div>
