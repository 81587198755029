import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '@modeso/dgoods-lib-admin-fe';
import { FraudRuleBaseDTO } from '@modeso/types__dgoods-cart';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FraudRulesService {
    private readonly server = '';
    private readonly microserviceName = 'cart';

    constructor(
        private readonly http: HttpClient,
        @Inject(AdminConfigService) private readonly config,
    ) {
        this.server = config.apiGateway;
    }

    getFraudRules(): Observable<FraudRuleBaseDTO[]> {
        const url = `${this.server}${this.microserviceName}/fraud-rules`;
        return this.http.get<FraudRuleBaseDTO[]>(url);
    }

    enableFraudRule(id: string): Observable<FraudRuleBaseDTO> {
        const url = `${this.server}${this.microserviceName}/fraud-rules/${id}/enable`;
        return this.http.patch<FraudRuleBaseDTO>(url, {});
    }

    disableFraudRule(id: string): Observable<FraudRuleBaseDTO> {
        const url = `${this.server}${this.microserviceName}/fraud-rules/${id}/disable`;
        return this.http.patch<FraudRuleBaseDTO>(url, {});
    }

    deleteFraudRule(id: string): Observable<void> {
        const url = `${this.server}${this.microserviceName}/fraud-rules/${id}`;
        return this.http.delete<void>(url);
    }
}
