import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin-manageContent.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { AllTranslationsDetails } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';

const debug = Debug('dgoods-lib-admin-fe:AdminManageContentProvider');
@Injectable({ providedIn: 'root' })
export class AdminManageContentProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public getAllLocalizations$(): Observable<AllTranslationsDetails[]> {
        this.store.dispatch(fromActions.getAllLocalizations());
        return this.store.pipe(
            select(fromFeature.selectFeatureLocalizations),
            filter((localizations: AllTranslationsDetails[]) => localizations.length !== 0 && localizations != null),
        );
    }

    public updateLocalizationByKey$(
        translation: TranslationsByKeyResponseDetails,
    ): Observable<AllTranslationsDetails[]> {
        this.store.dispatch(fromActions.updateLocalizationByKey({ payload: translation }));
        return this.store.pipe(
            select(fromFeature.selectFeatureLocalizations),
            filter((localization: AllTranslationsDetails[]) => localization.length !== 0 && localization != null),
        );
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(
            select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null),
        );
    }
}
