import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { AdminConfig, AdminConfigService } from './admin.config';
import * as fromReducer from './reducers/admin.reducer';
import { AdminEffects } from './effects/admin.effects';
import { AdminFraudLimitsEffects } from './effects/admin-fraudLimits.effects';
import { AdminService } from './services/admin.service';
import { AdminFraudLimitsService } from './services/admin-fraudLimits.service';
import { AdminReportEffects } from './effects/admin-report.effect';
import { AdminFraudDetectionProductsService } from './services/admin.fraudDetectionProducts.service';
import { AdminFraudDetectionProductsEffects } from './effects/admin.fraudDetectionProducts.effects';
import { AdminReportService } from './services/admin-report.service';
import { AdminManageContentService } from './services/admin-manageContent.service';
import { AdminManageContentEffects } from './effects/admin-manageContent.effect';
import { AdminWhiteListingProductsEffects } from './effects/admin.whiteListingProducts.effects';
import { AdminWhiteListingProductsService } from './services/admin.whiteListingProducts.service';
import { AdminManageProductContentService } from './services/admin-manageProductContent.service';
import { AdminManageProductContentEffects } from './effects/admin-manageProductContent.effect';
import { AdminWhiteListingUsersService } from './services/admin.whiteListingUsers.service';
import { AdminWhiteListingUsersEffects } from './effects/admin.whiteListingUsers.effects';
import { AdminAuditLogsEffects } from './effects/admin-auditLogs.effects';
import { AdminAuditLogsService } from './services/admin-auditLogs.service';
import { AdminMerchantEffect } from './effects/admin-merchant.effect';
import { AdminMerchantService } from './services/admin-merchant.service';
import { AdminDigitalVoucherConfiguratorService } from './services/admin-digitalvoucher-configurator.service.ts';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminDigitalVoucherConfiguratorEffects } from './effects/admin-digitalvoucher-configurator.effects';
import { AdminNewsTickerEffect } from './effects/admin-newsticker.effect';
import { AdminSortingOrderEffect } from './effects/admin-sortingOrder.effect';
import { AdminManageProductCategoryEffects } from './effects/admin-manageProductCategory.effect';
import { AdminManageProductCategoryService } from './services/admin-manageProductCategory.service';
import { AdminContentPageEffect } from './effects/admin-content-page.effect';
import { AdminManageSpotLightEffects } from './effects/admin-manageSpotLight.effect';
import { AdminManageSpotLightService } from './services/admin-manageSpotLight.service';
import { AdminContentBoxEffect } from './effects/admin-content-box.effect';

@NgModule({
    declarations: [],
    providers: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromReducer.adminFeatureKey, fromReducer.reducer),
        EffectsModule.forFeature([
            AdminEffects,
            AdminFraudDetectionProductsEffects,
            AdminReportEffects,
            AdminFraudLimitsEffects,
            AdminWhiteListingProductsEffects,
            AdminManageContentEffects,
            AdminManageProductContentEffects,
            AdminManageProductCategoryEffects,
            AdminWhiteListingUsersEffects,
            AdminAuditLogsEffects,
            AdminMerchantEffect,
            AdminDigitalVoucherConfiguratorEffects,
            AdminNewsTickerEffect,
            AdminSortingOrderEffect,
            AdminContentPageEffect,
            AdminManageSpotLightEffects,
            AdminContentBoxEffect,
        ]),
        MatSnackBarModule,
    ],
    exports: [],
})
export class AdminModule {
    static forRoot(config: AdminConfig): ModuleWithProviders<AdminModule> {
        return {
            ngModule: AdminModule,
            providers: [
                AdminService,
                AdminFraudDetectionProductsService,
                AdminReportService,
                AdminFraudLimitsService,
                AdminManageContentService,
                AdminManageProductCategoryService,
                AdminWhiteListingProductsService,
                AdminManageProductContentService,
                AdminWhiteListingUsersService,
                AdminAuditLogsService,
                AdminMerchantService,
                AdminDigitalVoucherConfiguratorService,
                AdminManageSpotLightService,
                { provide: AdminConfigService, useValue: config },
            ],
        };
    }
}
