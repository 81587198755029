import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import {
    ProductContentUpdateRequest,
    ProductContentUpdateResponse,
} from '../models/manageProductContent/updateProductContent.model';
import { ProductSettingsUpdateResponse } from '../models/manageProductContent/updateProductSettings.model';
import { UpdateProductSettingsRequest } from '../models/manageProductContent/updateProductSettings.model';
import {
    ProductCategoriesUpdateRequest,
    ProductCategoriesUpdateRequestModel,
    ProductCategoriesUpdateResponse,
} from '../models/manageProductContent/updateProductCategories.model';

export const getAllProductContent = createAction('[Admin ManageProductContent] getAllProductContent');

export const onGetAllProductContentSuccessfully = createAction(
    '[Admin ManageProductContent] getAllProductContent successfully',
    props<{ payload: ManageProductsResponse }>(),
);

export const onGetAllProductContentFailed = createAction(
    '[Admin ManageProductContent] getAllProductContent failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateContentByProductLine = createAction(
    '[Admin ManageProductContent] updateContentByProductLine',
    props<{ payload: ProductContentUpdateRequest }>(),
);

export const onUpdateContentByProductLineSuccessfully = createAction(
    '[Admin ManageProductContent] updateContentByProductLine successfully',
    props<{ payload: ProductContentUpdateResponse }>(),
);

export const onUpdateContentByProductLineFailed = createAction(
    '[Admin ManageProductContent] updateContentByProductLine failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onUpdateSettingsByProductLine = createAction(
    '[Admin ManageProductSettings] onUpdateSettingsByProductLine',
    props<{ payload: UpdateProductSettingsRequest }>(),
);

export const onUpdateSettingsByProductLineSuccessfully = createAction(
    '[Admin ManageProductContent] onUpdateSettingsByProductLineSuccessfully successfully',
    props<{ payload: ProductSettingsUpdateResponse }>(),
);

export const onUpdateSettingsByProductLineFailed = createAction(
    '[Admin ManageProductContent] onUpdateSettingsByProductLineFailed failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onUpdateProductCategoriesSuccessfully = createAction(
    '[Admin ManageProductContent] onUpdateProductCategoriesSuccessfully',
    props<{ payload: ProductCategoriesUpdateResponse }>(),
);

export const onUpdateProductCategoriesFailed = createAction(
    '[Admin ManageProductContent] onUpdateProductCategoriesFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateProductCategories = createAction(
    '[Admin ManageProductContent] updateProductCategories',
    props<{ payload: ProductCategoriesUpdateRequest }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
