export class UserSearchResponseModel extends Array<UserResponseModel> {}
export class UserResponseModel {
    constructor(
        public userToken: string,
        public email: string,
        public userIssuer: string,
    ) {}
}
export class UserSearchRequestModel {
    constructor(public email: string) {}
}
