<div class="container" *ngIf="!hasWritePermission()">Sorry you don't have read permission.</div>
<div class="container" *ngIf="hasWritePermission()">
    <form class="signup-form" [formGroup]="signupForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>User Name</label>
                    <div class="input-wrapper">
                        <input
                            id="userName"
                            class="form-control"
                            type="text"
                            formControlName="userName"
                            placeholder="User Name"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Role</label>
                    <div class="input-wrapper">
                        <select id="role" class="form-control" formControlName="role">
                            <option *ngFor="let role of roles; let i = index" [value]="role" [selected]="i === 1">
                                {{ role }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Password</label>
                    <div class="input-wrapper">
                        <input
                            id="password"
                            class="form-control"
                            type="password"
                            formControlName="password"
                            placeholder="Password"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label>Confirm Password</label>
                    <div class="input-wrapper">
                        <input
                            id="confirmPassword"
                            class="form-control"
                            type="password"
                            formControlName="confirmPassword"
                            placeholder="Confirm Password"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row messages">
            <div class="col-lg-12">
                <span class="success" *ngIf="isUserAddedSuccessfully">{{userAddedSuccessfullyMessage}}</span>
                <span class="failure" *ngIf="isUserAlreadyExist">{{responseMessage}}</span>
                <span class="failure" *ngIf="getMatchedPasswords()">{{passwordAndConfirmedNotMatchedErrorMsg}}</span>
                <span class="failure" *ngIf="isPasswordWeak">{{weakPasswordErrorMessage}}</span>
                <span class="failure" *ngIf="isSignupFailed">{{errorMessage}}</span>
                <span class="failure" *ngIf="isServerError">{{serverError}}</span>
            </div>
        </div>

        <div class="login-button">
            <button id="saveAddressButton" [disabled]="buttonStatus()" (click)="onLoginUSer()">Add User</button>
        </div>
    </form>
</div>
