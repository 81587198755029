<div class="dgoods-text-image-info">
    <div class="img-container">
        <img [src]="src" />
    </div>

    <div class="text-container">
        <div class="text">
            <p>
                <ng-content></ng-content>
            </p>
        </div>
        <div class="info" (click)="onClick()">
            <img class="info-icon" src="assets/icon-information.svg" />
            <div class="info-text">
                <label>{{ infobutton | injectedText | async }} </label>
            </div>
        </div>
    </div>
</div>
