import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '../admin.config';
import { IBlockedProductModel } from '../models/fraudDetectionProducts/blockedProducts.model';

export interface UnBlockProductResponse {
    message: string;
}

@Injectable({ providedIn: 'root' })
export class AdminFraudDetectionProductsService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly route = '/';
    private readonly getBlockedProductsUrl = 'blockedProducts';
    private readonly analyticsMicroServiceName = 'analytics';
    private readonly unBlockProductUrl = '/unblock/product/';
    private readonly unblockShopUrl = 'unblock/shop';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    public getAllProducts() {
        const url = `${this.server}${this.productMicroServiceName}${this.route}${this.getBlockedProductsUrl}`;
        return this.http.get<IBlockedProductModel>(url);
    }

    public unBlockProduct(product: { payload: string }) {
        const url = `${this.server}${this.analyticsMicroServiceName}${this.unBlockProductUrl}${product.payload}`;
        return this.http.put<UnBlockProductResponse>(url, null);
    }

    public unblockShop() {
        const url = `${this.server}${this.analyticsMicroServiceName}${this.route}${this.unblockShopUrl}`;
        return this.http.put<UnBlockProductResponse>(url, null);
    }
}
