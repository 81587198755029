<ng-container>
    <div class="spotLight-container">
        <div class="roboto-text page-title">
            <div class="module-activation-indicator">
                <span class="text">SpotLight Feature</span>

                <label class="label">
                    <span *ngIf="spotLightSetting; else enableSpan" class="spotLight-feature-span-on">ON</span>
                    <ng-template #enableSpan>
                        <span class="spotLight-feature-span-off">OFF</span>
                    </ng-template>
                </label>

                <div class="buttons" *ngIf="hasWritePermission()">
                    <ng-container>
                        <button
                            *ngIf="spotLightSetting; else enable"
                            (click)="toggleSpotLightFeature()"
                            class="disable-button"
                        >
                            <span class="roboto-text spotLight-text-white">DISABLE SPOTLIGHT FEATURE</span>
                        </button>

                        <ng-template #enable>
                            <button (click)="toggleSpotLightFeature()" class="enable-button">
                                <span class="roboto-text spotLight-text">ENABLE SPOTLIGHT FEATURE</span>
                            </button>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="button-details" *ngIf="hasWritePermission()">
            <button class="green-button" (click)="createSpotLight()">
                <span class="roboto-text spotLight-text">CREATE NEW SPOTLIGHT</span>
            </button>
        </div>
    </div>

    <div class="spotlight-table-container" *ngIf="spotLights && spotLights.length > 0">
        <table mat-table [dataSource]="spotLights" class="mat-table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let spotLight">{{ spotLight.name }}</td>
            </ng-container>

            <ng-container matColumnDef="fromDate">
                <th mat-header-cell *matHeaderCellDef>Start From</th>
                <td mat-cell *matCellDef="let spotLight">{{ spotLight.startFrom | date: 'dd.MM.y HH:mm:ss' }}</td>
            </ng-container>

            <ng-container matColumnDef="toDate">
                <th mat-header-cell *matHeaderCellDef>To Date</th>
                <td mat-cell *matCellDef="let spotLight">{{ spotLight.endAt | date: 'dd.MM.y HH:mm:ss' }}</td>
            </ng-container>

            <ng-container matColumnDef="publishState">
                <th mat-header-cell *matHeaderCellDef>Publishing State</th>
                <td mat-cell *matCellDef="let spotLight">{{ spotLight.published ? 'Published' : 'Unpublished' }}</td>
            </ng-container>

            <ng-container matColumnDef="displayOnAndroid">
                <th mat-header-cell *matHeaderCellDef>DisplayOnAndroid</th>
                <td mat-cell *matCellDef="let spotLight">
                    <mat-slide-toggle
                        color="success"
                        [checked]="spotLight['displayOnAndroid'] !== undefined ? spotLight['displayOnAndroid'] : true"
                        disabled="true"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="displayOnIOS">
                <th mat-header-cell *matHeaderCellDef>DisplayOnIOS</th>
                <td mat-cell *matCellDef="let spotLight">
                    <mat-slide-toggle
                        color="success"
                        [checked]="spotLight['displayOnIOS'] !== undefined ? spotLight['displayOnIOS'] : true"
                        disabled="true"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="imagePreview">
                <th mat-header-cell *matHeaderCellDef>Image Preview</th>
                <td mat-cell *matCellDef="let spotLight">
                    <img class="img-preview" [src]="spotLight.image['en-us']" />
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let spotLight" class="actions">
                    <span *ngIf="hasWritePermission()">
                        <button (click)="togglePublish(spotLight)" class="states activate-button">
                            {{ spotLight.published ? 'Unpublish' : 'Publish' }}
                        </button>
                        <button
                            class="states edit-button"
                            (click)="editSpotLight(spotLight)"
                            [disabled]="spotLight.published"
                        >
                            Edit
                        </button>
                        <button [matMenuTriggerFor]="menu" class="menu-button">
                            <mat-icon class="mat-icon">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [disabled]="spotLight.published" (click)="deleteSpotLight(spotLight)">
                                Delete
                            </button>
                            <button mat-menu-item (click)="copySpotLight(spotLight)">Copy</button>
                        </mat-menu>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</ng-container>
