import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { IDigitalVoucherConfigurator } from '@modeso/types__dgoods-products';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminDigitalVoucherConfiguratorService {
    private server: string;
    private microserviceName = 'products';
    private readonly route = '/';
    private readonly featureRoute = 'digitalvoucher';

    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getDigitalVoucherByProductLineName(productLineName: string): Observable<IDigitalVoucherConfigurator> {
        const url = `${this.server}${this.microserviceName}${this.route}${this.featureRoute}${this.route}${productLineName}`;
        return this.http.get<IDigitalVoucherConfigurator>(url);
    }

    createOrUpdateDigitalVoucher(digitalvoucher: IDigitalVoucherConfigurator): Observable<IDigitalVoucherConfigurator> {
        const url = `${this.server}${this.microserviceName}${this.route}${this.featureRoute}`;
        return this.http.post<IDigitalVoucherConfigurator>(url, digitalvoucher);
    }
}
