import { Component, OnInit, Input } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsTextImageInfoComponent');

export interface IDGoodsTextImageInfoDelegate {
    onInfoClicked(): void;
}

@Component({
    selector: 'dgoods-text-image-info',
    templateUrl: './dgoods-text-image-info.component.html',
    styleUrls: ['./dgoods-text-image-info.component.scss'],
})
export class DGoodsTextImageInfoComponent {
    @Input() infobutton: string;
    @Input() src: string;
    @Input() delegate?: IDGoodsTextImageInfoDelegate;

    /**
     * emit the card product to the parent "product-list" to navigate with it
     */
    onClick() {
        if (this.delegate) {
            this.delegate.onInfoClicked();
        } else {
            debug('onInfoClicked event not fired. There is no delegate attached to the component.');
        }
    }
}
