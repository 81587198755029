import { Component, Injector, OnInit, Inject } from '@angular/core';
import { BasePageComponent } from '../base.page';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({ selector: 'app-popUp-component', templateUrl: './popUp.html' })
export class PopUpComponent extends BasePageComponent implements OnInit {
    ngOnInit() {}
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private injector: Injector,
    ) {
        super(injector);
    }
}
