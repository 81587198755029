import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AdminProvider, UserResponse, UserRolesResponse } from '@modeso/dgoods-lib-admin-fe';
import Debug from 'debug';
import { Subscription } from 'rxjs';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { LocalStorageService } from '../../shared/services/localStorage.service';
const debug = Debug('modeso:dgoods-admin:SidebarComponent');

interface INavigationItems {
    link: [string];
    text: string;
}
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewChecked {
    private readonly subscriptions = new Array<Subscription>();
    public navigation = new Array<INavigationItems>();
    isUserLoggedIn: boolean;

    constructor(
        private readonly localStorageService: LocalStorageService,
        private readonly adminProvider: AdminProvider,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(this.adminProvider.getLoggedInUser$().subscribe(this.updateUserRole.bind(this)));
        this.subscriptions.push(this.adminProvider.getUserRoles$().subscribe(this.setUserRolesInStorage.bind(this)));
        this.subscriptions.push(LocalStorageService.UserRoleObserver$.subscribe(this.updateNavigation.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    ngAfterViewChecked() {
        this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
        this.changeDetector.detectChanges();
    }

    public hasRole(roles: string[], role: string) {
        switch (role) {
            case 'ADMIN':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_ADMIN);
            case 'CONTENT_MANAGER':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER);
            case 'FRAUD_MANAGER':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER);
            case 'FRAUD_VIEWER':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER);
            case 'PRODUCT_MANAGER':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER);
            case 'REPORTING_MANAGER':
                return roles.some((role) => role === UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER);
            case 'UNDEFINED':
                return roles.some((role) => role === UserRoles.UNDEFINED);
            default:
                return roles.some((role) => role === UserRoles.UNDEFINED);
        }
    }

    updateNavigation(roles: string[]) {
        debug('updateNavigation for role;');
        debug(roles);

        const navigationItems: INavigationItems[] = [{ link: ['/de-ch/'], text: 'Home' }];

        if (roles) {
            // Admin
            if (this.hasRole(roles, 'ADMIN')) {
                navigationItems.push(
                    { link: ['/de-ch/user-management/admin-users'], text: 'User Management' },
                    { link: ['/de-ch/product-managment/blocked-products'], text: 'Product Management' },
                    { link: ['/de-ch/merchant-list'], text: 'Merchant Management' },
                    { link: ['/de-ch/reports'], text: 'Reports' },
                    { link: ['/de-ch/order-reports'], text: 'Orders Reports' },
                    { link: ['/de-ch/fraud-popups'], text: 'Manage Fraud Popups' },
                    { link: ['/de-ch/fraudLimits'], text: 'Fraud Limits' },
                    { link: ['/de-ch/cms/text-and-labels'], text: 'Manage Content' },
                    { link: ['/de-ch/coupons-management'], text: 'Manage Coupons' },
                    { link: ['/de-ch/newstickers'], text: 'Manage Newstickers' },
                    { link: ['/de-ch/contentpages'], text: 'Manage Content Pages' },
                    { link: ['/de-ch/spot-lights'], text: 'Manage Spotlights' },
                    { link: ['/de-ch/contentboxes'], text: 'Manage Content Boxes' },
                );
            }

            // Product Managment
            if (
                this.hasRole(roles, 'PRODUCT_MANAGER')
                || this.hasRole(roles, 'FRAUD_MANAGER')
                || this.hasRole(roles, 'FRAUD_VIEWER')
                || this.hasRole(roles, 'CONTENT_MANAGER')
            ) {
                navigationItems.push({
                    link: ['/de-ch/product-managment/blocked-products'],
                    text: 'Product Management',
                });
            }

            if (this.hasRole(roles, 'FRAUD_MANAGER') || this.hasRole(roles, 'PRODUCT_MANAGER')) {
                navigationItems.push({ link: ['/de-ch/coupons-management'], text: 'Manage Coupons' });
                navigationItems.push({ link: ['/de-ch/fraud-popups'], text: 'Manage Fraud Popups' });
            }

            // Merchant Managment
            if (this.hasRole(roles, 'PRODUCT_MANAGER')) {
                navigationItems.push(
                    { link: ['/de-ch/merchant-list'], text: 'Merchant Management' },
                    { link: ['/de-ch/newstickers'], text: 'Manage Newstickers' },
                    { link: ['/de-ch/contentpages'], text: 'Manage Content Pages' },
                    { link: ['/de-ch/contentboxes'], text: 'Manage Content Boxes' },
                    { link: ['/de-ch/spot-lights'], text: 'Manage Spotlights' },
                    { link: ['/de-ch/reports'], text: 'Reports' },
                );
            }

            // Reports
            if (this.hasRole(roles, 'REPORTING_MANAGER')) {
                navigationItems.push({ link: ['/de-ch/reports'], text: 'Reports' });
                navigationItems.push({ link: ['/de-ch/order-reports'], text: 'Orders Reports' });
            }

            // Fraud Limits
            if (
                this.hasRole(roles, 'FRAUD_MANAGER')
                || this.hasRole(roles, 'PRODUCT_MANAGER')
                || this.hasRole(roles, 'FRAUD_VIEWER')
            ) {
                navigationItems.push({ link: ['/de-ch/fraudLimits'], text: 'Fraud Limits' });
                navigationItems.push({ link: ['/de-ch/auditLogs'], text: 'Audit Logs' });
                navigationItems.push({ link: ['/de-ch/order-reports'], text: 'Orders Reports' });
            }

            // Manage Content
            if (this.hasRole(roles, 'CONTENT_MANAGER') || this.hasRole(roles, 'PRODUCT_MANAGER')) {
                navigationItems.push({ link: ['/de-ch/cms/text-and-labels'], text: 'Manage Content' });
            }
        }
        this.navigation = navigationItems;
    }

    updateUserRole(user: UserResponse) {
        debug('updateUserRole:');
        debug(user);
        if (user) {
            this.localStorageService.setUserRole([user.role]);
        } else {
            debug('take from localstorage:');
            if (this.localStorageService.getUserRole() !== undefined) {
                this.updateNavigation(this.localStorageService.getUserRole());
            }
        }
    }

    setUserRolesInStorage(response: UserRolesResponse) {
        if (response && response.roles) {
            this.localStorageService.setUserRole(response.roles);
        }
    }

    getRole() {
        const roles = this.localStorageService.getUserRole();
        if (roles) {
            let roleStr = '';
            for (const role of roles) {
                switch (role) {
                    case UserRoles.DIGITAL_VAUCHERS_ADMIN:
                        roleStr = roleStr + 'Admin, ';
                        break;
                    case UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER:
                        roleStr = roleStr + 'Content Manager, ';
                        break;
                    case UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER:
                        roleStr = roleStr + 'Fraud Manager, ';
                        break;
                    case UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER:
                        roleStr = roleStr + 'Fraud Viewer, ';
                        break;
                    case UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER:
                        roleStr = roleStr + 'Product Manager, ';
                        break;
                    case UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER:
                        roleStr = roleStr + 'Reporting Manager, ';
                        break;
                    default:
                        roleStr = roleStr + 'UNDEFINED, ';
                        break;
                }
            }
            return roleStr.substring(0, roleStr.length - 2);
        }
        return 'UNDEFINED';
    }
}
