import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminContentPageProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-content-page.provider';
import { BasePageComponent } from '../../base.page';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';

@Component({
    selector: 'app-content-page-list',
    templateUrl: './content-pages-list.page.html',
    styleUrls: ['./content-pages-list.page.scss'],
})
export class ContentPageListComponent extends BasePageComponent implements OnInit {
    contentPages: IContentPageResponse[];

    displayedColumns: string[] = ['title', 'content', 'sortingOrder', 'actions'];

    constructor(
        private router: Router,
        private contentPageProvider: AdminContentPageProvider,
        private injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.loadContentPages();
    }

    loadContentPages() {
        this.subscriptions.push(
            this.contentPageProvider.getAllContentPages().subscribe((response) => {
                this.contentPages = response;
            }),
        );
    }

    createContentPage() {
        this.router.navigate(['/admin/create-contentpages/create']);
    }

    editContentPage(contentPage: IContentPageResponse) {
        this.router.navigate(['/admin/create-contentpages', contentPage._id]);
    }

    togglePublish(contentPage: IContentPageResponse) {
        const updatedContentPage = {
            _id: contentPage._id,
            title: null,
            content: null,
            sortId: contentPage.sortId,
            published: !contentPage.published,
            images: contentPage.images,
            icon: contentPage.icon,
        };
        this.contentPageProvider.updateContentPage(updatedContentPage);
    }
}
