<div class="export-file-cost">
    <!-- Loading spinner -->
    <div *ngIf="loading$ | async" class="dialog-overlay">
        <div class="spinner-container">
            <mat-spinner [value]="50" [diameter]="50"></mat-spinner>
            <p>Please wait, your data is being processed...</p>
        </div>
    </div>

    <div>
        <span class="title"> {{ title }} </span>
        <span class="hint"
            >[Not selecting a specific option, will be automatically considered as selecting all.This goes for coupons
            or finance parties.]
        </span>
    </div>

    <div class="form-group-container" [formGroup]="formGroup">
        <div>
            <span class="mr-10">Finance Party Name:</span>
            <app-multi-select-component
                [list]="financeParties$ | async"
                [title]="'Selected Finance Parties'"
                (selectedListChange)="selectingFinanceParties($event)"
            ></app-multi-select-component>
        </div>

        <div>
            <span class="mr-10">Dates:</span>

            <mat-form-field class="mr-10" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    [ngxMatDatetimePicker]="picker"
                    formControlName="startFrom"
                    placeholder="dd.mm.yyyy, hh:ss"
                />
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds"> </ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>End Date</mat-label>
                <input
                    [min]="startFrom.value"
                    matInput
                    [ngxMatDatetimePicker]="endPicker"
                    formControlName="endAt"
                    placeholder="dd.mm.yyyy, hh:ss"
                />
                <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #endPicker [showSeconds]="showSeconds"> </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>

        <div>
            <span class="mr-10">Coupons:</span>
            <app-multi-select-component
                [list]="coupons$ | async"
                [title]="'Selected Coupons'"
                (selectedListChange)="selectingCoupons($event)"
            ></app-multi-select-component>
        </div>
    </div>
    <mat-dialog-actions class="btn-container">
        <button mat-button class="cancel-btn" (click)="cancel()">Cancel</button>
        <button mat-button class="save-btn" (click)="export()" [disabled]="(loading$ | async) || formGroup.invalid">
            Export
        </button>
    </mat-dialog-actions>

    <!-- Error message -->
    <div *ngIf="error$ | async as error" class="error-message">
        {{ error.parsedMessage ?? 'CSV generation failed. Please try again.' }}
    </div>
</div>
