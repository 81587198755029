import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsButtonComponent');
export interface IDGoodsButtonDelegate {
    onButtonClicked(sender: DGoodsButtonComponent): void;
}

@Component({
    selector: 'dgoods-button',
    templateUrl: './dgoods-button.component.html',
    styleUrls: ['./dgoods-button.component.scss'],
})
export class DGoodsButtonComponent {
    private _state: string = 'normal';
    @ViewChild('button', { static: true }) button: ElementRef;
    @Input() delegate?: IDGoodsButtonDelegate;
    @Input() disabled?;
    @Input()
    set state(state: string) {
        this._state = state && state.trim();
        switch (this._state) {
            case 'normal':
                this.button.nativeElement.className = 'dgoods-button';
                break;
            case 'notAction':
                this.button.nativeElement.className = 'dgoods-button notAction';
                break;
            default:
                break;
        }
    }
    get state(): string {
        return this._state;
    }

    onClick() {
        if (this.delegate) {
            this.delegate.onButtonClicked(this);
        } else {
            debug('onClick event not fired. No delegate defined for the component.');
        }
    }
}
