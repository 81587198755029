import { Component, Injector, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { MatDialog } from '@angular/material/dialog';
import Debug from 'debug';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { Router } from '@angular/router';

const debug = Debug('modeso:dgoods-admin:campaigns');

@Component({ selector: 'app-page-coupons', templateUrl: './coupons.page.html', styleUrls: ['./coupons.page.scss'] })
export class CouponsPage extends BasePageComponent implements OnInit {
    public readPermission: boolean;
    public writePermission: boolean;

    constructor(
        injector: Injector,
        private router: Router,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);

        // get coupons from store as observable and map it to set paginator
        this.readPermission = this.hasReadPermission();
        this.writePermission = this.hasWritePermission();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    public hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    addCoupon() {
        this.router.navigate([`/de-ch/add-coupon`]);
    }
}
