import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminManageSpotLightsProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-manageSpotLight.provider';
import { BasePageComponent } from '../../base.page';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeletionDialog } from '@modeso/twint-lib-couponsmng-fe/dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmFeatureDialog } from '@modeso/twint-lib-couponsmng-fe';
import { ISpotLight } from '@modeso/types__dgoods-products';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { UserRoles } from '../../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../../shared/util/permission.helper';

@Component({
    selector: 'app-spot-light-list',
    templateUrl: './list-spotlights.component.html',
    styleUrls: ['./list-spotlights.component.scss'],
})
export class ListSpotLightsComponent extends BasePageComponent implements OnInit {
    spotLights: ISpotLight[];
    displayedColumns: string[] = [
        'name',
        'fromDate',
        'toDate',
        'displayOnAndroid',
        'displayOnIOS',
        'imagePreview',
        'publishState',
        'actions',
    ];
    spotLightSetting = false;

    constructor(
        private router: Router,
        private spotLightProvider: AdminManageSpotLightsProvider,
        private injector: Injector,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadAllSpotLights();
        this.getSpotLightSetting();
    }

    loadAllSpotLights() {
        this.spotLightProvider.fetchSpotLights();
        this.subscriptions.push(
            this.spotLightProvider.getSpotLights$().subscribe((spotlights) => {
                if (spotlights.length > 0) {
                    this.spotLights = spotlights;
                }
            }),
        );
    }

    getSpotLightSetting() {
        this.subscriptions.push(
            this.spotLightProvider.getSpotLightSetting$().subscribe((response) => {
                this.spotLightSetting = response;
            }),
        );
    }

    createSpotLight() {
        this.router.navigate(['/de-ch/spot-light']);
    }

    editSpotLight(spotLight: ISpotLight) {
        this.router.navigate(['/de-ch/spot-light/', spotLight.id]);
    }

    togglePublish(spotLight: ISpotLight) {
        const updatedSpotLight = { ...spotLight };
        updatedSpotLight.published = !updatedSpotLight.published;
        this.spotLightProvider.updateSpotLight(updatedSpotLight);
    }

    toggleSpotLightFeature() {
        const dialogRef = this.dialog.open(ConfirmFeatureDialog, { data: { activate: !this.spotLightSetting } });

        this.subscriptions.push(
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.spotLightProvider.updateSpotLightSetting$({ spotLightFeature: !this.spotLightSetting });
                }
            }),
        );
    }

    deleteSpotLight(spotLight: ISpotLight) {
        const dialogRef = this.dialog.open(ConfirmDeletionDialog, {
            data: { message: `Are you sure you want to delete ${spotLight.name}?` },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.spotLightProvider.deleteSpotLight(spotLight.id);
            }
        });
    }

    copySpotLight(spotLight: ISpotLight) {
        const copiedSpotlight: ISpotLight = {
            image: spotLight.image,
            name: spotLight.name,
            startFrom: spotLight.startFrom,
            endAt: spotLight.endAt,
            navigationType: spotLight.navigationType,
            productLineId: spotLight.productLineId,
            contentPageId: spotLight.contentPageId,
            navigationUrl: spotLight.navigationUrl,
            published: false,
            displayOnAndroid: spotLight.displayOnAndroid,
            displayOnIOS: spotLight.displayOnIOS,
        };
        this.spotLightProvider.storeCopiedSpotlight(copiedSpotlight);
        this.createSpotLight();
    }

    public hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    public hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
