import { createSelector } from '@ngrx/store';
import { selectAllFraudRules } from '../reducer/cart.reducer';
import { selectCartState } from './cart.selectors';

export const selectFraudRulesState = createSelector(
    selectCartState,
    state => state.fraudRules
);

export const selectAllFraudRulesList = createSelector(
    selectFraudRulesState,
    selectAllFraudRules
);
