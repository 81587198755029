<div class="container" *ngIf="hasReadPermission()">
    <!-- <div *ngIf="allNotes?.length === 0" class="alert alert-info col-xs-7" role="alert">
        No Notes Available
    </div> -->
    <div class="row col-md-7">
        <h4>Blocked Products</h4>
        <div class="alert alert-info" *ngIf="noBlockedProductsExist">{{noBlockedProductsMessage}}</div>
        <div class="alert alert-success" *ngIf="productUnBlockedSuccesfully">{{productUnBlockedSuccsfullyMessage}}</div>
        <div class="alert alert-danger" *ngIf="errorInUnBlockProduct">{{errorMessageInUnBlockProduct}}</div>
        <div class="alert alert-danger" *ngIf="errorInGetBlockedProducts">{{errorMessageInGetBlockedProducts}}</div>
    </div>
    <div *ngIf="!errorInGetBlockedProducts && !noBlockedProductsExist" class="col-xs-7">
        <div
            *ngFor="let blockedProduct of blockedProducts.products;let id=index"
            [id]="id"
            class="list-group-item clearfix row"
        >
            <div class="pull-left">
                <h4 class="list-group-item-heading">{{blockedProduct}}</h4>
            </div>
            <div class="pull-right">
                <button *ngIf="hasWritePermission()" class="btn btn-primary" mat-button (click)="onClick(id)">
                    Unblock
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
