<div class="dgoods-textarea">
    <textarea
        #input
        (keyup)="onKeyUp(input.value)"
        (focus)="onFocus()"
        maxlength="250"
        (blur)="onBlur(input.value)"
        [placeholder]="caption | injectedText | async"
        [value]="value"
    ></textarea>
    <label *ngIf="!captionHidden">{{ caption | injectedText | async }}</label>
</div>
