import { NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { DGoodsTitleComponent } from './components/title-component/title.component';
import { DGoodsViewContainerComponent } from './components/view-container-component/view.component';
import { DGoodsTextImageInfoComponent } from './components/dgoods-text-image-info/dgoods-text-image-info.component';
import { DGoodsButtonComponent } from './components/dgoods-button/dgoods-button.component';
import { DGoodsInputComponent } from './components/dgoods-input/dgoods-input.component';
import { DGoodsTextAreaComponent } from './components/dgoods-textarea/dgoods-textarea.component';
import { DGoodsCheckboxComponent } from './components/dgoods-checkbox/dgoods-checkbox.component';
import { DGoodsPriceOptionComponent } from './components/dgoods-price-option/dgoods-price-option.component';
import { DGoodsPriceOptionGroupComponent } from './components/dgoods-price-option-group/dgoods-price-option-group.component';
import { DGoodsPriceOptionDenominationComponent } from './components/dgoods-price-option/dgoods-price-option-denomination.component';
import { DGoodsEmailConfirmationComponent } from './components/dgoods-email-confirmation/dgoods-email-confirmation.component';
import { DGoodsTableCellComponent } from './components/dgoods-table/dgoods-table-cell.component';
import { DGoodsTableComponent } from './components/dgoods-table/dgoods-table.component';
import { DGoodsBackButtonComponent } from './components/dgoods-button/dgoods-back-button.component';
import { DGoodsOverlayComponent } from './components/dgoods-overlay/dgoods-overlay.component';
import { DGoodsNavigationComponent } from './components/dgoods-navigation/dgoods-navigation.component';
import { DGoodsTutorialElementComponent } from './components/dgoods-tutorial-element/dgoods-tutorial-element.component';
import { DGoodsTutorialOverlayComponent } from './components/dgoods-tutorial-overlay/dgoods-tutorial-overlay.component';
//TODO: Fix Components: They don't build with Angular 13
//import { DgoodsOrderConfirmationComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation.component';
// tslint:disable-next-line: max-line-length
//import { DgoodsOrderConfirmationDetailsComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation-details/dgoods-order-confirmation-details.component';
import { InjectedTextFeautureModule, InjectedTextPipe, ModesoCoreModule } from '@modeso/modeso-lib-core-fe';
import { DgoodsTitleWithContentComponent } from './components/dgoods-title-with-content/dgoods-title-with-content.component';
import { DgoodsTermsOfServiceComponent } from './components/dgoods-terms-of-service/dgoods-terms-of-service.component';
import { DgoodsDotsLoadingIndicatorComponent } from './components/dgoods-dots-loading-indicator/dgoods-dots-loading-indicator.component';
import { DGoodsDialogBoxComponent } from './components/dgoods-dialogbox/dgoods-dialogbox.component';
import { DgoodsNewstickerComponent } from './components/dgoods-newsticker/dgoods-newsticker.component';
import { CurrencyNumberPipe } from './pipes/currency.pipe';
import { GetEnumKeyFromValuePipe } from './pipes/get-enum-key-from-value.pipe';
import { PascalCasePipe } from './pipes/pascal-case.pipe';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { FileService } from './services/file.service';

@NgModule({
    declarations: [
        DGoodsTitleComponent,
        DGoodsViewContainerComponent,
        DGoodsTextImageInfoComponent,
        DGoodsButtonComponent,
        DGoodsBackButtonComponent,
        DGoodsInputComponent,
        DGoodsTextAreaComponent,
        DGoodsEmailConfirmationComponent,
        DGoodsCheckboxComponent,
        DGoodsPriceOptionComponent,
        DGoodsPriceOptionDenominationComponent,
        DGoodsPriceOptionGroupComponent,
        DGoodsTableCellComponent,
        DGoodsTableComponent,
        DGoodsOverlayComponent,
        DGoodsNavigationComponent,
        DGoodsTutorialElementComponent,
        DGoodsTutorialOverlayComponent,
        DgoodsTitleWithContentComponent,
        DgoodsTermsOfServiceComponent,
        DGoodsDialogBoxComponent,
        DgoodsDotsLoadingIndicatorComponent,
        DgoodsNewstickerComponent,
        CurrencyNumberPipe,
        GetEnumKeyFromValuePipe,
        PascalCasePipe,
        SafeHtmlPipe,
        MarkdownPipe,
        MultiSelectComponent,
    ],
    providers: [InjectedTextPipe, SafeHtmlPipe, MarkdownPipe, FileService],
    imports: [
        CommonModule,
        InjectedTextFeautureModule,
        ClipboardModule,
        ModesoCoreModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatAutocompleteModule,
    ],
    exports: [
        CurrencyNumberPipe,
        GetEnumKeyFromValuePipe,
        PascalCasePipe,
        SafeHtmlPipe,
        MarkdownPipe,
        DGoodsTitleComponent,
        DGoodsViewContainerComponent,
        DGoodsTextImageInfoComponent,
        DGoodsButtonComponent,
        DGoodsBackButtonComponent,
        DGoodsInputComponent,
        DGoodsTextAreaComponent,
        DGoodsEmailConfirmationComponent,
        DGoodsCheckboxComponent,
        DGoodsPriceOptionComponent,
        DGoodsPriceOptionDenominationComponent,
        DGoodsPriceOptionGroupComponent,
        DGoodsTableCellComponent,
        DGoodsTableComponent,
        DGoodsOverlayComponent,
        DGoodsNavigationComponent,
        DGoodsDialogBoxComponent,
        DGoodsTutorialElementComponent,
        DGoodsTutorialOverlayComponent,
        DgoodsTitleWithContentComponent,
        DgoodsTermsOfServiceComponent,
        DgoodsDotsLoadingIndicatorComponent,
        DgoodsNewstickerComponent,
        MultiSelectComponent,
    ],
})
export class DgoodsSharedModule {}
