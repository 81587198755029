import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-text-form',
    templateUrl: './text-form.component.html',
    styleUrls: ['./text-form.component.scss'],
})
export class TextFormComponent {
    @Input() title: string;
    @Input() form: FormGroup;
    @Input() name: string;
    constructor() {}
}
