import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-dialog',
    template: `
        <h2 mat-dialog-title>{{ data.title ? data.title : 'Attention' }}</h2>
        <mat-dialog-content>
            <p>{{ data.message }}</p>
        </mat-dialog-content>
        <mat-dialog-actions style="justify-content: center;">
            <button mat-button (click)="onNoClick()">Ok</button>
        </mat-dialog-actions>
    `,
    styles: [],
})
export class ErrorDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
