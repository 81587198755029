import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import { DefaultFraudLimitsRequest } from '../models/fraudLimits/defaultFraudLimits.model';
import {
    IssuerLimitsOfAddOrUpdateRequest,
    IssuerLimitsResponse,
    IssuerLimitsOfDeleteRequest,
} from '../models/fraudLimits/issuerLimits.model';

@Injectable({ providedIn: 'root' })
export class AdminFraudLimitsService {
    private server = '';
    private analyticsMicroserviceName = 'analytics';
    private readonly route = '/';
    private readonly fraudLimits = 'limits';
    private readonly updateDefaultFRaudLimit = 'fraud/update';
    private readonly addIssuerUrl = 'addIssuer';
    private readonly updateIssuerUrl = 'updateIssuer';
    private readonly deleteIssuerUrl = 'deleteIssuer';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getFraudLimits() {
        const url = `${this.server}${this.analyticsMicroserviceName}${this.route}${this.fraudLimits}`;
        return this.http.get<FraudLimitsResponse[]>(url);
    }

    updateDefaultFraudLimit(defaultFraud: { payload: DefaultFraudLimitsRequest }) {
        const url =
            // tslint:disable-next-line: max-line-length
            `${this.server}${this.analyticsMicroserviceName}${this.route}${this.updateDefaultFRaudLimit}${this.route}${defaultFraud.payload.fraudLimit}`;
        return this.http.put<string>(url, { fraudLimitValue: Number(defaultFraud.payload.fraudLimitValue) });
    }

    addIssuer(issuer: { payload: IssuerLimitsOfAddOrUpdateRequest }) {
        const issuerUrl =
            // tslint:disable-next-line: max-line-length
            `${issuer.payload.name}${this.route}${issuer.payload.value}${this.route}${issuer.payload.timeframeName}${this.route}${issuer.payload.timeframeValue}`;
        const url = `${this.server}${this.analyticsMicroserviceName}${this.route}${this.addIssuerUrl}${this.route}${issuerUrl}`;
        return this.http.post<IssuerLimitsResponse>(url, null);
    }

    updateIssuer(issuer: { payload: IssuerLimitsOfAddOrUpdateRequest }) {
        const issuerUrl =
            // tslint:disable-next-line: max-line-length
            `${issuer.payload.name}${this.route}${issuer.payload.value}${this.route}${issuer.payload.timeframeName}${this.route}${issuer.payload.timeframeValue}`;
        const url = `${this.server}${this.analyticsMicroserviceName}${this.route}${this.updateIssuerUrl}${this.route}${issuerUrl}`;
        return this.http.put<IssuerLimitsResponse>(url, null);
    }

    deleteIssuer(issuer: { payload: IssuerLimitsOfDeleteRequest }) {
        const url = `${this.server}${this.analyticsMicroserviceName}${this.route}${this.deleteIssuerUrl}${this.route}${issuer.payload.issuerName}`;
        return this.http.delete<IssuerLimitsResponse>(url);
    }
}
