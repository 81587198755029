import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe, Inject, Optional } from '@angular/core';
import { marked } from 'marked';
import Debug from 'debug';
import { MARKED_CONFIG, MODESO_DEFAULT_MARKED_CONFIG } from './marked.config';
import { SafeHtmlPipe } from './safeHtml.pipe';
const debug = Debug('modeso:core:MarkdownPipe');
@Pipe({ name: 'markdownToSafeHtml' })
export class MarkdownPipe implements PipeTransform {
    protected config: marked.MarkedOptions;

    constructor(
        private safeHtmlPipe: SafeHtmlPipe,
        @Optional() @Inject(MARKED_CONFIG) config: marked.MarkedOptions,
    ) {
        if (config) {
            this.config = config;
        } else {
            this.config = MODESO_DEFAULT_MARKED_CONFIG;
        }
        marked.setOptions(this.config);
    }

    public transform(value: any, type?: string): SafeHtml {
        if (value === undefined || value === null) {
            return this.safeHtmlPipe.transform('', type ? type : 'string');
        }
        return this.safeHtmlPipe.transform(marked.parse(value), type ? type : 'string');
    }
}
