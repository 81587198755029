import { Injectable, InjectionToken, Inject } from '@angular/core';
import Debug from 'debug';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay, take } from 'rxjs/operators';
import { AdminProvider } from '../provider/admin.provider';
const debug = Debug('dgoods:session:AuthHandler');

export interface IAuthHandlerDelegate {
    onSessionExpired();
}

export const AUTH_INTERCEPTOR = new InjectionToken<IAuthHandlerDelegate>('AUTH_INTERCEPTOR');
@Injectable()
export class AuthHandler implements HttpInterceptor {
    constructor(
        private sessionProviders: AdminProvider,
        @Inject(AUTH_INTERCEPTOR) private authHandlerDelegate: IAuthHandlerDelegate,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq;

        if (!request.url.includes('storage.googleapis.com')) {
            modifiedReq = request.clone({
                headers: request.headers.set('content-language', 'en-us'),
                withCredentials: true,
            });
        } else {
            modifiedReq = request.clone({});
        }

        return next.handle(modifiedReq).pipe(
            catchError((err) => {
                if (err.status === 440) {
                    // redirect to checkin again
                    // localStorage.clear();

                    if (this.authHandlerDelegate) {
                        debug('onSessionExired');
                        debug(modifiedReq.url);
                        this.authHandlerDelegate.onSessionExpired();
                    } else {
                        debug('onSessionExired event not fired. No delegate defined for the AuthHandler.');
                    }
                }

                return throwError(() => err);
            }),
            retryWhen((errors) =>
                errors.pipe(
                    concatMap((error) => {
                        if (error.status === 401) {
                            debug('JWT Token exired, lease new one');
                            debug(modifiedReq.url);
                            this.sessionProviders.getNewAccsessToken$();
                            return of(error.status);
                        }
                        return throwError(() => error);
                    }),
                    take(2),
                    delay(1000),
                ),
            ),
        );
    }
}
