import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActionsAdminFraudLimits from './../actions/admin-fraudLimits.actions';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import * as AdminFromActions from './../actions/admin.actions';
import * as fromWhitelistUsersActions from './../actions/admin.whiteListingUsers.actions';
import { UserResponse } from '../models/administration/userResponse.model';
import * as FraudDetectionProductsFromActions from '../actions/admin.fraudDetectionProducts.actions';
import { IBlockedProductModel } from '../models/fraudDetectionProducts/blockedProducts.model';
import { UnBlockProductResponse } from '../services/admin.fraudDetectionProducts.service';
import * as fromAdminReportActions from './../actions/admin-reports.action';
import { reportResponse } from '../services/admin-report.service';
import * as fromAdminManageContentActions from './../actions/admin-manageContent.actions';
import * as fromAdminManageProductContentActions from './../actions/admin-manageProductContent.actions';
import * as fromAdminManageProductCategoryActions from './../actions/admin-manageProductCategory.actions';
import { AllTranslationsDetails } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import * as AdminWhiteListProducts from './../actions/admin.whiteListingProducts.actions';
import { WhiteListingResponseModel } from '../models/whiteListingProducts/whiteListingResponse.model';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import { ProductContentUpdateResponse } from '../models/manageProductContent/updateProductContent.model';
import { AdminUsersResponse, IAdminUserModel } from '../models/administration/usersResponse.model';
import { WhitelistedUsersResponseModel } from '../models/whiteListUsers/whitelistedUserResponse.model';
import { UserSearchResponseModel } from '../models/whiteListUsers/userSearch.model';
import { AddOrRemoveWhitelistedUserResponseModel } from '../models/whiteListUsers/addOrRemoveWhitelistedUser.response.model';
import {
    IssuerLimitsOfAddOrUpdateRequest,
    IssuerLimitsOfDeleteRequest,
} from '../models/fraudLimits/issuerLimits.model';
import { ProductSettingsUpdateResponse } from '../models/manageProductContent/updateProductSettings.model';
import * as fromActionsAdminAuditLogs from './../actions/admin-auditLogs.actions';
import { AuditLogsResponse } from '../models/auditLogs/auditLogsResponse.model';
import { UserRolesResponse } from '../models/administration/userRolesResponse.model';
import {
    IDigitalVoucherConfigurator,
    IMerchantResponse,
    ProductContentDto,
    ProductLineForManagementDto,
    ProductsWithAvailablePricesDto,
    INewstickerResponse,
} from '@modeso/types__dgoods-products';
import { IContentPageResponse } from '../models/content.pages';

import * as fromActionsAdminMerchant from './../actions/admin-merchant.actions';
import * as fromActionsAdminDigitalVoucher from './../actions/admin-digitalvoucher.actions';
import * as fromActionsAdminNewsTicker from '../actions/admin-newsticker.action';
import * as fromActionsContentPage from '../actions/admin-content-page.action';
import * as fromActionsContentBox from '../actions/admin-content-box.action';

import * as fromActionsAdminSortingOrder from '../actions/admin-sortingOrder.actions';
import Debug from 'debug';
import { ProductCategoriesUpdateResponse } from '../models/manageProductContent/updateProductCategories.model';
import * as fromAdminManageSpotLightsActions from './../actions/admin-manageSpotLights.actions';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
const debug = Debug('modeso:dgoods-admin:modesoAdminMicroservice:Reducer');

export const adminFeatureKey = 'modesoAdminMicroservice';

// State Declarations - START

export interface FeatureState {
    fraudLimits: Array<FraudLimitsResponse>;
    addOrUpdateLimitResponse: FraudLimitsResponse | IssuerLimitsOfDeleteRequest | IssuerLimitsOfAddOrUpdateRequest;
    login: UserResponse;
    signup: UserResponse;
    allProductsAllLanguages: ProductsWithAvailablePricesDto[];
    whiteList: WhiteListingResponseModel;
    products: IBlockedProductModel;
    categories: any;
    synchronize: string | null;
    unBlockState: UnBlockProductResponse;
    report: reportResponse;
    localizations: Array<AllTranslationsDetails>;
    productContent: ManageProductsResponse;
    adminUsers: AdminUsersResponse;
    whitelistedUsers: WhitelistedUsersResponseModel;
    searchUserResult: UserSearchResponseModel;
    addOrRemoveUserFromWhitelistResponse: AddOrRemoveWhitelistedUserResponseModel;
    auditLogsResponse: AuditLogsResponse;
    userRoles: UserRolesResponse;
    error: any;
    errorFraudDefaultLimit: HttpErrorResponse;
    errorFraudIssuerLimit: HttpErrorResponse;
    errorOnMerchantCreationFailed?: HttpErrorResponse;
    errorDigitalVoucherConfig?: HttpErrorResponse;
    merchant: IMerchantResponse;
    merchants: IMerchantResponse[];
    digitalVoucherConfigurator: IDigitalVoucherConfigurator;
    newsticker: INewstickerResponse;
    newstickers: INewstickerResponse[];
    contentPage: IContentPageResponse;
    contentPagesList: IContentPageResponse[];
    contentBox: IContentBoxResponse;
    contentBoxesList: IContentBoxResponse[];
    productLineNames: string[];
    sortingOrderSuccess: boolean;
    spotLights: any[];
    spotLightSetting: any;
    copiedSpotlight: any;
}

export interface AppState {
    modesoAdminMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoAdminMicroservice;

export const featureFraudLimits = createSelector(selectFeature, (state: FeatureState) => state.fraudLimits);

export const featureFraudUpdateResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.addOrUpdateLimitResponse,
);

export const selectFeatureLogin = createSelector(selectFeature, (state: FeatureState) => state.login);

export const featureReport = createSelector(selectFeature, (state: FeatureState) => state.report);

export const selectFeatureSignup = createSelector(selectFeature, (state: FeatureState) => state.signup);

export const selectFeatureLocalizations = createSelector(selectFeature, (state: FeatureState) => state.localizations);
export const selectFeatureProductContent = createSelector(selectFeature, (state: FeatureState) => state.productContent);
export const selectFeatureProductCategory = createSelector(selectFeature, (state: FeatureState) => state.categories);

export const selectFeatureProducts = createSelector(selectFeature, (state: FeatureState) => state.products);
export const selectFeatureUnBlockResponse = createSelector(selectFeature, (state: FeatureState) => state.unBlockState);

export const selectFeatureGetAllProductsAllLanguages = createSelector(
    selectFeature,
    (state: FeatureState) => state.allProductsAllLanguages,
);

export const selectFeatureWhiteListResponse = createSelector(selectFeature, (state: FeatureState) => state.whiteList);

export const selectFeatureSynchronizeProductsResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.synchronize,
);

export const selectFeatureAdminUsers = createSelector(selectFeature, (state: FeatureState) => state.adminUsers);

export const selectFeatureWhitelistedUsers = createSelector(
    selectFeature,
    (state: FeatureState) => state.whitelistedUsers,
);

export const selectFeatureSearchUserResult = createSelector(
    selectFeature,
    (state: FeatureState) => state.searchUserResult,
);

export const selectFeatureAddOrRemoveUserFromWhitelistResponse = createSelector(
    selectFeature,
    (state: FeatureState) => state.addOrRemoveUserFromWhitelistResponse,
);

export const selectFeatureUserRoles = createSelector(selectFeature, (state: FeatureState) => state.userRoles);

export const featureError = createSelector(selectFeature, (state: FeatureState) => state.error);
export const featureErrorFraudDefault = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorFraudDefaultLimit,
);
export const featureErrorFraudIssuer = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorFraudIssuerLimit,
);
export const featureAuditLogsResponse = createSelector(selectFeature, (state: FeatureState) => state.auditLogsResponse);

export const selectFeatureMerchantsList = createSelector(selectFeature, (state: FeatureState) => state.merchants);
export const selectFeatureDigitalVoucher = createSelector(
    selectFeature,
    (state: FeatureState) => state.digitalVoucherConfigurator,
);
export const selectFeatureDigitalVoucherErrors = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorDigitalVoucherConfig,
);

export const selectFeatureNewstickerList = createSelector(selectFeature, (state: FeatureState) => state.newstickers);

export const selectFeatureContentPagesList = createSelector(
    selectFeature,
    (state: FeatureState) => state.contentPagesList,
);

export const selectFeatureContentBoxesList = createSelector(
    selectFeature,
    (state: FeatureState) => state.contentBoxesList,
);
export const selectFeatureContentBox = createSelector(selectFeature, (state: FeatureState) => state.contentBox);

export const selectFeatureGetAllProductLineNames = createSelector(
    selectFeature,
    (state: FeatureState) => state.productLineNames,
);

export const selectFeatureMerchantCreationFailed = createSelector(
    selectFeature,
    (state: FeatureState) => state.errorOnMerchantCreationFailed,
);
export const selectFeatureSpotLight = (spotlightId: string) =>
    createSelector(selectFeature, (state: FeatureState) =>
        state.spotLights.find((spotlight) => spotlight.id === spotlightId),
    );

export const selectFeatureSpotLights = createSelector(selectFeature, (state: FeatureState) => state.spotLights);

export const selectFeatureSpotLightSetting = createSelector(
    selectFeature,
    (state: FeatureState) => state.spotLightSetting,
);

export const selectSortingOrderState = createSelector(
    selectFeature,
    (state: FeatureState) => state.sortingOrderSuccess,
);

export const selectCopiedSpotlight = createSelector(selectFeature, (state: FeatureState) => state.copiedSpotlight);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
    fraudLimits: new Array<FraudLimitsResponse>(),
    addOrUpdateLimitResponse: undefined,
    login: undefined,
    signup: undefined,
    allProductsAllLanguages: undefined,
    whiteList: undefined,
    synchronize: undefined,
    products: { products: new Array<string>() },
    unBlockState: { message: '' },
    report: {} as reportResponse,
    localizations: new Array<AllTranslationsDetails>(),
    productContent: {} as ManageProductsResponse,
    adminUsers: new AdminUsersResponse(),
    whitelistedUsers: new WhitelistedUsersResponseModel(),
    searchUserResult: new UserSearchResponseModel(),
    addOrRemoveUserFromWhitelistResponse: undefined,
    auditLogsResponse: new AuditLogsResponse(),
    userRoles: undefined,
    error: undefined,
    errorFraudDefaultLimit: undefined,
    errorFraudIssuerLimit: undefined,
    merchant: undefined,
    merchants: undefined,
    digitalVoucherConfigurator: undefined,
    errorOnMerchantCreationFailed: undefined,
    newsticker: undefined,
    newstickers: undefined,
    sortingOrderSuccess: undefined,
    categories: undefined,
    contentPage: undefined,
    contentPagesList: [],
    spotLights: [],
    spotLightSetting: undefined,
    copiedSpotlight: undefined,
    contentBox: undefined,
    contentBoxesList: [],
    productLineNames: [],
};

const adminReducer = createReducer(
    initialState,
    on(fromAdminManageProductCategoryActions.getAllProductCategory, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductCategoryActions.onGetAllProductCategorySuccessfully, (state, action) => ({
        ...state,
        categories: action.payload,
        error: undefined,
    })),
    on(fromAdminManageProductCategoryActions.onGetAllProductCategoryFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageProductCategoryActions.createCategory, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductCategoryActions.onCreateCategorySuccessfully, (state, action) => ({
        ...state,
        categories: [...state.categories, action.payload],
        error: undefined,
    })),
    on(fromAdminManageProductCategoryActions.onCreateCategoryFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageProductCategoryActions.deleteCategory, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductCategoryActions.onDeleteCategorySuccessfully, (state, action) => {
        const updatedCategories = state.categories.filter((category) => category._id !== action.payload._id);
        return { ...state, categories: updatedCategories, error: undefined };
    }),
    on(fromAdminManageProductCategoryActions.onDeleteCategoryFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageProductCategoryActions.updateCategory, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductCategoryActions.onUpdateCategorySuccessfully, (state, action) => {
        debug(state.categories, action.payload);
        const updatedCategories = state.categories.map((category) => {
            if (category._id === action.payload._id) {
                // Replace the matching category with the updated one
                return action.payload;
            }
            return category; // Return unchanged category for other elements
        });

        return { ...state, categories: updatedCategories, error: undefined };
    }),
    on(fromAdminManageProductCategoryActions.onUpdateCategoryFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageProductContentActions.getAllProductContent, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.onGetAllProductContentSuccessfully, (state, action) => ({
        ...state,
        productContent: { products: action.payload, action: 'GET' } as unknown as ManageProductsResponse,
        error: undefined,
    })),
    on(fromAdminManageProductContentActions.updateContentByProductLine, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.updateProductCategories, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.onUpdateProductCategoriesSuccessfully, (state, action) => {
        const currentProducts = [...state.productContent.products];
        const update: ProductCategoriesUpdateResponse = action.payload;
        debug('onUpdateProductCategoriesSuccessfully', update);
        debug('onUpdateProductCategoriesSuccessfully', currentProducts);
        currentProducts.forEach((product: ProductLineForManagementDto, index: number) => {
            const updatedProduct = { ...product };
            if (updatedProduct.productLineName === update.productLineName) {
                updatedProduct.categories = update.categories;
                currentProducts[index] = { ...updatedProduct };
            }
        });
        const updatedProductContent = { ...state.productContent };
        updatedProductContent.products = currentProducts;
        return { ...state, productContent: updatedProductContent, error: undefined };
    }),
    on(fromAdminManageProductContentActions.onUpdateProductCategoriesFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageProductContentActions.onUpdateContentByProductLineSuccessfully, (state, action) => {
        const currentProducts = [...state.productContent.products];
        const update: ProductContentUpdateResponse = action.payload;
        currentProducts.forEach((product: ProductLineForManagementDto, index: number) => {
            const updatedProduct = { ...product };
            if (updatedProduct.productLineName === update.productLineName) {
                const updatedContentArray = [...updatedProduct.content];
                updatedContentArray.forEach((content: ProductContentDto, index: number) => {
                    if (content.locale === update.locale) {
                        const updatedContent = { ...content };
                        updatedContent.twintDescription = update.description;
                        updatedContentArray[index] = updatedContent;
                    }
                });
                updatedProduct.content = [...updatedContentArray];
                currentProducts[index] = { ...updatedProduct };
            }
        });
        const updatedProductContent = { ...state.productContent };
        updatedProductContent.products = currentProducts;
        return { ...state, productContent: updatedProductContent, error: undefined };
    }),
    //onUpdateSettingsByProductLine
    on(fromAdminManageProductContentActions.onUpdateSettingsByProductLine, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageProductContentActions.onUpdateSettingsByProductLineSuccessfully, (state, action) => {
        const currentProducts = [...state.productContent.products];
        const update: ProductSettingsUpdateResponse = action.payload;

        currentProducts.forEach((product: ProductLineForManagementDto, index) => {
            if (product.productLineName === update.productLineName) {
                const updatedProduct = { ...product };
                updatedProduct.couponsEnabled = update.couponsEnabled;
                updatedProduct.displayOnAndroid = update.displayOnAndroid;
                updatedProduct.displayOnIOS = update.displayOnIOS;
                currentProducts[index] = updatedProduct;
            }
        });
        return {
            ...state,
            productContent: {
                products: currentProducts,
                action: 'UPDATE_SETTINGS',
            } as unknown as ManageProductsResponse,
            error: undefined,
        };
    }),

    on(fromAdminManageProductContentActions.onGetAllProductContentFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageProductContentActions.onUpdateContentByProductLineFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageProductContentActions.onUpdateSettingsByProductLineFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageContentActions.getAllLocalizations, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onGetAllLocalizationsSuccessfully, (state, action) => ({
        ...state,
        localizations: action.payload,
        error: undefined,
    })),
    on(fromAdminManageContentActions.onGetAllLocalizationFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageContentActions.updateLocalizationByKey, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageContentActions.onUpdateLocalizationByKeySuccessfully, (state, action) => ({
        ...state,
        localizations: [
            ...state.localizations.map((translation: AllTranslationsDetails) => {
                translation.key === action.payload.key ? (translation.text = action.payload.text) : translation;
                return translation;
            }),
        ],
        error: undefined,
    })),

    on(fromAdminManageContentActions.onUpdateLocalizationByKeyFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromActionsAdminFraudLimits.getFraudLimits, (state) => ({
        ...state,
        errorFraudDefaultLimit: undefined,
        errorFraudIssuerLimit: undefined,
        addOrUpdateLimitResponse: undefined,
    })),
    on(fromActionsAdminFraudLimits.onGetFraudLimitsSuccessfully, (state, action) => ({
        ...state,
        fraudLimits: action.payload,
        errorFraudDefaultLimit: undefined,
        errorFraudIssuerLimit: undefined,
    })),
    on(fromActionsAdminFraudLimits.onGetFraudLimitsFailed, (state, action) => ({
        ...state,
        fraudLimits: [],
        errorFraudDefaultLimit: action.payload,
        errorFraudIssuerLimit: action.payload,
    })),

    on(fromActionsAdminFraudLimits.updateDefaultFraudLimit, (state) => ({
        ...state,
        errorFraudDefaultLimit: undefined,
    })),
    on(fromActionsAdminFraudLimits.onUpdateDefaultFraudLimitSuccessfully, (state, action) => ({
        ...state,
        // tslint:disable-next-line: max-line-length
        fraudLimits: [
            ...state.fraudLimits.map((defaultFraudLimit) =>
                defaultFraudLimit.name === action.payload.name ? action.payload : defaultFraudLimit,
            ),
        ],
        errorFraudDefaultLimit: undefined,
        addOrUpdateLimitResponse: action.payload,
    })),
    on(fromActionsAdminFraudLimits.onUpdateDefaultFraudLimitFailed, (state, action) => ({
        ...state,
        errorFraudDefaultLimit: action.payload,
    })),

    on(fromActionsAdminFraudLimits.addIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onAddIssuerSuccessfully, (state, action) => ({
        ...state,
        fraudLimits: [...state.fraudLimits, action.payload],
        addOrUpdateLimitResponse: action.payload,
        errorFraudIssuerLimit: undefined,
    })),
    on(fromActionsAdminFraudLimits.onAddIssuerFailed, (state, action) => ({
        ...state,
        errorFraudIssuerLimit: action.payload,
    })),

    on(fromActionsAdminFraudLimits.updateIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onUpdateIssuerSuccessfully, (state, action) => ({
        ...state,
        fraudLimits: [
            ...state.fraudLimits.map((fraudIssuer) =>
                fraudIssuer.name === action.payload.name ? action.payload : fraudIssuer,
            ),
        ],
        addOrUpdateLimitResponse: action.payload,
        errorFraudIssuerLimit: undefined,
    })),
    on(fromActionsAdminFraudLimits.onUpdateIssuerFailed, (state, action) => ({
        ...state,
        errorFraudIssuerLimit: action.payload,
    })),

    on(fromActionsAdminFraudLimits.deleteIssuer, (state) => ({ ...state, errorFraudIssuerLimit: undefined })),
    on(fromActionsAdminFraudLimits.onDeleteIssuersuccessfully, (state, action) => ({
        ...state,
        fraudLimits: [...state.fraudLimits.filter((element) => element.name !== action.payload.issuerName)],
        addOrUpdateLimitResponse: action.payload,
        errorFraudIssuerLimit: undefined,
    })),
    on(fromActionsAdminFraudLimits.onDeleteIssuerFailed, (state, action) => ({
        ...state,
        errorFraudIssuerLimit: action.payload,
    })),

    on(AdminFromActions.login, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onLoginSuccessfully, (state, action) => ({
        ...state,
        login: action.payload,
        error: undefined,
    })),
    on(AdminFromActions.onLoginFailed, (state, action) => ({ ...state, login: undefined, error: action.payload })),
    on(AdminFromActions.getAdminUsers, (state) => ({ ...state, error: undefined })),

    on(AdminFromActions.onGetGetAdminUsersSuccessfully, (state, action) => ({
        ...state,
        adminUsers: action.payload,
        error: undefined,
    })),
    on(AdminFromActions.onGetAdminUsersFailed, (state, action) => ({
        ...state,
        adminUsers: new AdminUsersResponse(),
        error: action.payload,
    })),

    on(AdminFromActions.updateUserById, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onUpdateUserByIdSuccessfully, (state, action) => {
        const currentUsers = state.adminUsers;
        const update: IAdminUserModel = action.payload;
        currentUsers.forEach((user: IAdminUserModel) => {
            if (user.id === update.id) {
                user.role = update.role;
            }
        });
        return { ...state, adminUsers: currentUsers, error: undefined };
    }),
    on(AdminFromActions.onUpdateUserByIdFailed, (state, action) => ({
        ...state,
        adminUsers: new AdminUsersResponse(),
        error: action.payload,
    })),

    on(AdminFromActions.deleteUserById, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onDeleteUserByIdSuccessfully, (state, action) => {
        const currentUsers = state.adminUsers;
        const update: IAdminUserModel = action.payload;
        const updatedUsers = currentUsers.filter((user) => {
            return user.id !== update.id;
        });
        return { ...state, adminUsers: updatedUsers, error: undefined };
    }),
    on(AdminFromActions.onDeleteUserByIdFailed, (state, action) => ({
        ...state,
        adminUsers: new AdminUsersResponse(),
        error: action.payload,
    })),

    on(AdminFromActions.changePassword, (state) => state),
    on(AdminFromActions.onChangePasswordSuccessfully, (state, action) => ({
        ...state,
        login: action.payload,
        error: undefined,
    })),
    on(AdminFromActions.onChangePasswordFailed, (state, action) => ({
        ...state,
        login: undefined,
        error: action.payload,
    })),

    on(AdminFromActions.signup, (state) => ({ ...state })),
    on(AdminFromActions.onSignupSauccessfully, (state, action) => ({
        ...state,
        signup: action.payload,
        error: undefined,
    })),
    on(AdminFromActions.onSignupFailed, (state, action) => ({ ...state, signup: undefined, error: action.payload })),

    on(AdminFromActions.logout, (state) => ({ ...state })),
    on(AdminFromActions.onLogoutSuccessfully, (state, action) => ({
        ...state,
        login: undefined,
        signup: undefined,
        error: undefined,
    })),
    on(AdminFromActions.onLogoutFailed, (state, action) => ({
        ...state,
        login: undefined,
        signup: undefined,
        error: action.payload,
    })),

    on(AdminFromActions.getNewAccessToken, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenSuccessfully, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetNewAccessTokenFailed, (state, action) => ({ ...state, error: action.payload })),

    on(FraudDetectionProductsFromActions.getBlockedProducts, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.getBlockedProductsSuccessfully, (state, action) => ({
        ...state,
        products: action.payload,
        error: undefined,
    })),
    on(FraudDetectionProductsFromActions.getBlockedProductsFailed, (state, action) => ({
        ...state,
        products: { products: new Array<string>() },
        error: action.payload,
    })),

    on(FraudDetectionProductsFromActions.unBlockProduct, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.unBlockProductSuccessfully, (state: FeatureState, action) => {
        const currentBlockedProduct: string[] = [...state.products.products];
        const newBlcokedProducts = currentBlockedProduct.filter(
            (blockedProduct: string) => blockedProduct !== action.payload.productName,
        );

        return {
            ...state,
            unBlockState: action.payload.message,
            products: { products: newBlcokedProducts },
            error: undefined,
        };
    }),
    on(FraudDetectionProductsFromActions.unBlockProductFailed, (state, action) => ({
        ...state,
        unBlockState: { message: '' },
        error: action.payload,
    })),

    on(FraudDetectionProductsFromActions.unblockShop, (state) => ({ ...state, error: undefined })),
    on(FraudDetectionProductsFromActions.onUnblockShopSuccessfully, (state, action) => ({
        ...state,
        products: { products: new Array<string>() },
        unBlockState: action.payload,
        error: undefined,
    })),
    on(FraudDetectionProductsFromActions.onUnblockShopFailed, (state, action) => ({
        ...state,
        unBlockState: { message: '' },
        error: action.payload,
    })),

    on(fromAdminReportActions.getReport, (state) => ({ ...state })),
    on(fromAdminReportActions.onGetReportSuccessfully, (state, action) => ({
        ...state,
        report: action.payload,
        error: undefined,
    })),
    on(fromAdminReportActions.onGetReportFailed, (state, action) => ({
        ...state,
        report: {} as reportResponse,
        error: action.payload,
    })),

    on(AdminWhiteListProducts.getAllProductsInAllLanguages, (state) => ({
        ...state,
        allProductsAllLanguages: [],
        error: null,
    })),
    on(AdminWhiteListProducts.getAllProductsInAllLanguagesSuccessfully, (state, action) => ({
        ...state,
        allProductsAllLanguages: action.payload,
    })),
    on(AdminWhiteListProducts.getAllProductsInAllLanguagesFailed, (state, action) => ({
        ...state,
        error: { ...action.payload, source: action.source },
    })),

    on(AdminWhiteListProducts.whiteListProduct, (state) => ({ ...state, error: null })),
    on(AdminWhiteListProducts.whiteListProductSuccessfully, (state, action) => {
        const productLineName = action.productLineName;
        const products = state.allProductsAllLanguages.map((product) => {
            if (product.productLineName === productLineName) {
                product = { ...product, whiteListed: true };
            }
            return product;
        });

        return { ...state, allProductsAllLanguages: products, whiteList: action.payload };
    }),
    on(AdminWhiteListProducts.whiteListProductFailed, (state, action) => ({
        ...state,
        error: { ...action.payload, source: action.source },
    })),

    on(AdminWhiteListProducts.unWhiteListProduct, (state) => ({ ...state, error: null })),
    on(AdminWhiteListProducts.unWhiteListProductSuccessfully, (state, action) => {
        const productLineName = action.productLineName;
        const products = state.allProductsAllLanguages.map((product) => {
            if (product.productLineName === productLineName) {
                product = { ...product, whiteListed: false };
            }
            return product;
        });

        return { ...state, allProductsAllLanguages: products, whiteList: action.payload };
    }),
    on(AdminWhiteListProducts.unWhiteListProductFailed, (state, action) => ({
        ...state,
        error: { ...action.payload, source: action.source },
    })),

    on(AdminWhiteListProducts.synchronizeProducts, (state) => ({ ...state, synchronize: null, error: null })),
    on(AdminWhiteListProducts.synchronizeProductsSuccessfully, (state, action) => ({
        ...state,
        synchronize: action.payload,
    })),
    on(AdminWhiteListProducts.synchronizeProductsFailed, (state, action) => ({
        ...state,
        error: { ...action.payload, source: action.source },
    })),

    on(fromWhitelistUsersActions.getWhitelistedUsers, (state) => ({ ...state, error: undefined })),

    on(fromWhitelistUsersActions.onGetWhitelistedUsersSuccessfully, (state, action) => ({
        ...state,
        whitelistedUsers: action.payload,
        error: undefined,
    })),
    on(fromWhitelistUsersActions.onGetWhitelistedUsersFailed, (state, action) => ({
        ...state,
        whitelistedUsers: new WhitelistedUsersResponseModel(),
        error: action.payload,
    })),

    on(fromWhitelistUsersActions.searchUser, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onSearchUserSuccessfully, (state, action) => ({
        ...state,
        searchUserResult: action.payload,
        error: undefined,
    })),
    on(fromWhitelistUsersActions.onSearchUserFailed, (state, action) => ({
        ...state,
        searchUserResult: new UserSearchResponseModel(),
        error: action.payload,
    })),

    on(fromWhitelistUsersActions.addUserToWhitelist, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onAddUserToWhitelistSuccessfully, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: action.payload,
        error: undefined,
    })),
    on(fromWhitelistUsersActions.onAddUserToWhitelistFailed, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined,
        error: action.payload,
    })),

    on(fromWhitelistUsersActions.removeUserFromWhitelist, (state) => ({ ...state, error: undefined })),
    on(fromWhitelistUsersActions.onRemoveUserFromWhitelistSuccessfully, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: action.payload,
        error: undefined,
    })),
    on(fromWhitelistUsersActions.onRemoveUserFromWhitelistFailed, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined,
        error: action.payload,
    })),
    on(fromWhitelistUsersActions.cleanAddOrDeleteToUserWhitelistResponse, (state, action) => ({
        ...state,
        addOrRemoveUserFromWhitelistResponse: undefined,
        error: undefined,
    })),

    on(fromActionsAdminAuditLogs.getAuditLogs, (state) => ({ ...state, error: undefined })),
    on(fromActionsAdminAuditLogs.onGetAuditLogsSuccessfully, (state, action) => ({
        ...state,
        auditLogsResponse: action.payload,
        error: undefined,
    })),
    on(fromActionsAdminAuditLogs.onGetAuditLogsFailed, (state, action) => ({ ...state, error: action.payload })),
    on(AdminFromActions.getUserRoles, (state) => ({ ...state, error: undefined })),
    on(AdminFromActions.onGetUserRolesSuccessfully, (state, action) => ({
        ...state,
        userRoles: { roles: action.payload } as unknown as UserRolesResponse,
        error: undefined,
    })),
    on(AdminFromActions.onGetUserRolesFailed, (state, action) => ({
        ...state,
        userRoles: undefined,
        error: action.payload,
    })),

    // Merchants

    on(fromActionsAdminMerchant.getAllMerchants, (state) => ({ ...state })),
    on(fromActionsAdminMerchant.onGetAllMerchantsSucceeded, (state, action) => ({
        ...state,
        merchants: action.payload,
    })),
    on(fromActionsAdminMerchant.onGetAllMerchantsFailed, (state, action) => {
        return { ...state, merchants: undefined, error: action.payload };
    }),

    on(fromActionsAdminMerchant.createOrUpdateMerchant, (state) => ({
        ...state,
        errorOnMerchantCreationFailed: undefined,
    })),
    on(fromActionsAdminMerchant.onCreateOrUpdateMerchantSuccessfully, (state, action) => ({
        ...state,
        merchant: action.payload,
    })),
    on(fromActionsAdminMerchant.onCreateOrUpdateMerchantFailed, (state, action) => ({
        ...state,
        merchant: undefined,
        errorOnMerchantCreationFailed: action.payload,
    })),

    // Digital Voucher
    on(fromActionsAdminDigitalVoucher.onCreateOrUpdateDigitalVoucher, (state) => ({ ...state })),
    on(fromActionsAdminDigitalVoucher.onCreateOrUpdateDigitalVoucherSuccessful, (state, action) => ({
        ...state,
        digitalVoucherConfigurator: action.payload,
    })),
    on(fromActionsAdminDigitalVoucher.onCreateOrUpdateDigitalVoucherFailed, (state) => ({ ...state })),
    on(fromActionsAdminDigitalVoucher.onGetDigitalVoucherByProductLine, (state) => ({
        ...state,
        digitalVoucherConfigurator: undefined,
    })),
    on(fromActionsAdminDigitalVoucher.onGetDigitalVoucherByProductLineSuccessfull, (state, action) => ({
        ...state,
        digitalVoucherConfigurator: action.payload,
    })),
    on(fromActionsAdminDigitalVoucher.onGetDigitalVoucherByProductLineFailed, (state, action) => ({
        ...state,
        errorDigitalVoucherConfig: action.payload,
    })),

    //NewsTicker

    on(fromActionsAdminNewsTicker.getAllNewstickers, (state) => ({ ...state })),
    on(fromActionsAdminNewsTicker.onGetAllNewstickersSucceeded, (state, action) => ({
        ...state,
        newstickers: action.payload,
    })),
    on(fromActionsAdminNewsTicker.onGetAllNewstickersFailed, (state, action) => {
        return { ...state, newstickers: undefined, error: action.payload };
    }),

    on(fromActionsAdminNewsTicker.addNewstickers, (state) => ({ ...state })),
    on(fromActionsAdminNewsTicker.onAddNewstickerSucceeded, (state, action) => ({
        ...state,
        newsticker: action.payload,
    })),
    on(fromActionsAdminNewsTicker.onAddNewstickerFailed, (state, action) => ({
        ...state,
        newsticker: undefined,
        error: action.payload,
    })),

    on(fromActionsAdminNewsTicker.updateNewsticker, (state) => ({ ...state })),
    on(fromActionsAdminNewsTicker.onUpdateNewstickerSucceeded, (state, action) => {
        const newsTickers = state.newstickers.map((st) => {
            if (st._id === action.payload._id) {
                return action.payload;
            } else {
                return st;
            }
        });
        return { ...state, newstickers: newsTickers };
    }),
    on(fromActionsAdminNewsTicker.onUpdateNewstickerFailed, (state, action) => ({
        ...state,
        newsticker: undefined,
        error: action.payload,
    })),

    // sorting order
    on(fromActionsAdminSortingOrder.onCreatOrUpdateSortingOrder, (state) => ({
        ...state,
        sortingOrderSuccess: false,
        error: null,
    })),
    on(fromActionsAdminSortingOrder.onCreatOrUpdateSortingOrderSuccessfully, (state, action) => ({
        ...state,
        sortingOrderSuccess: true,
    })),
    on(fromActionsAdminSortingOrder.onCreatOrUpdateSortingOrderFailed, (state, action) => ({
        ...state,
        error: { ...action.payload, source: action.source },
    })),

    // Content Pages

    on(fromActionsContentPage.getAllContentPages, (state) => ({ ...state })),
    on(fromActionsContentPage.getAllContentPagesSuccess, (state, action) => ({
        ...state,
        contentPagesList: action.payload,
    })),
    on(fromActionsContentPage.getAllContentPagesFailure, (state, action) => ({
        ...state,
        contentPagesList: [],
        error: action.payload,
    })),
    on(fromActionsContentPage.addContentPage, (state) => ({ ...state })),
    on(fromActionsContentPage.addContentPageSuccess, (state, action) => ({ ...state, contentPages: action.payload })),
    on(fromActionsContentPage.addContentPageFailure, (state, action) => ({
        ...state,
        contentPages: undefined,
        error: action.payload,
    })),
    on(fromActionsContentPage.updateContentPage, (state) => ({ ...state })),
    on(fromActionsContentPage.updateContentPageSuccess, (state, action) => {
        const updatedContentPagesList = state.contentPagesList.map((content) => {
            if (content._id === action.payload._id) {
                return action.payload;
            } else {
                return content;
            }
        });
        return { ...state, contentPagesList: updatedContentPagesList };
    }),
    on(fromActionsContentPage.updateContentPageFailure, (state, action) => ({
        ...state,
        contentPages: undefined,
        error: action.payload,
    })),

    // Content Boxes

    on(fromActionsContentBox.getAllContentBoxes, (state) => ({ ...state })),
    on(fromActionsContentBox.getAllContentBoxesSuccess, (state, action) => ({
        ...state,
        contentBoxesList: action.payload,
    })),
    on(fromActionsContentBox.getAllContentBoxesFailure, (state, action) => ({
        ...state,
        contentBoxesList: [],
        error: action.payload,
    })),

    on(fromActionsContentBox.getContentBoxById, (state) => ({ ...state })),
    on(fromActionsContentBox.getContentBoxByIdSuccess, (state, action) => ({ ...state, contentBox: action.payload })),
    on(fromActionsContentBox.getContentBoxByIdFailure, (state, action) => ({
        ...state,
        contentBox: undefined,
        error: action.payload,
    })),

    on(fromActionsContentBox.addContentBox, (state) => ({ ...state })),
    on(fromActionsContentBox.addContentBoxSuccess, (state, action) => ({ ...state, contentBoxes: action.payload })),
    on(fromActionsContentBox.addContentBoxFailure, (state, action) => ({
        ...state,
        contentBoxes: undefined,
        error: action.payload,
    })),
    on(fromActionsContentBox.updateContentBox, (state) => ({ ...state })),
    on(fromActionsContentBox.updateContentBoxSuccess, (state, action) => {
        const updatedContentBoxesList = state.contentBoxesList.map((content) => {
            if (content._id === action.payload._id) {
                return action.payload;
            } else {
                return content;
            }
        });
        return { ...state, contentBoxesList: updatedContentBoxesList };
    }),
    on(fromActionsContentBox.updateContentBoxFailure, (state, action) => ({
        ...state,
        contentBoxes: undefined,
        error: action.payload,
    })),
    on(fromActionsContentBox.deleteContentBox, (state) => ({ ...state })),
    on(fromActionsContentBox.deleteContentBoxSuccess, (state, action) => {
        const updatedContentBoxesList = state.contentBoxesList.filter((content) => {
            if (content._id !== action.payload) {
                return content;
            } else {
                return null;
            }
        });
        return { ...state, contentBoxesList: updatedContentBoxesList };
    }),
    on(fromActionsContentBox.deleteContentBoxFailure, (state, action) => ({
        ...state,
        // contentBoxes: undefined,
        error: action.payload,
    })),

    on(AdminWhiteListProducts.getAllProductLineNames, (state) => ({ ...state })),
    on(AdminWhiteListProducts.getAllProductLineNamesSuccessfully, (state, action) => ({
        ...state,
        productLineNames: action.payload,
    })),
    on(AdminWhiteListProducts.getAllProductLineNamesFailed, (state, action) => ({
        ...state,
        productLineNames: undefined,
        error: action.payload,
    })),

    //spotLights
    on(fromAdminManageSpotLightsActions.getAllSpotLights, (state) => ({ ...state, spotLights: [], error: undefined })),
    on(fromAdminManageSpotLightsActions.onGetAllSpotLightsSuccessfully, (state, action) => ({
        ...state,
        spotLights: action.payload,
        error: undefined,
    })),
    on(fromAdminManageSpotLightsActions.onGetAllSpotLightsFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageSpotLightsActions.createSpotLight, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageSpotLightsActions.onCreateSpotLightSuccessfully, (state, action) => ({
        ...state,
        spotLight: action.payload,
        error: undefined,
    })),
    on(fromAdminManageSpotLightsActions.onCreateSpotLightFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageSpotLightsActions.deleteSpotLight, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageSpotLightsActions.onDeleteSpotLightSuccessfully, (state, action) => {
        const updatedSpotlights = [...state.spotLights];
        const updatedIndex = updatedSpotlights.findIndex((spotlight) => spotlight.id === action.payload.id);
        updatedSpotlights.splice(updatedIndex, 1);
        return { ...state, spotLights: [...updatedSpotlights], couponsError: undefined };
    }),
    on(fromAdminManageSpotLightsActions.onDeleteSpotLightFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
    on(fromAdminManageSpotLightsActions.updateSpotLight, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageSpotLightsActions.onUpdateSpotLightSuccessfully, (state, action) => {
        const updatedSpotLightList = state.spotLights.map((spotLight) => {
            if (spotLight.id === action.payload.id) {
                return action.payload;
            } else {
                return spotLight;
            }
        });
        return { ...state, spotLights: updatedSpotLightList };
    }),
    on(fromAdminManageSpotLightsActions.onUpdateSpotLightFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageSpotLightsActions.storeCopiedSpotLight, (state, action) => ({
        ...state,
        copiedSpotlight: action.payload,
        error: undefined,
    })),

    on(fromAdminManageSpotLightsActions.removeCopiedSpotLight, (state) => ({ ...state, copiedSpotlight: undefined })),

    //spotLight Setting
    on(fromAdminManageSpotLightsActions.updateSpotLightSetting, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageSpotLightsActions.onUpdateSpotLightSettingSuccessfully, (state, action) => {
        return { ...state, spotLightSetting: action.payload };
    }),
    on(fromAdminManageSpotLightsActions.onUpdateSpotLightSettingFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),

    on(fromAdminManageSpotLightsActions.getSpotLightSetting, (state) => ({ ...state, error: undefined })),
    on(fromAdminManageSpotLightsActions.onGetSpotLightSettingSuccessfully, (state, action) => {
        return { ...state, spotLightSetting: action.payload?.spotLightFeature, error: undefined };
    }),
    on(fromAdminManageSpotLightsActions.onGetSpotLightSettingFailed, (state, action) => ({
        ...state,
        error: action.payload,
    })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return adminReducer(state, action);
}
