import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdminContentBoxService } from '../services/admin-content-box.service';
import * as fromActions from '../actions/admin-content-box.action';

@Injectable()
export class AdminContentBoxEffect {
    constructor(
        private actions$: Actions,
        private service: AdminContentBoxService,
        private router: Router,
    ) {}

    getAllContentBoxes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllContentBoxes),
            mergeMap(() =>
                this.service.getAllContentBoxes().pipe(
                    map((response) => fromActions.getAllContentBoxesSuccess({ payload: response })),
                    catchError((error) => of(fromActions.getAllContentBoxesFailure({ payload: error }))),
                ),
            ),
        ),
    );

    getContentBoxById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getContentBoxById),
            mergeMap((action) =>
                this.service.getContentBoxById(action.payload).pipe(
                    map((response) => fromActions.getContentBoxByIdSuccess({ payload: response })),
                    catchError((error) => of(fromActions.getContentBoxByIdFailure({ payload: error }))),
                ),
            ),
        ),
    );

    addContentBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addContentBox),
            mergeMap((action) =>
                this.service.addContentBox(action.payload).pipe(
                    map((response) => fromActions.addContentBoxSuccess({ payload: response })),
                    catchError((error) => of(fromActions.addContentBoxFailure({ payload: error }))),
                ),
            ),
        ),
    );

    addContentBoxSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.addContentBoxSuccess),
                tap(() => {
                    this.router.navigate(['/admin/contentboxes']);
                }),
            ),
        { dispatch: false },
    );

    updateContentBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateContentBox),
            mergeMap((action) =>
                this.service.updateContentBox(action.payload).pipe(
                    map((response) => fromActions.updateContentBoxSuccess({ payload: response })),
                    catchError((error) => of(fromActions.updateContentBoxFailure({ payload: error }))),
                ),
            ),
        ),
    );

    updateContentBoxSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.updateContentBoxSuccess),
                tap(() => {
                    this.router.navigate(['/admin/contentboxes']);
                }),
            ),
        { dispatch: false },
    );

    deleteContentBox$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteContentBox),
            mergeMap((action) =>
                this.service.deleteContentBox(action.payload).pipe(
                    map(() => fromActions.deleteContentBoxSuccess({ payload: action.payload })),
                    catchError((error) => of(fromActions.deleteContentBoxFailure({ payload: error }))),
                ),
            ),
        ),
    );

    deleteContentBoxSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.deleteContentBoxSuccess),
                tap(() => {
                    this.router.navigate(['/admin/contentboxes']);
                }),
            ),
        { dispatch: false },
    );
}
