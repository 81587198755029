import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as fromActions from '../actions/admin.fraudDetectionProducts.actions';
import { IUnblockedProduct } from '../models/fraudDetectionProducts/IBlockedProduct';
import { AdminFraudDetectionProductsService } from '../services/admin.fraudDetectionProducts.service';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminFraudDetectionProductsEffects ');

@Injectable()
export class AdminFraudDetectionProductsEffects {
    getBlockedProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getBlockedProducts.type),
            mergeMap(() => {
                return this.service.getAllProducts().pipe(
                    map((response) => {
                        return fromActions.getBlockedProductsSuccessfully({ payload: response });
                    }),
                    catchError((error) => {
                        return of(fromActions.getBlockedProductsFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnGetBlockedProducts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.getBlockedProductsFailed.type),
                tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload)),
            ),
        { dispatch: false },
    );

    unBlockProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.unBlockProduct.type),
            mergeMap((payload: any) => {
                const productName = payload.payload;

                return this.service.unBlockProduct(payload).pipe(
                    map((response) => {
                        const unblockResponse: IUnblockedProduct = { message: response, productName };
                        return fromActions.unBlockProductSuccessfully({ payload: unblockResponse });
                    }),
                    catchError((error) => {
                        return of(fromActions.unBlockProductFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );
    errorOnUnblockProduct$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.unBlockProductFailed.type),
                tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllProductErrors(action.payload)),
            ),
        { dispatch: false },
    );

    unblockShop$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.unblockShop.type),
            mergeMap(() => {
                return this.service.unblockShop().pipe(
                    map((response) => {
                        return fromActions.onUnblockShopSuccessfully({ payload: response });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(fromActions.onUnblockShopFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnUnblockShop$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUnblockShopFailed.type),
                tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllProductErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );
    constructor(
        private actions$: Actions,
        private service: AdminFraudDetectionProductsService,
    ) {}

    public handleOnLoadAllProductErrors(error) {
        debug(error);
        return error;
    }
}
