import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
    FraudRuleBaseDTO,
    FraudRuleTypeEnum,
    RepeatedTransactionsConfig,
    SingleTransactionConfig,
} from '@modeso/types__dgoods-cart';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { MappedFraudRulesToList } from '../../shared/models/interfaces/FormattedFraudRule.interface';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { PermissionHelper } from '../../shared/util/permission.helper';
import * as FraudRulesActions from '../../state/cart/actions/fraud-rules.actions';
import { selectCartError, selectCartLoadingState } from '../../state/cart/selectors/cart.selectors';
import { selectAllFraudRulesList } from '../../state/cart/selectors/fraud-rules.selectors';
import { FraudRulesConstants } from './constants';

@Component({
    selector: 'app-fraud-rules',
    templateUrl: './fraud-rules.page.html',
    styleUrls: ['./fraud-rules.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FraudRulesPage implements OnInit, OnDestroy {
    @ViewChild('confirmationDialog') confirmationDialog!: TemplateRef<void>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public hasAccess = false;
    public displayedColumnsKeys: string[] = FraudRulesConstants.DISPLAYED_COLUMNS_KEYS;
    public displayedColumns: Record<string, string> = FraudRulesConstants.DISPLAYED_COLUMNS;

    public pageSize = FraudRulesConstants.DEFAULT_PAGE_SIZE;
    public currentPage = FraudRulesConstants.DEFAULT_CURRENT_PAGE;
    public pageSizeOptions = FraudRulesConstants.PAGE_SIZE_OPTIONS;

    public confirmationMessage = '';
    public confirmButtonText = '';
    public errorMessage = '';
    public successMessage = '';

    public dataSource: MatTableDataSource<MappedFraudRulesToList> | null = null;
    public dataSource$: Observable<MatTableDataSource<MappedFraudRulesToList>> | null;

    public fraudRules$: Observable<FraudRuleBaseDTO[]>;
    public loading$: Observable<boolean>;
    public error$: Observable<HttpErrorResponse> | null;

    private actionCallback: () => void = () => {};
    private readonly destroyed$: Subject<boolean> = new Subject();
    private dialogRef: MatDialogRef<unknown> | null = null;

    constructor(
        private readonly localStorageService: LocalStorageService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly store: Store,
    ) {}

    public ngOnInit(): void {
        this.hasAccess = this.checkPermission();
        this.fraudRules$ = this.store.select(selectAllFraudRulesList);
        this.loading$ = this.store.select(selectCartLoadingState);
        this.error$ = this.store.select(selectCartError);
        this.dataSource$ = this.fraudRules$.pipe(
            map((rules) => {
                const formattedRules = rules.map((rule) => this.mapRule(rule));
                if (this.dataSource == null) {
                    this.dataSource = new MatTableDataSource<MappedFraudRulesToList>(formattedRules);
                } else {
                    this.dataSource.data = formattedRules;
                }
                this.dataSource.paginator = this.paginator;
                return this.dataSource;
            }),
            takeUntil(this.destroyed$),
        );
        this.error$
            .pipe(
                filter((error) => error != null),
                takeUntil(this.destroyed$),
            )
            .subscribe((error) => {
                this.handleError(error);
            });
        if (this.hasAccess) {
            this.store.dispatch(FraudRulesActions.loadFraudRules());
        }
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public addNewRule(): void {
        this.router.navigate([FraudRulesConstants.CREATE_RULE_ROUTE]);
    }

    public editRule(rule: FraudRuleBaseDTO): void {
        this.router.navigate([FraudRulesConstants.EDIT_RULE_ROUTE, rule._id]);
    }

    public toggleRuleStatus(rule: FraudRuleBaseDTO): void {
        const newStatus = rule.isEnabled ? FraudRulesConstants.DISABLE_ACTION : FraudRulesConstants.ENABLE_ACTION;

        this.confirmationMessage = FraudRulesConstants.TOGGLE_CONFIRMATION_TEMPLATE.replace('{0}', newStatus);
        this.confirmButtonText = FraudRulesConstants.CONFIRM_BUTTON;

        this.actionCallback = () => {
            if (rule.isEnabled) {
                this.store.dispatch(FraudRulesActions.disableFraudRule({ id: rule._id }));
                this.successMessage = FraudRulesConstants.DISABLE_SUCCESS_TEMPLATE.replace('{0}', rule.name);
            } else {
                this.store.dispatch(FraudRulesActions.enableFraudRule({ id: rule._id }));
                this.successMessage = FraudRulesConstants.ENABLE_SUCCESS_TEMPLATE.replace('{0}', rule.name);
            }
            this.errorMessage = '';
        };

        this.openConfirmationDialog();
    }

    public deleteRule(rule: FraudRuleBaseDTO): void {
        this.confirmationMessage = FraudRulesConstants.DELETE_CONFIRMATION;
        this.confirmButtonText = FraudRulesConstants.DELETE_BUTTON;

        this.actionCallback = () => {
            this.store.dispatch(FraudRulesActions.deleteFraudRule({ id: rule._id }));
            this.successMessage = FraudRulesConstants.DELETE_SUCCESS_TEMPLATE.replace('{0}', rule.name);
            this.errorMessage = '';
        };

        this.openConfirmationDialog();
    }

    public confirmAction(): void {
        this.actionCallback();
        this.closeDialog();
    }

    public cancelAction(): void {
        this.closeDialog();
    }

    private mapRuleTypeToDisplay(ruleType: FraudRuleTypeEnum): string {
        return ruleType === FraudRuleTypeEnum.SINGLE ?
                FraudRulesConstants.SINGLE_TRANSACTION_LABEL
            :   FraudRulesConstants.REPEATED_TRANSACTIONS_LABEL;
    }

    private mapThresholdValueToDisplay(rule: FraudRuleBaseDTO): string {
        if (rule.ruleType === FraudRuleTypeEnum.SINGLE) {
            const config = rule.ruleConfiguration as SingleTransactionConfig;
            const threshold = config.thresholdValue;
            return `${FraudRulesConstants.THRESHOLD_PREFIX}${FraudRulesConstants.CURRENCY_PREFIX}${threshold}`;
        } else {
            const config = rule.ruleConfiguration as RepeatedTransactionsConfig;
            return FraudRulesConstants.REPEATED_TRANSACTION_FORMAT.replace(
                '{0}',
                config.numberOfTransactions.toString(),
            ).replace('{1}', config.timeframeInMinutes.toString());
        }
    }

    private mapRule(rule: FraudRuleBaseDTO): MappedFraudRulesToList {
        return {
            ruleName: rule.name,
            ruleType: this.mapRuleTypeToDisplay(rule.ruleType),
            thresholdValue: this.mapThresholdValueToDisplay(rule),
            status: rule.isEnabled ? FraudRulesConstants.ENABLED_STATUS : FraudRulesConstants.DISABLED_STATUS,
            isEnabled: rule.isEnabled,
            originalRuleObject: rule,
        };
    }

    private openConfirmationDialog(): void {
        this.dialogRef = this.dialog.open(this.confirmationDialog);
    }

    private closeDialog(): void {
        if (this.dialogRef != null) {
            this.dialogRef.close();
            this.dialogRef = null;
        } else {
            this.dialog.closeAll();
        }
    }

    private handleError(error: HttpErrorResponse): void {
        this.successMessage = '';

        if (error.status === 0) {
            this.errorMessage = FraudRulesConstants.NETWORK_ERROR;
        } else {
            this.errorMessage = error.error?.message || FraudRulesConstants.DEFAULT_ERROR;
        }
    }

    private checkPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
