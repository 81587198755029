/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminMerchantProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-merchant.provider';
import { IMerchantResponse } from '@modeso/types__dgoods-products';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-merchant-list',
    templateUrl: './merchant-list.page.html',
    styleUrls: ['./merchant-list.page.scss'],
})
export class MerchantListPage implements OnInit, OnDestroy {
    merchantList: IMerchantResponse[];
    displayedColumns: string[] = ['name', 'merchantId'];
    subscription = new Subscription();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private adminMerchantProvider: AdminMerchantProvider,
    ) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.adminMerchantProvider.getMerchantList().subscribe((merchants) => {
                this.merchantList = merchants;
            }),
        );
    }

    editMerchant(merchant: IMerchantResponse) {
        this.router.navigateByUrl(
            `${this.activatedRoute.snapshot.params.language}/create-merchant/${merchant.externalMerchantId}`,
            { state: { merchant } },
        );
    }

    goToCreateMerchantPage() {
        this.router.navigateByUrl(`${this.activatedRoute.snapshot.params.language}/create-merchant`);
    }
}
