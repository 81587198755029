import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AdminConfigService } from '@modeso/dgoods-lib-admin-fe';
import { ExportOrdersHistory, OrderHistoryResponse, OrdersHistoryRequest } from '@modeso/types__dgoods-cart';
import { Observable } from 'rxjs';
import { constructExportOrderHistoryQueryParams } from '../../../shared/helpers/construct-export-order-history-query-params.helper';
import { constructOrderHistoryQueryParams } from '../../../shared/helpers/construct-order-history-query-params.helper';

@Injectable({ providedIn: 'root' })
export class AdminOrderReportsService {
    private readonly server = '';
    private readonly microserviceName = 'cart';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private readonly http: HttpClient,
        @Inject(AdminConfigService) private readonly config,
    ) {
        this.server = config.apiGateway;
    }

    getOrdersReport(requestParams: OrdersHistoryRequest): Observable<OrderHistoryResponse> {
        const queryParams = constructOrderHistoryQueryParams(requestParams);
        const url = `${this.server}${this.microserviceName}/orders-history?${queryParams.toString()}`;
        return this.http.get<OrderHistoryResponse>(url);
    }

    exportOrdersReport(requestParams: ExportOrdersHistory): Observable<void> {
        const queryParams = constructExportOrderHistoryQueryParams(requestParams);
        const url = `${this.server}${this.microserviceName}/export-order-history?${queryParams.toString()}`;
        return this.http.get<void>(url);
    }
}
