import { CouponEffectTypeEnum, CouponTypeEnum } from '@modeso/types__twint-lib-coupons';
import { CouponForm } from '../../models/interfaces/coupon-form.interface';

export const couponInitValue: CouponForm = {
    couponType: undefined as unknown as CouponTypeEnum,
    name: '',
    products: [],
    minPurchaseAmount: 50,
    maxApplicationPerUser: 0,
    maxApplicationOverAllUsers: 1,
    startFrom: new Date(),
    endAt: undefined as unknown as Date,
    effectType: undefined as unknown as CouponEffectTypeEnum,
    effectValue: undefined as unknown as number,
    noOfCodes: undefined as unknown as number,
    financingParties: [],
    comment: undefined,
    costSplitType: undefined as unknown as CouponEffectTypeEnum,
    codeName: undefined as unknown as string,
};
