import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import Debug from 'debug';

const debug = Debug('modeso:twint-lib-core-fe:CurrencyNumberPipe');

@Pipe({ name: 'currencyNumber' })
export class CurrencyNumberPipe implements PipeTransform {
    static format(value: number): string {
        try {
            return formatNumber(value, 'de-CH', '1.2-2');
        } catch (error) {
            debug('try to convert a value, which is no number...', value);
            return value.toString();
        }
    }

    transform(value: number): string {
        return CurrencyNumberPipe.format(value);
    }
}
