<div mat-dialog-content>
    <form class="update-localization-by-key-form" [formGroup]="updateContentByProductLineDialogForm">
        <div class="form-group">
            <label>Product</label>
            <div class="input-wrapper">
                <input id="key" class="form-control" type="text" formControlName="key" />
            </div>
        </div>

        <div class="form-group">
            <label>Text</label>
            <div class="input-wrapper">
                <textarea id="defaultText" class="form-control" type="text" formControlName="defaultText"></textarea>
                <angular-editor
                    id="text"
                    class="angular-editor"
                    type="text"
                    formControlName="text"
                    [config]="editorConfig"
                ></angular-editor>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="dialog-buttons">
    <div class="action-button">
        <button mat-button (click)="onNoClick()" class="button">Cancel</button>
        <button
            mat-button
            [mat-dialog-close]="data"
            [disabled]="buttonStatus()"
            cdkFocusInitial
            (click)="save()"
            class="button"
        >
            Confirm
        </button>
    </div>
</div>
