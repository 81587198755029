import { HttpErrorResponse } from '@angular/common/http';
import { FraudRuleBaseDTO, OrderHistoryResponse } from '@modeso/types__dgoods-cart';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer } from '@ngrx/store';
import { fraudRulesReducers } from './fraud-rules.reducer';
import { orderHistoryReducers } from './order-history.reducer';

export const fraudRulesAdapter: EntityAdapter<FraudRuleBaseDTO> = createEntityAdapter<FraudRuleBaseDTO>({
    selectId: (rule: FraudRuleBaseDTO) => rule._id,
    sortComparer: (a: FraudRuleBaseDTO, b: FraudRuleBaseDTO) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export interface CartState {
    ordersHistoryResponse: OrderHistoryResponse | null;
    loading: boolean;
    error: HttpErrorResponse | null;
    fraudRules: EntityState<FraudRuleBaseDTO>;
    success: boolean;
}

export const initialState: CartState = {
    error: null,
    loading: false,
    ordersHistoryResponse: null,
    fraudRules: fraudRulesAdapter.getInitialState(),
    success: false,
};

export const cartReducer = createReducer(initialState, ...orderHistoryReducers, ...fraudRulesReducers);

export const {
    selectIds: selectFraudRuleIds,
    selectEntities: selectFraudRuleEntities,
    selectAll: selectAllFraudRules,
    selectTotal: selectFraudRulesTotal,
} = fraudRulesAdapter.getSelectors();
