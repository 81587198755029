import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { cartReducer } from './reducer/cart.reducer';
import { OrderReportsEffects } from './effects/admin-order-reports.effects';
import { FraudRulesEffects } from './effects/fraud-rules.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('cart', cartReducer),
        EffectsModule.forFeature([OrderReportsEffects, FraudRulesEffects]),
    ],
})
export class CartModule { }
