<ng-template #noPermission>
    <p>Sorry you don't have permission to access this page.</p>
</ng-template>

<div class="main" *ngIf="hasAccess; else noPermission">
    <div *ngIf="errorMessage !== ''" class="message-box error-box">
        <span class="message-text">{{ errorMessage }}</span>
    </div>

    <div *ngIf="successMessage !== ''" class="message-box success-box">
        <span class="message-text">{{ successMessage }}</span>
    </div>

    <h4>Manage Fraud Popups</h4>

    <div class="add-rule-container">
        <button class="add-rule-button" (click)="addNewRule()">Add new rule</button>
    </div>

    <ng-container *ngIf="loading$ | async">
        <div class="spinner">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>

    <div class="table-container" [hidden]="(fraudRules$ | async)?.length <= 0">
        <mat-table [dataSource]="dataSource$ | async" class="mat-table">
            <ng-container matColumnDef="ruleName">
                <mat-header-cell *matHeaderCellDef class="fixed-width-column">{{ displayedColumns['ruleName']
                    }}</mat-header-cell>
                <mat-cell *matCellDef="let rule" class="fixed-width-column">
                    <span>{{ rule.ruleName }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ruleType">
                <mat-header-cell *matHeaderCellDef>{{ displayedColumns['ruleType'] }}</mat-header-cell>
                <mat-cell *matCellDef="let rule">
                    <span>{{ rule.ruleType }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="thresholdValue">
                <mat-header-cell *matHeaderCellDef>{{ displayedColumns['thresholdValue'] }}</mat-header-cell>
                <mat-cell *matCellDef="let rule">
                    <span>{{ rule.thresholdValue }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>{{ displayedColumns['status'] }}</mat-header-cell>
                <mat-cell *matCellDef="let rule">
                    <span>{{ rule.status }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="fixed-width-column"></mat-header-cell>
                <mat-cell *matCellDef="let rule" class="actions fixed-width-column">
                    <button class="states edit-button" (click)="editRule(rule.originalRuleObject)">
                        Edit
                    </button>
                    <button class="states" [ngClass]="rule.isEnabled ? 'disable-button' : 'enable-button'"
                        (click)="toggleRuleStatus(rule.originalRuleObject)">
                        {{ rule.isEnabled ? 'Disable' : 'Enable' }}
                    </button>
                    <button class="states delete-button" (click)="deleteRule(rule.originalRuleObject)">
                        Delete
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsKeys"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsKeys;"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="pageSize" [pageIndex]="currentPage" [pageSizeOptions]="pageSizeOptions"
            showFirstLastButtons>
        </mat-paginator>
    </div>

    <div class="no-data" *ngIf="(fraudRules$ | async)?.length === 0">
        <p>
            No fraud rules have been created yet.
        </p>
    </div>
</div>

<ng-template #confirmationDialog>
    <div class="confirmation-dialog">
        <div class="dialog-content">
            <div class="warning-icon">
                <mat-icon>warning</mat-icon>
            </div>
            <p>{{ confirmationMessage }}</p>
        </div>
        <div class="dialog-actions">
            <button mat-button class="cancel-button" (click)="cancelAction()">Cancel</button>
            <button mat-button class="confirm-button" (click)="confirmAction()">{{ confirmButtonText }}</button>
        </div>
    </div>
</ng-template>