import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    CouponFilterDTO,
    CouponListingDTO,
    CreateCouponDTO,
    DetailedCouponResponseDTO,
} from '@modeso/types__twint-lib-coupons';
import { TwintCouponConfigService } from '../twint.coupons.config';
import { IFinancingParty } from '../models/interfaces/finance';
import { ExportCostFileType } from '../models/enums/export-cost-file-type.enum';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';
import { InvalidateCodeRequest } from '../models/interfaces/invalidate-code-request.interface';
import { FindCouponByIdRequest } from '../models/interfaces/find-coupon-by-id-request.interface';
import { InvalidateCodesRequest } from '../models/interfaces/invalidate-codes-request.interface';
import { UpdateCouponPayload } from '../models/interfaces/update-coupon-request.interface';
import { ArchiveCouponRequest } from '../models/interfaces/archive-coupon-request.interface';

@Injectable({ providedIn: 'root' })
export class TwintCouponsService {
    private server = '';

    private microserviceName = 'cart';
    private readonly addCoupon = '/coupons';
    private readonly editCoupon = '/coupons/';
    private readonly couponFeature = '/coupons/state';
    private readonly activateCoupon = '/activate';
    private readonly deactivateCoupon = '/deactivate';
    private readonly invalidateCode = '/codes/';
    private readonly invalidateCodes = '/codes';
    private readonly archiveCoupon = '/archive';
    private readonly fetchCouponDetailsCoupon = '/details';
    private readonly controlCouponState = '/control';
    private readonly exportCostFile = '/cost-full-report';
    private readonly exportCostFileSummary = '/cost-summary-report';
    private readonly financingParties = '/financing-parties';
    private readonly totalCouponDiscount = '/coupon-total-discount';

    constructor(
        private http: HttpClient,
        @Inject(TwintCouponConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    public createCoupon(coupon: CreateCouponDTO): Observable<string> {
        const url = `${this.server}${this.microserviceName}${this.addCoupon}`;
        return this.http.post(url, coupon, { responseType: 'text' });
    }

    public updateCoupon(payload: UpdateCouponPayload): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${payload.couponId}`;
        return this.http.put<void>(url, payload.body);
    }

    public getCoupons(data?: CouponFilterDTO): Observable<CouponListingDTO[]> {
        const queryParams = this.buildQueryParams(data);
        const url = `${this.server}${this.microserviceName}${this.addCoupon}?${queryParams}`;
        return this.http.get<CouponListingDTO[]>(url);
    }

    public fetchCouponDetails(couponRequest: FindCouponByIdRequest): Observable<DetailedCouponResponseDTO> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponRequest.couponId}${this.fetchCouponDetailsCoupon}?skip=${couponRequest.skip}&limit=${couponRequest.limit}`;
        return this.http.get<DetailedCouponResponseDTO>(url);
    }

    public toggleCouponsFeature(couponFeature: boolean) {
        const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
        return this.http.post<boolean>(url, { state: couponFeature ? 'active' : 'inactive' });
    }
    public checkCouponsFeature() {
        const url = `${this.server}${this.microserviceName}${this.couponFeature}`;
        const res = this.http.get<boolean>(url);
        return res;
    }

    public activateCouponState(couponId: string): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.activateCoupon}`;
        return this.http.post<void>(url, {});
    }

    public deactivateCouponState(couponId: string): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.deactivateCoupon}`;
        return this.http.post<void>(url, {});
    }

    public deleteCoupon(couponId: string): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}`;
        return this.http.delete<void>(url);
    }

    public invalidateCodeState(invalidateCodeRequest: InvalidateCodeRequest): Observable<DetailedCouponResponseDTO> {
        const { couponId, codeId, skip, limit } = invalidateCodeRequest;
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.invalidateCode}${codeId}?skip=${skip}&limit=${limit}`;
        return this.http.put<DetailedCouponResponseDTO>(url, {});
    }

    public invalidateCodesState(invalidateCouponCodes: InvalidateCodesRequest): Observable<DetailedCouponResponseDTO> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${invalidateCouponCodes.couponId}${this.invalidateCodes}?skip=${invalidateCouponCodes.skip}&limit=${invalidateCouponCodes.limit}`;
        return this.http.put<DetailedCouponResponseDTO>(url, {});
    }

    public archiveCouponState(archiveCoupon: ArchiveCouponRequest): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${archiveCoupon.couponId}${this.archiveCoupon}`;
        return this.http.post<void>(url, { archived: archiveCoupon.archived });
    }

    public controlCoupon(couponId: string): Observable<void> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.controlCouponState}`;
        return this.http.patch<void>(url, null);
    }

    public getFinancingParties(): Observable<IFinancingParty[]> {
        const url = `${this.server}${this.microserviceName}${this.addCoupon}${this.financingParties}`;
        return this.http.get<IFinancingParty[]>(url);
    }
    public addFinanceParty(name: string): Observable<IFinancingParty> {
        const url = `${this.server}${this.microserviceName}${this.addCoupon}${this.financingParties}`;
        return this.http.post<IFinancingParty>(url, { name });
    }

    public exportCost(data: ExportCostFileRequest): Observable<Blob> {
        let url;
        const { type, ...requestBody } = data;

        if (data.type === ExportCostFileType.FULL) {
            url = `${this.server}${this.microserviceName}${this.addCoupon}${this.exportCostFile}`;
        } else {
            url = `${this.server}${this.microserviceName}${this.addCoupon}${this.exportCostFileSummary}`;
        }

        return this.http.post(url, requestBody, { responseType: 'blob' });
    }

    public couponTotalDiscount(couponId: string): Observable<number> {
        const url = `${this.server}${this.microserviceName}${this.editCoupon}${couponId}${this.totalCouponDiscount}`;
        return this.http.get<number>(url);
    }

    private buildQueryParams(params?: CouponFilterDTO): string {
        const queryParams = new URLSearchParams();

        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key] != null && params[key] !== '') {
                queryParams.append(key, params[key]);
            }
        }

        return queryParams.toString();
    }
}
