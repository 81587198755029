import { Component, Injector, ViewChild, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { BasePageComponent } from '../../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AdminWhiteListingUsersProvider,
    UserResponseModel,
    UserSearchResponseModel,
} from '@modeso/dgoods-lib-admin-fe';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

const debug = Debug('modeso:dgoods-admin:UserSearchFormComponent');

@Component({
    selector: 'app-page-user-search-form',
    templateUrl: './userSearchForm.page.html',
    styleUrls: ['./userSearchForm.page.scss'],
})
export class UserSearchFormComponent extends BasePageComponent implements OnInit {
    users: UserSearchResponseModel = new UserSearchResponseModel();
    dataSource: MatTableDataSource<UserResponseModel>;
    selection = new SelectionModel<any>();
    searchValue = '';
    isError = false;
    isServerError = false;
    needsUpdates = false;

    manageColumns: string[] = ['select', 'CustomerUuid', 'Email', 'Issuer'];
    updatedNotificationMessage = 'User is updated successfully';
    serverErrorMessage = 'Server Error';
    errorMessage: string;
    filterValue = '';
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    @Input() selectedAction;
    @Input() selectedActionCaption: string;

    errorhandler = (errorResponse: HttpErrorResponse) => {
        if (errorResponse === undefined) {
            return;
        }
        if (errorResponse.status === 404) {
            this.isServerError = false;
            this.isError = true;
            this.errorMessage = errorResponse.error.message;
        } else {
            this.isError = false;
            this.isServerError = true;
        }
    };

    constructor(
        injector: Injector,
        private provider: AdminWhiteListingUsersProvider,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef,
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSearch();
        this.initErrors();
    }

    initSearch() {
        this.subscriptions.push(
            this.provider
                .getUserSearchResults$()
                .pipe()
                .subscribe((response: UserSearchResponseModel) => {
                    this.isError = false;
                    this.isServerError = false;

                    this.users = response;

                    this.dataSource = new MatTableDataSource<UserResponseModel>(this.users);
                    this.changeDetector.detectChanges();
                    this.dataSource.paginator = this.paginator;

                    debug(response);
                    if (!this.needsUpdates) {
                        return;
                    }
                    if (response.length === 0) {
                        debug('No users found');
                        this._snackBar.open('No users found', null, { duration: 2000, panelClass: ['blue-snackbar'] });
                    }
                }),
        );
    }

    initErrors() {
        this.subscriptions.push(this.provider.getError$().subscribe(this.errorhandler));
    }

    onSearch() {
        if (this.searchButtonIsDisabled()) {
            return;
        }
        const searchByEmail = this.searchValue;
        this.needsUpdates = true;
        this.provider.searchUsers$({ email: searchByEmail });
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, null, { duration: 2000, panelClass: ['blue-snackbar'] });
    }

    selectedRow() {
        if (!this.selection.selected[0]) {
            return null;
        }
        return this.selection.selected[0];
    }

    updateButtonStatus() {
        return !this.selectedRow();
    }

    searchButtonIsDisabled() {
        return this.searchValue.length <= 3;
    }
}
