import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminNewstickerProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-newsticker.provider';
import { INewstickerResponse, NewstickerTypes, PublishStatus } from '@modeso/types__dgoods-products';
import { BasePageComponent } from '../../base.page';

@Component({
    selector: 'app-newsticker-list',
    templateUrl: './newsticker-list.page.html',
    styleUrls: ['./newsticker-list.page.scss'],
})
export class NewstickerListPage extends BasePageComponent implements OnInit {
    PublishStatus = PublishStatus;
    newstickerTypes = NewstickerTypes;
    newstickerList: INewstickerResponse[];
    displayedColumns: string[] = ['name', 'types', 'text', 'state', 'buttons'];

    isDisabled;
    btn = 'btn';

    constructor(
        private router: Router,
        private adminProductsProvider: AdminNewstickerProvider,
        private injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.adminProductsProvider.getNewstickerList().subscribe((tickers) => {
                this.newstickerList = tickers;
                if (tickers) {
                    tickers.map((elem, index) => {
                        if (elem.status === PublishStatus.PUBLISHED) {
                            this.isDisabled = this.btn + index;
                        }
                    });
                }
            }),
        );
    }

    createNewstickerPage() {
        this.router.navigate([`/de-ch/create-newstickers`]);
    }

    editNewsticker(ticker: INewstickerResponse) {
        this.router.navigate([`/de-ch/create-newstickers/${ticker._id}`]);
    }

    setStatus(ticker: INewstickerResponse, status, btn) {
        ticker = { ...this.newstickerList.find((origTicker) => origTicker._id === ticker._id), status };

        this.adminProductsProvider.updateNewsticker(ticker);
        if (this.isDisabled === btn) {
            // re-enables all the buttons
            this.isDisabled = null;
            return;
        }
        this.isDisabled = btn;
    }
}
