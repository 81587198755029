import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminWhiteListingProductsProvider } from '@modeso/dgoods-lib-admin-fe';
import { AdminMerchantProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-merchant.provider';
import {
    ExportOrdersHistory,
    OrderHistoryDTO,
    OrderHistoryResponse,
    OrdersHistoryRequest,
    OrderStatus,
} from '@modeso/types__dgoods-cart';
import { IMerchantResponse } from '@modeso/types__dgoods-products';
import { Store } from '@ngrx/store';
import equal from 'fast-deep-equal';
import moment from 'moment';
import { distinctUntilChanged, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { orderHistoryDisplayedColumns } from '../../shared/constants/orderHistoryDisplayedColumns';
import { orderStatusesMap } from '../../shared/constants/orderStatuses.mapping';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { OrderHistoryStringifiedDTO } from '../../shared/models/orderHistoryStringifiedDTO.interface';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../../shared/util/dateFormats.helper';
import { PermissionHelper } from '../../shared/util/permission.helper';
import * as OrderReportsActions from '../../state/cart/actions/admin-order-history.actions';
import { CartState } from '../../state/cart/reducer/cart.reducer';
import {
    selectCartError,
    selectCartLoadingState,
    selectCartSuccessState,
} from '../../state/cart/selectors/cart.selectors';
import { selectCartOrderHistoryResponse } from '../../state/cart/selectors/order-history.selectors';
import { ErrorDialogComponent } from '../Dialogs/error-dialog.component';

@Component({
    selector: 'app-order-reports',
    templateUrl: './order-reports.page.html',
    styleUrls: ['./order-reports.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ],
})
export class OrderReportsPage implements OnInit, OnDestroy {
    public readonly displayedColumns = orderHistoryDisplayedColumns;
    public readonly displayedColumnsKeys = Object.keys(orderHistoryDisplayedColumns);
    public readonly orderStatusesSelectionValues = Object.values(OrderStatus);
    public readonly orderStatusesSelectionMap: Map<string, string> = orderStatusesMap;
    public readonly pageSizeOptions = [5, 10, 20, 500, 1000];
    // Pagination
    public pageSize = 5;
    public currentPage = 0;
    public isSearchButtonPressed = false;
    private skip = 0;
    private readonly destroyed$: Subject<boolean> = new Subject();

    private readonly MAXIMUM_DAYS_DIFFERENCE = 31;
    private previousDispatchedRequest: OrdersHistoryRequest;

    //Store Observables
    public merchants$: Observable<IMerchantResponse[]>;
    public orderReportResponse$: Observable<OrderHistoryResponse>;
    public dataSource = new MatTableDataSource<OrderHistoryStringifiedDTO>();
    public orderReportOrdersDataSource$: Observable<MatTableDataSource<OrderHistoryStringifiedDTO>>;
    public loading$: Observable<boolean>;
    public success$: Observable<boolean>;
    public error$: Observable<HttpErrorResponse>;
    public allProductLineNames$: Observable<string[]>;

    public filterForm: FormGroup;
    public startDateFormControl: FormControl;
    public endDateFormControl: FormControl;
    constructor(
        private readonly store: Store<{ cart: CartState }>,
        private readonly adminMerchantProvider: AdminMerchantProvider,
        private readonly adminWhiteListingProvider: AdminWhiteListingProductsProvider,
        private readonly localStorageService: LocalStorageService,
        private readonly dialog: MatDialog,
        private readonly fb: FormBuilder,
    ) {}

    public ngOnInit(): void {
        this.store.dispatch(OrderReportsActions.clearOrderHistoryState());
        this.orderReportResponse$ = this.store.select(selectCartOrderHistoryResponse).pipe(
            filter((ordersResponse: OrderHistoryResponse) => ordersResponse != null),
            distinctUntilChanged((prev, curr) => equal(prev, curr)),
        );

        this.orderReportOrdersDataSource$ = this.orderReportResponse$.pipe(
            map((ordersResponse: OrderHistoryResponse) => {
                const formattedOrders = ordersResponse.orders.map((order: OrderHistoryDTO) => this.formatOrder(order));
                if (this.dataSource == null) {
                    this.dataSource = new MatTableDataSource<OrderHistoryStringifiedDTO>();
                }
                this.dataSource.data = formattedOrders;
                this.isSearchButtonPressed = true;
                return this.dataSource;
            }),
        );

        this.merchants$ = this.adminMerchantProvider.getMerchantList();
        this.allProductLineNames$ = this.adminWhiteListingProvider
            .getAllProductLineNames$()
            .pipe(
                map((products: string[]) =>
                    products.map((product: string) => product).sort((a, b) => a.localeCompare(b)),
                ),
            );

        this.loading$ = this.store.select(selectCartLoadingState);
        this.success$ = this.store.select(selectCartSuccessState);
        this.error$ = this.store.select(selectCartError).pipe(
            filter((error: HttpErrorResponse) => error != null),
            takeUntil(this.destroyed$),
        );
        this.error$.subscribe((error: HttpErrorResponse) => {
            let title = 'Error loading orders';
            let message = error.error?.message || 'An unexpected error occurred';
            if (error.status === 0) {
                title = 'Network Error';
                message = 'Please check your internet connection and try again';
            }
            this.dialog.open(ErrorDialogComponent, { data: { title, message }, width: '350px', height: '200px' });
        });

        this.initForm();
    }
    public ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public loadOrders(paginated: boolean = false): void {
        let filterRequest: OrdersHistoryRequest;
        if (paginated) {
            filterRequest = this.previousDispatchedRequest;
            filterRequest.skip = this.skip.toString();
            filterRequest.limit = this.pageSize.toString();
        } else if (this.filterForm.invalid) {
            return;
        } else {
            this.previousDispatchedRequest = filterRequest;
            this.skip = 0;
            this.currentPage = 0;
            const formValue = this.filterForm.value;
            filterRequest = {
                skip: this.skip.toString(),
                limit: this.pageSize.toString(),
                startDate: formValue.startDate.toISOString(),
                endDate: formValue.endDate.toISOString(),
                merchant: formValue.merchant,
                productLine: formValue.productLine,
                orderStatus: formValue.orderStatus?.join(','),
                includeCoupons: formValue.includeCoupons ? 'true' : 'false',
            };
            this.previousDispatchedRequest = filterRequest;
        }
        this.store.dispatch(OrderReportsActions.loadOrders(filterRequest));
    }

    public exportToExcel(): void {
        const formValue = this.filterForm.value;

        const filterRequest: ExportOrdersHistory = {
            startDate: formValue.startDate.toISOString(),
            endDate: formValue.endDate.toISOString(),
            merchant: formValue.merchant,
            productLine: formValue.productLine,
            orderStatus: formValue.orderStatus?.join(','),
            includeCoupons: formValue.includeCoupons ? 'true' : 'false',
        };
        this.store.dispatch(OrderReportsActions.exportOrdersHistory(filterRequest));
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.skip = this.pageSize * event.pageIndex;
        this.currentPage = event.pageIndex;

        this.loadOrders(true);
    }

    public hasPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    private initForm(): void {
        this.filterForm = this.fb.group(
            {
                merchant: [null],
                productLine: [null],
                orderStatus: [null],
                startDate: [moment().startOf('month').toDate(), Validators.required],
                endDate: [moment().toDate(), Validators.required],
                includeCoupons: [false],
            },
            { validators: (group: FormGroup) => this.dateRangeValidator(group) },
        );
        // When either dates is invalid, both should have red border
        this.startDateFormControl = this.filterForm.get('startDate') as FormControl;
        this.endDateFormControl = this.filterForm.get('endDate') as FormControl;
        this.startDateFormControl.markAsTouched();
        this.endDateFormControl.markAsTouched();
        this.startDateFormControl.markAsDirty();
        this.endDateFormControl.markAsDirty();
    }

    private dateRangeValidator(group: FormGroup): { [key: string]: string[] } | null {
        const startDate = group.get('startDate')?.value;
        const endDate = group.get('endDate')?.value;
        const errors: string[] = [];
        const now = new Date();

        if (startDate != null && endDate != null) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end.getTime() - start.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (start > now) {
                group.get('startDate')?.setErrors({ future: true });
                errors.push('Start date cannot be in the future');
            } else if (end > now) {
                group.get('endDate')?.setErrors({ future: true });
                errors.push('End date cannot be in the future');
            } else if (start > end) {
                group.get('startDate')?.setErrors({ invalid: true });
                group.get('endDate')?.setErrors({ invalid: true });
                errors.push('Start date cannot be after end date');
            } else if (diffDays > this.MAXIMUM_DAYS_DIFFERENCE) {
                group.get('startDate')?.setErrors({ invalid: true });
                group.get('endDate')?.setErrors({ invalid: true });
                errors.push(`The difference between dates cannot exceed ${this.MAXIMUM_DAYS_DIFFERENCE} days`);
            }

            if (errors.length === 0) {
                group.get('startDate')?.setErrors(null);
                group.get('endDate')?.setErrors(null);
            }
        }
        return errors.length > 0 ? { dateRange: errors } : null;
    }

    private formatOrder(order: OrderHistoryDTO): OrderHistoryStringifiedDTO {
        let totalPaidAmount;
        if (order.totalPaidAmount != null || order.totalPaidAmount === 0) {
            totalPaidAmount = order.totalPaidAmount.toFixed(2);
        } else {
            totalPaidAmount = order.price.toFixed(2);
        }
        return {
            couponCode: order.couponCode ? order.couponCode : '-',
            couponValue: order.couponValue ? order.couponValue.toFixed(2) : '-',
            merchant: order.merchant,
            orderStatus: this.orderStatusesSelectionMap.get(order.orderStatus),
            price: order.price.toFixed(2),
            productName: order.productName,
            totalPaidAmount,
            createdAt: moment(order.createdAt).format('DD-MM-YYYY HH:mm:ss'),
            email: order.email,
            orderLanguage: order.orderLanguage,
        };
    }
}
