import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'dgoods-tutorial-element',
    templateUrl: './dgoods-tutorial-element.component.html',
    styleUrls: ['./dgoods-tutorial-element.component.scss'],
})
export class DGoodsTutorialElementComponent implements OnInit {
    @Input() tutorial;

    constructor() {}

    ngOnInit() {}
}
