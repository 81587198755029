import { DiscountDTO } from '@modeso/types__twint-lib-coupons';

export interface IDGoodsTableDataSource {
    title: string;
    reference: string;
    date: Date;
    link: string;
    amount: number;
    //optional
    discounts?: DiscountDTO;

    compare(a: IDGoodsTableDataSource, b: IDGoodsTableDataSource): number;
}

export class DGoodsTableDataSource implements IDGoodsTableDataSource {
    constructor(
        public title: string,
        public reference: string,
        public date: Date,
        public link: string,
        public amount: number,
        public discounts?: DiscountDTO,
    ) {}

    public compare(a: IDGoodsTableDataSource, b: IDGoodsTableDataSource) {
        if (a.date < b.date) {
            return -1;
        }
        if (a.date > b.date) {
            return 1;
        }
        return 0;
    }
}

export interface IDGoodsTableSection {
    title: string;
    index: number;
    datasource: IDGoodsTableDataSource[];
}

export class DGoodsTableSection implements IDGoodsTableSection {
    constructor(
        public title: string,
        public index: number,
        public datasource: IDGoodsTableDataSource[],
    ) {}
}

export abstract class MonthAndYear {
    month: string;
    year: string;
}
