import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState } from '../reducer/cart.reducer';

export const selectCartState = createFeatureSelector<CartState>('cart');

export const selectCartLoadingState = createSelector(selectCartState, (state) => state.loading);

export const selectCartError = createSelector(selectCartState, (state) => state.error);

export const selectCartSuccessState = createSelector(selectCartState, (state) => state.success);
