import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: 'confirmDeletion.html',
    styleUrls: ['./confirmDeletion.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmDeletionDialog {
    message;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeletionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.message = data.message;
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
