import { Component, Injector, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AdminFraudLimitsProvider,
    FraudLimitsResponse,
    DefaultFraudLimitsRequest,
    IssuerLimitsOfAddOrUpdateRequest,
    IssuerLimitsOfDeleteRequest,
    DefaultFraudLimit,
    DefaultFraudLimitsDescriptionsHelper,
} from '@modeso/dgoods-lib-admin-fe';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDefaultDialog } from './updateDialog/updateDefaultDialog';
import { AddOrUpdateIssuerDialog } from './addOrUpdateIssuerDialog/addOrUpdateIssuerDialog';
import { PopUpComponent } from '../popUp/popUp';
import Debug from 'debug';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
const debug = Debug('modeso:dgoods-admin:FraudLimitsPage');

enum ACTION {
    ADD_ISSUER,
    UPDATE_DEFAULT,
    UPDATE_ISSUER,
    DELETE_ISSUER,
    UNDEFINED,
}
@Component({
    selector: 'app-page-fraudlimits',
    templateUrl: './fraudLimits.page.html',
    styleUrls: ['./fraudLimits.page.scss'],
})
export class FraudLimitsPage extends BasePageComponent implements OnInit {
    defaultFraudLimits: DefaultFraudLimit[] = [];
    issuerLimits: IssuerLimitsOfAddOrUpdateRequest[] = [];
    updatedDefaultValue: number;
    issuer = {} as IssuerLimitsOfAddOrUpdateRequest;
    action: ACTION = ACTION.UNDEFINED;
    isBadRequestDefaultFraud = false;
    isServerErrorDefaultFraud = false;
    isUnauthorizedDefault = false;

    isServerErrorIssuer = false;
    isTimeframeNotValid = false;
    isBadRequestIssuer = false;
    isUpdateIssuer = false;

    defaultDataSource: MatTableDataSource<DefaultFraudLimit>;
    issuerDataSource: MatTableDataSource<IssuerLimitsOfAddOrUpdateRequest>;

    defaultSelection = new SelectionModel<any>();
    issuerSelection = new SelectionModel<any>();

    defaultFraudLimitsColmuns: string[] = ['select', 'Name', 'Description', 'Value'];
    issuerFraudLimitsColmuns: string[] = ['select', 'Name', 'Value', 'Timeframe Name', 'Timeframe Value'];
    errorMessage: string;

    defaultFraudUpdateNotificationMessage = 'Updates Successfully';
    issuerAddExistNotificationMessage = 'Issuer already exists. Could not save.';
    issuerAddedNotificationMessage = 'Issuer is added successfully';
    issuerUpdatedNotificationMessage = 'Issuer is updated successfully';
    issuerDeletedNotificationMessage = 'Issuer is deleted successfully';

    @ViewChild('defaultFraudPaginator', { static: false }) defaultFraudPaginator: MatPaginator;
    @ViewChild('issuerFraudPaginator', { static: false }) issuerFraudPaginator: MatPaginator;

    constructor(
        injector: Injector,
        private adminFraudLimitProvider: AdminFraudLimitsProvider,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef,
        private defaultLimitsDescriptionsHelper: DefaultFraudLimitsDescriptionsHelper,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.initErrors();
        this.initFraudLimits();
        this.initFraudUpdates();
        this.getFraudLimits();
        super.ngOnInit();
    }

    selectedDefaultFraud() {
        if (!this.defaultSelection.selected[0]) {
            return null;
        }
        return this.defaultSelection.selected[0];
    }

    selectedFraudIssuer() {
        if (!this.issuerSelection.selected[0]) {
            return null;
        }
        return this.issuerSelection.selected[0];
    }

    defaultUpdateButtonStatus() {
        return !this.selectedDefaultFraud();
    }

    issuerButtonStatus() {
        return !this.selectedFraudIssuer();
    }

    initErrors() {
        // Default Limits Errors
        this.subscriptions.push(
            this.adminFraudLimitProvider.getErrorDefaultLimits$().subscribe((errorResponse: any) => {
                debug('getErrorDefaultLimits');
                if (errorResponse) {
                    debug(errorResponse);
                    debug(errorResponse.status);
                    if (errorResponse.status === 500) {
                        debug('is 500');
                        this.errorMessage = errorResponse.statusText;
                    } else {
                        this.errorMessage = errorResponse.error.status + ':' + ' ' + errorResponse.error.error;
                    }
                    this.isServerErrorDefaultFraud = true;
                } else {
                    this.isServerErrorDefaultFraud = false;
                }
            }),
        );

        // Issuer Limis Errors
        this.subscriptions.push(
            this.adminFraudLimitProvider.getErrorIssuerLimits$().subscribe((errorResponse: any) => {
                debug('getErrorIssuerLimits');
                if (errorResponse) {
                    this.isServerErrorIssuer = true;
                    if (errorResponse.status === 500) {
                        debug('is 500');
                        this.errorMessage = errorResponse.statusText;
                    } else {
                        this.errorMessage = errorResponse.error.status + ':' + ' ' + errorResponse.error.error;
                    }
                } else {
                    this.isServerErrorIssuer = true;
                }
            }),
        );
    }

    initFraudLimits() {
        this.subscriptions.push(
            this.adminFraudLimitProvider
                .getFraudLimits$()
                .pipe()
                .subscribe((fraudLimitResponse: FraudLimitsResponse[]) => {
                    this.issuerLimits = [];
                    this.defaultFraudLimits = [];
                    fraudLimitResponse.forEach((element) => {
                        if (element.timeframeName) {
                            this.issuerLimits.push(element as IssuerLimitsOfAddOrUpdateRequest);
                        } else {
                            const defaultFraudLimit: DefaultFraudLimit = {
                                fraudLimit: element.name,
                                fraudLimitDescription:
                                    this.defaultLimitsDescriptionsHelper.getDefaultFraudLimitDescription(element.name),
                                fraudLimitValue: element.value,
                            };
                            this.defaultFraudLimits.push(defaultFraudLimit);
                        }
                    });

                    this.defaultDataSource = new MatTableDataSource<DefaultFraudLimit>(this.defaultFraudLimits);
                    this.issuerDataSource = new MatTableDataSource<IssuerLimitsOfAddOrUpdateRequest>(this.issuerLimits);
                    this.changeDetector.detectChanges();
                    this.defaultDataSource.paginator = this.defaultFraudPaginator;
                    this.issuerDataSource.paginator = this.issuerFraudPaginator;
                }),
        );
    }

    initFraudUpdates() {
        this.subscriptions.push(
            this.adminFraudLimitProvider
                .getUpdateResponse$()
                .pipe()
                .subscribe(
                    (
                        response: FraudLimitsResponse | IssuerLimitsOfDeleteRequest | IssuerLimitsOfAddOrUpdateRequest,
                    ) => {
                        if (!response) {
                            return;
                        }
                        debug(response);
                        this.defaultSelection = new SelectionModel<any>();
                        this.issuerSelection = new SelectionModel<any>();
                        switch (this.action) {
                            case ACTION.UPDATE_DEFAULT:
                                debug('UPDATE_DEFAULT');
                                this.openSnackBar(this.defaultFraudUpdateNotificationMessage);
                                break;

                            case ACTION.ADD_ISSUER:
                                debug('ADD_ISSUER');
                                this.openSnackBar(this.issuerAddedNotificationMessage);
                                break;

                            case ACTION.DELETE_ISSUER:
                                debug('DELETE_ISSUER');
                                this.openSnackBar(this.issuerDeletedNotificationMessage);
                                break;

                            case ACTION.UPDATE_ISSUER:
                                debug('UPDATE_ISSUER');
                                this.openSnackBar(this.issuerUpdatedNotificationMessage);
                                break;
                            case ACTION.UNDEFINED:
                                debug('UNDEFINED');
                                break;

                            default:
                                break;
                        }
                    },
                ),
        );
    }

    getFraudLimits() {
        this.adminFraudLimitProvider.requestFraudLimits$();
    }

    onUpdateDefaultFraud() {
        const dialogRef = this.dialog.open(UpdateDefaultDialog, {
            width: '50%',
            data: { updatedDefaultValue: this.selectedDefaultFraud() },
        });

        dialogRef.afterClosed().subscribe((result: DefaultFraudLimitsRequest) => {
            if (result) {
                this.action = ACTION.UPDATE_DEFAULT;
                this.adminFraudLimitProvider.updateDefaultFraudLimit$(result);
            }
        });
    }

    onAddIssuer() {
        this.isUpdateIssuer = false;
        this.issuer = {} as IssuerLimitsOfAddOrUpdateRequest;
        const dialogRef = this.dialog.open(AddOrUpdateIssuerDialog, {
            width: '50%',
            data: { issuer: this.issuer, updateIssuer: this.isUpdateIssuer },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.issuerLimits.some((issuerLimit) => issuerLimit.name === result.name)) {
                    debug(result);
                    debug(this.issuerAddExistNotificationMessage);
                    this.openSnackBarError(this.issuerAddExistNotificationMessage);
                } else {
                    this.action = ACTION.ADD_ISSUER;
                    this.adminFraudLimitProvider.addIssuer$(result);
                }
            }
        });
    }

    onUpdateIssuer() {
        this.isUpdateIssuer = true;

        this.issuer = this.selectedFraudIssuer();
        const dialogRef = this.dialog.open(AddOrUpdateIssuerDialog, {
            width: '50%',
            data: { issuer: this.issuer, updateIssuer: this.isUpdateIssuer },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.action = ACTION.UPDATE_ISSUER;
                this.adminFraudLimitProvider.updateIssuer$(result);
            }
        });
    }

    onDeleteIssuer() {
        const selectedIssuer = this.selectedFraudIssuer();
        const issuer: IssuerLimitsOfDeleteRequest = { issuerName: selectedIssuer.name };

        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Delete Issuer Limit',
                confirmationMessage:
                    'Are you sure you want to delete the limit for issuer ' + selectedIssuer.name + '?',
            },
        });

        this.subscriptions.push(
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.action = ACTION.DELETE_ISSUER;
                    this.adminFraudLimitProvider.deleteIssuer$(issuer);
                }
            }),
        );
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, null, { duration: 2000, panelClass: ['blue-snackbar'] });
    }
    openSnackBarError(message: string) {
        this._snackBar.open(message, null, { duration: 5000, verticalPosition: 'top', panelClass: ['red-snackbar'] });
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
