import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin-fraudLimits.actions';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { FraudLimitsResponse } from '../models/fraudLimits/fraudLimitsResponse.model';
import { DefaultFraudLimitsRequest } from '../models/fraudLimits/defaultFraudLimits.model';
import {
    IssuerLimitsOfAddOrUpdateRequest,
    IssuerLimitsOfDeleteRequest,
} from '../models/fraudLimits/issuerLimits.model';

const debug = Debug('dgoods-lib-admin-fe');
@Injectable({ providedIn: 'root' })
export class AdminFraudLimitsProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public requestFraudLimits$(): void {
        this.store.dispatch(fromActions.getFraudLimits());
    }

    public getFraudLimits$(): Observable<FraudLimitsResponse[]> {
        return this.store.pipe(
            select(fromFeature.featureFraudLimits),
            filter(
                (getFraudLimitsResponse: FraudLimitsResponse[]) =>
                    getFraudLimitsResponse.length !== 0 && getFraudLimitsResponse != null,
            ),
        );
    }

    public getUpdateResponse$(): Observable<
        FraudLimitsResponse | IssuerLimitsOfDeleteRequest | IssuerLimitsOfAddOrUpdateRequest
    > {
        return this.store.pipe(select(fromFeature.featureFraudUpdateResponse));
    }

    public updateDefaultFraudLimit$(defaultFraudLimit: DefaultFraudLimitsRequest): Observable<FraudLimitsResponse[]> {
        this.store.dispatch(fromActions.updateDefaultFraudLimit({ payload: defaultFraudLimit }));
        return this.store.pipe(
            select(fromFeature.featureFraudLimits),
            map((getFraudLimitsResponse: FraudLimitsResponse[]) => {
                if (getFraudLimitsResponse) {
                    return getFraudLimitsResponse;
                } else {
                    debug('Get fraud limits response is undefined or null');
                    return undefined;
                }
            }),
        );
    }

    public addIssuer$(issuer: IssuerLimitsOfAddOrUpdateRequest): Observable<FraudLimitsResponse[]> {
        this.store.dispatch(fromActions.addIssuer({ payload: issuer }));
        return this.store.pipe(
            select(fromFeature.featureFraudLimits),
            map((getFraudLimitsResponse: FraudLimitsResponse[]) => {
                if (getFraudLimitsResponse) {
                    return getFraudLimitsResponse;
                } else {
                    debug('Get fraud limits response is undefined or null');
                    return undefined;
                }
            }),
        );
    }

    public updateIssuer$(issuer: IssuerLimitsOfAddOrUpdateRequest): Observable<FraudLimitsResponse[]> {
        this.store.dispatch(fromActions.updateIssuer({ payload: issuer }));
        return this.store.pipe(
            select(fromFeature.featureFraudLimits),
            map((getFraudLimitsResponse: FraudLimitsResponse[]) => {
                if (getFraudLimitsResponse) {
                    return getFraudLimitsResponse;
                } else {
                    debug('Get fraud limits response is undefined or null');
                    return undefined;
                }
            }),
        );
    }

    public deleteIssuer$(issuer: IssuerLimitsOfDeleteRequest): Observable<FraudLimitsResponse[]> {
        this.store.dispatch(fromActions.deleteIssuer({ payload: issuer }));
        return this.store.pipe(
            select(fromFeature.featureFraudLimits),
            map((getFraudLimitsResponse: FraudLimitsResponse[]) => {
                if (getFraudLimitsResponse) {
                    return getFraudLimitsResponse;
                } else {
                    debug('Get fraud limits response is undefined or null');
                    return undefined;
                }
            }),
        );
    }

    public getError$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureError),
            map((error: any) => {
                return error;
            }),
        );
    }

    public getErrorDefaultLimits$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureErrorFraudDefault),
            map((error: any) => {
                return error;
            }),
        );
    }
    public getErrorIssuerLimits$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureErrorFraudIssuer),
            map((error: any) => {
                return error;
            }),
        );
    }
}
