import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import Debug from 'debug';

const debug = Debug('modeso:dgoods-admin:MarkdownInputComponent');

export function conputeAtRuntime(container: Container) {
    return container;
}

@Component({
    selector: 'app-markdown-input',
    templateUrl: './markdown-input.component.html',
    styleUrls: ['./markdown.component.scss'],
    viewProviders: [
        { provide: ControlContainer, useFactory: conputeAtRuntime, deps: [[new SkipSelf(), ControlContainer]] },
    ],
})
export class MarkdownInputComponent {
    @Input() controlName: string;
}
