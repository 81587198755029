import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { Blocked } from '../models/analytics-reports/blocked.model';
import { Users } from '../models/analytics-reports/users.model';
import { Views } from '../models/analytics-reports/views.model';
import { AnalyticsReportRequest } from '../models/analytics-reports/analyticsReportRequest.model';
import { Sessions } from '../models/analytics-reports/session.model';
import { Goods } from '../models/analytics-reports/goods.model';

export type reportResponse = Blocked | Goods | Users[] | Views | Sessions;
@Injectable({ providedIn: 'root' })
export class AdminReportService {
    private server = '';
    private microserviceName = 'analytics/purchasingreport';
    private readonly route = '/';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getReport(requestParams: { payload: AnalyticsReportRequest }) {
        const url =
            // tslint:disable-next-line: max-line-length
            `${this.server}${this.microserviceName}${this.route}${requestParams.payload.kpi}${this.route}${requestParams.payload.timePeriod}${this.route}${requestParams.payload.date}${this.route}${requestParams.payload.isCouponOrders}`;
        return this.http.get<reportResponse>(url);
    }
}
