import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { INewsticker, INewstickerResponse } from '@modeso/types__dgoods-products';

// New Sticker
export const getAllNewstickers = createAction('[Admin get Newsticker List] getAllNewstickers request');

export const onGetAllNewstickersSucceeded = createAction(
    '[Admin get Newsticker List] getAllNewstickers success',
    props<{ payload: Array<INewstickerResponse> }>(),
);

export const onGetAllNewstickersFailed = createAction(
    '[Admin get Newsticker List] getAllNewstickers fail',
    props<{ payload: any }>(),
);

export const addNewstickers = createAction(
    '[Admin add Newsticker] addNewstickers request',
    props<{ payload: INewsticker }>(),
);

export const onAddNewstickerSucceeded = createAction(
    '[Admin add Newsticker] addNewstickers success',
    props<{ payload: INewstickerResponse }>(),
);

export const onAddNewstickerFailed = createAction(
    '[Admin add Newsticker] addNewstickers fail',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateNewsticker = createAction(
    '[Admin update Newsticker] updateNewsticker request',
    props<{ payload: INewstickerResponse }>(),
);

export const onUpdateNewstickerSucceeded = createAction(
    '[Admin update Newsticker] updateNewsticker success',
    props<{ payload: INewstickerResponse }>(),
);

export const onUpdateNewstickerFailed = createAction(
    '[Admin update Newsticker] updateNewsticker fail',
    props<{ payload: HttpErrorResponse }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
