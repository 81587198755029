import { Injectable } from '@angular/core';
import * as fromActions from './../actions/admin-reports.action';
import * as fromFeature from './../reducers/admin.reducer';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { AnalyticsReportRequest } from '../models/analytics-reports/analyticsReportRequest.model';
import { reportResponse } from '../services/admin-report.service';

const debug = Debug('dgoods-lib-admin-fe');
@Injectable({ providedIn: 'root' })
export class AdminReportProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public getReport$(reportRequest: AnalyticsReportRequest): Observable<reportResponse> {
        this.store.dispatch(fromActions.getReport({ payload: reportRequest }));
        return this.store.pipe(
            select(fromFeature.featureReport),
            map((reportRespone: reportResponse) => {
                if (reportRespone) {
                    return reportRespone;
                } else {
                    debug('analytics report response is undefined or null');
                    return undefined;
                }
            }),
        );
    }

    public getError$(): Observable<any> {
        return this.store.pipe(
            select(fromFeature.featureError),
            map((error: any) => {
                return error;
            }),
        );
    }
}
