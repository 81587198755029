import { InjectionToken } from '@angular/core';
import { Config } from '@modeso/modeso-lib-core-fe';

export interface DgoodsUserConfig extends Config {
    serviceId: string;
    // if true, the service is used in the prototyping framework
    // so that all calls respond with 200 success and predefined data, if not otherwise defined
    prototype?: boolean;
}

export const DgoodsUserConfigService = new InjectionToken<DgoodsUserConfig>('Config');
