import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { Form, FormControl } from '@angular/forms';
import { marked } from 'marked';
import Debug from 'debug';
import { MARKED_CONFIG, MODESO_DEFAULT_MARKED_CONFIG } from '@modeso/twint-lib-core-fe';
import { SafeHtml } from '@angular/platform-browser';
import { SafeHtmlPipeImages } from './safeHtml.pipe';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../services/upload.service';
import { IUploadURL } from '../../services/uploadUrl.model';
import { take } from 'rxjs';
import { FileUploadUtil } from '../../util/fileUtil';
import { ConfirmationDialogComponent } from '../../../pages/Dialogs/confirmation-dialog.component';
import { timer, throwError } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

const debug = Debug('modeso:dgoods-admin:MarkdownEditorWithImageComponent');

export interface I_Image {
    imgId: string;
    url: string;
    filename: string;
}

@Component({
    selector: 'app-markdown-image-editor',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
})
export class MarkdownEditorWithImageComponent implements OnInit, OnDestroy {
    subscriptions: any[] = [];

    showPreview: boolean = false;
    _markdown: string = '';
    get markdown(): string {
        return this._markdown;
    }
    @Input() set markdown(value: string) {
        this._markdown = value;
        this._formControl.setValue(value);
    }

    @Input() validators?: [];
    @Input() errorMessage?: '';
    @Input() images: string[];

    _formControl: FormControl = new FormControl('');
    get control(): FormControl {
        return this._formControl;
    }
    @Input() set control(value: FormControl) {
        this._formControl = value;
    }

    @Output() valueChanged = new EventEmitter<string>();
    @Output() imageUploaded = new EventEmitter<string>();
    @Output() imageDeleted = new EventEmitter<string>();

    onInputChange(value: string) {
        this.valueChanged.emit(value);
    }

    protected config: marked.MarkedOptions;

    @ViewChild(NgxFileDropComponent, { static: false }) imgFileDropElement;
    isReadOnly: boolean = false;
    isUploading: boolean = false;
    nameOfUnapprovedFile: string = '';
    imgList: I_Image[] = [];
    errorMessageForFile: string = '';
    uploadError: boolean = false;
    deleteError: boolean = false;
    uploadedIcon: {};

    fileUtil: FileUploadUtil = new FileUploadUtil();

    constructor(
        public uploadService: UploadService,
        public dialog: MatDialog,
        private pipe: SafeHtmlPipeImages,
        @Optional() @Inject(MARKED_CONFIG) config: marked.MarkedOptions,
    ) {
        if (config) {
            this.config = config;
        } else {
            this.config = MODESO_DEFAULT_MARKED_CONFIG;
        }
        marked.setOptions(this.config);
    }

    ngOnInit(): void {
        this._markdown = this._formControl.value;
        this._formControl.setValidators(this.validators);
        this._formControl.valueChanges.subscribe((value) => {
            this._markdown = value;
            this.onInputChange(value);
        });
    }

    getHtml(): SafeHtml {
        const result = this.pipe.transform(marked.parse(this._markdown), 'string');

        return result;
    }

    getHtmlString(): string {
        return marked.parse(this._markdown);
    }

    togglePreview() {
        this.showPreview = !this.showPreview;
    }

    public imgDropped(droppedFile: NgxFileDropEntry): void {
        this.uploadError = false;
        const fileEntry = droppedFile[0].fileEntry as FileSystemFileEntry;

        fileEntry.file(async (file: File) => {
            if (!this.fileUtil.isFileSizeAllowed(file.size) || !(await this.fileUtil.validateFile(file))) {
                this.uploadError = true;
                return;
            }
            const fileUuid = uuidv4();
            const fileName = fileUuid + '.' + droppedFile[0].relativePath.split('.').pop();

            const formData = new FormData();
            formData.append('file', file);

            const maxRetries = 3;
            const initialDelay = 1000; // Initial delay in milliseconds

            this.uploadService
                .getUploadUrl(fileName, formData)
                .pipe(
                    retryWhen((errors) => {
                        let retries = 0;
                        return errors.pipe(
                            mergeMap((error) => {
                                if (retries < maxRetries) {
                                    retries++;
                                    const delay = Math.pow(2, retries) * initialDelay;
                                    return timer(delay);
                                }
                                return throwError(() => error);
                            }),
                            take(maxRetries + 1),
                        );
                    }),
                    take(1),
                )
                .subscribe(
                    (urlObj: IUploadURL) => {
                        console.log('Trying to upload file');

                        this.uploadService
                            .uploadFile(urlObj.uploadUrl, file, urlObj.contentType)
                            .pipe(take(1))
                            .subscribe(
                                (response) => {
                                    this.imageUploaded.emit(urlObj.fetchUrl);
                                    this.isUploading = false;
                                    this.uploadError = false;
                                },
                                (err) => {
                                    if (err.error.status === 'Bad Request') {
                                        this.errorMessage = err.error.error;
                                    } else {
                                        this.errorMessageForFile = 'Something went wrong while uploading the file';
                                    }
                                    this.isUploading = false;
                                    this.uploadError = true;
                                },
                            );
                    },
                    (error) => {
                        this.errorMessageForFile = 'Something went wrong while uploading the file';
                        this.isUploading = false;
                        this.uploadError = true;
                    },
                );
        });
    }

    openDeleteConfirmationDialog(event, imageUrl: string): void {
        event.stopPropagation();

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: { message: 'Are you sure you want to delete this image? Any unsaved content changes will be lost.' },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((result) => {
                if (result) {
                    // User confirmed deletion, perform deletion logic here
                    this.deleteImage(imageUrl);
                }
            });
    }

    deleteImage(imageUrl: string) {
        this.deleteError = false;
        const segments = imageUrl.split('/');
        const filenameWithExtension = segments.pop();

        const maxRetries = 3;
        const initialDelay = 1000; // Initial delay in milliseconds

        this.uploadService
            .deleteFile(filenameWithExtension)
            .pipe(
                retryWhen((errors) => {
                    let retries = 0;
                    return errors.pipe(
                        mergeMap((error) => {
                            if (retries < maxRetries) {
                                retries++;
                                const delay = Math.pow(2, retries) * initialDelay;
                                return timer(delay);
                            }
                            return throwError(() => error);
                        }),
                        take(maxRetries + 1),
                    );
                }),
                take(1),
            )
            .subscribe(
                () => {
                    this.imageDeleted.emit(imageUrl);
                },
                (error) => {
                    this.deleteError = true;
                    console.error(error);
                },
            );
    }

    insertImage(imageUrl: string): void {
        this._formControl.setValue(this._formControl.value + '\n\n' + `![ContentPageImage](${imageUrl})`);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscribe) => {
            subscribe.unsubscribe();
        });
    }
}
