export const FraudRulesConstants = {
    // Column definitions
    COLUMNS: {
        RULE_NAME: 'ruleName',
        RULE_TYPE: 'ruleType',
        THRESHOLD_VALUE: 'thresholdValue',
        STATUS: 'status',
        ACTIONS: 'actions'
    },

    COLUMN_LABELS: {
        RULE_NAME: 'Rule Name',
        RULE_TYPE: 'Rule Type',
        THRESHOLD_VALUE: 'Threshold Value / Conditions',
        STATUS: 'Status',
        ACTIONS: 'Actions'
    },

    // Complete column configuration
    DISPLAYED_COLUMNS_KEYS: ['ruleName', 'ruleType', 'thresholdValue', 'status', 'actions'],

    DISPLAYED_COLUMNS: {
        'ruleName': 'Rule Name',
        'ruleType': 'Rule Type',
        'thresholdValue': 'Threshold Value / Conditions',
        'status': 'Status',
        'actions': 'Actions'
    },

    // Pagination
    DEFAULT_PAGE_SIZE: 5,
    DEFAULT_CURRENT_PAGE: 0,
    PAGE_SIZE_OPTIONS: [5, 10, 20, 50, 100],

    // Rule type display
    SINGLE_TRANSACTION_LABEL: 'Single Transaction',
    REPEATED_TRANSACTIONS_LABEL: 'Repeated Transactions',

    // Status display
    ENABLED_STATUS: 'Enabled',
    DISABLED_STATUS: 'Disabled',

    // Dialog messages
    DELETE_CONFIRMATION: 'Are you sure you want to delete this rule? This action cannot be undone.',
    TOGGLE_CONFIRMATION_TEMPLATE: 'Are you sure you want to {0} this rule? This action will immediately take effect.',
    DISABLE_ACTION: 'disable',
    ENABLE_ACTION: 'enable',

    // Button text
    CONFIRM_BUTTON: 'Confirm',
    DELETE_BUTTON: 'Delete',

    // Success messages
    DISABLE_SUCCESS_TEMPLATE: 'Rule "{0}" has been disabled successfully.',
    ENABLE_SUCCESS_TEMPLATE: 'Rule "{0}" has been enabled successfully.',
    DELETE_SUCCESS_TEMPLATE: 'Rule "{0}" has been deleted successfully.',

    // Error messages
    NETWORK_ERROR: 'Network error. Please check your internet connection and try again.',
    DEFAULT_ERROR: 'An unexpected error occurred. Please try again later.',

    // Routes
    CREATE_RULE_ROUTE: '/de-ch/fraud-popups/create',
    EDIT_RULE_ROUTE: '/de-ch/fraud-popups/edit',

    // Currency format
    CURRENCY_PREFIX: 'CHF ',
    THRESHOLD_PREFIX: '≥ ',

    // Repeated transaction format
    REPEATED_TRANSACTION_FORMAT: '{0} in {1} min',

    // Languages
    LANGUAGES: [{ key: 'de-ch', label: 'German' }, { key: 'fr-ch', label: 'French' }, { key: 'en-us', label: 'English' }, { key: 'it-ch', label: 'Italian' }]
};
