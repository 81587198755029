<div *ngIf="!newstickerId" class="newsticker-overview-container" [hidden]="isRemoved">
    <div *ngIf="item" class="newsticker-overview row" [style.backgroundColor]="true ? item.color : ''">
        <div class="column1">
            <div
                class="text-style"
                [ngStyle]="{ color: item.fontColor, '--link-color': item.fontColor }"
                [innerHtml]="item.text | markdownToSafeHtml"
            ></div>
        </div>
        <div class="column2"></div>
        <div class="column3">
            <a (click)="removeNewsticker()" [ngStyle]="{ color: item.fontColor }" matSuffix>
                <mat-icon class="cancel-mat-icon">cancel</mat-icon>
            </a>
        </div>
    </div>
</div>
