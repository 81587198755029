import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { INewsticker, INewstickerResponse } from '@modeso/types__dgoods-products';

import Debug from 'debug';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminNewsTickerService');
@Injectable({ providedIn: 'root' })
export class AdminNewsTickerService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly newstickerRoute = 'newsticker';
    private readonly all = 'all';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }
    public addNewsticker(newsticker: INewsticker) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.newstickerRoute}`;
        return this.http.post<INewstickerResponse>(url, newsticker);
    }

    public getAllNewsticker() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.newstickerRoute}${this.productsRoute}${this.all}`;
        return this.http.get<INewstickerResponse[]>(url);
    }

    public updateNewsticker(newsticker) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.newstickerRoute}${this.productsRoute}${newsticker._id}`;
        return this.http.put<INewstickerResponse>(url, newsticker);
    }
}
