<div class="dgoods-input">
    <input
        #input
        (focus)="onFocus()"
        (keyup)="onKeyUp(input.value)"
        (blur)="onBlur(input.value)"
        [placeholder]="caption | injectedText | async"
        [value]="value"
    />
    <label *ngIf="!captionHidden">{{ caption | injectedText | async }}</label>
</div>
