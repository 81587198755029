import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'lib-dgoods-newsticker',
    templateUrl: './dgoods-newsticker.component.html',
    styleUrls: ['./dgoods-newsticker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DgoodsNewstickerComponent implements OnInit {
    @Input() item;
    isRemoved = false;
    newstickerId = sessionStorage.getItem('newstickerId');

    constructor() {}

    ngOnInit(): void {}

    removeNewsticker() {
        this.isRemoved = true;
        sessionStorage.setItem('newstickerId', 'removed');
    }
}
