import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDGoodsTableDataSource } from './dgoods-table.datasource';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsTableCellComponent');

export interface IDGoodsTableCellDelegate {
    onTableCellClick(data: IDGoodsTableDataSource): void;
}

@Component({
    selector: 'dgoods-table-cell',
    templateUrl: './dgoods-table-cell.component.html',
    styleUrls: ['./dgoods-table-cell.component.scss'],
})
export class DGoodsTableCellComponent {
    @Input() delegate?: IDGoodsTableCellDelegate;
    @Input() datasource: IDGoodsTableDataSource;
    @Output() clickedData = new EventEmitter<IDGoodsTableDataSource>();

    onClick() {
        if (this.delegate) {
            this.clickedData.emit(this.datasource);
            this.delegate.onTableCellClick(this.datasource);
        } else {
            debug('onTableCellClick event not fired. No delegate defined for the component.');
        }
    }
}
