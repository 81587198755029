import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { IContentPage, IContentPageResponse } from '../models/content.pages';

@Injectable({ providedIn: 'root' })
export class AdminContentPageService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly contentPageRoute = 'contentpages';
    private readonly all = '';
    private readonly manage = 'manage';

    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    public addContentPage(contentPage: IContentPage) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentPageRoute}`;
        return this.http.post<IContentPageResponse>(url, contentPage);
    }

    public getAllContentPages() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentPageRoute}${this.productsRoute}${this.manage}`;
        return this.http.get<IContentPageResponse[]>(url);
    }

    public updateContentPage(contentPage: IContentPageResponse) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentPageRoute}${this.productsRoute}${contentPage._id}`;
        return this.http.put<IContentPageResponse>(url, contentPage);
    }

    public deleteContentPage(contentPageId: string) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentPageRoute}${this.productsRoute}${contentPageId}`;
        return this.http.delete(url);
    }
}
