export enum AuditLogActions {
    ALL = 'ALL',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    FAILED_LOGIN = 'FAILED_LOGIN',
    UNBLOCK_PRODUCT = 'UNBLOCK_PRODUCT',
    UNBLOCK_SHOP = 'UNBLOCK_SHOP',
    CHANGE_LIMIT = 'CHANGE_LIMIT',
    CREATE_ISSUER_LIMIT = 'CREATE_ISSUER_LIMIT',
    DELETE_ISSUER_LIMIT = 'DELETE_ISSUER_LIMIT',
    UPDATE_ISSUER_LIMIT = 'UPDATE_ISSUER_LIMIT',
}
