import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminDigitalVoucherConfiguratorProvider } from '@modeso/dgoods-lib-admin-fe';
import {
    DigitalVoucherConfigAttributes,
    DisplayLinkValues,
    IDigitalVoucherConfigurator,
    MerchantType,
    SweePayDigitalVoucherValues,
    EPayDigitalVoucherValues,
    IConfigration,
    IMultiLanguageText,
    OfferzDigitalVoucherValues,
    CodeTypes,
} from '@modeso/types__dgoods-products';
import { Subscription } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:dgoods-admin:DigitalVoucherConfiguratorComponent');

export enum PdfValues {
    NoDisplay = "Don't Display",
    accountCardLink = 'account.card_link',
}

@Component({
    selector: 'app-digital-voucher-configurator',
    templateUrl: './digital-voucher-configurator.component.html',
    styleUrls: ['./digital-voucher-configurator.component.scss'],
})
export class DigitalVoucherConfiguratorComponent implements OnInit, OnDestroy {
    digitalVoucherFrom: FormGroup;
    productLineName: string;
    subscription: Subscription;
    errorSubscription: Subscription;
    merchantType: string;
    iterators:
        | typeof DigitalVoucherConfigAttributes
        | typeof SweePayDigitalVoucherValues
        | typeof EPayDigitalVoucherValues
        | typeof OfferzDigitalVoucherValues;
    pdfIterators: typeof PdfValues;
    digitalVoucher: IDigitalVoucherConfigurator;
    codeType: typeof CodeTypes;
    isNotFound = false;
    constructor(
        private formBuilder: FormBuilder,
        private digitalVoucherProvider: AdminDigitalVoucherConfiguratorProvider,
        private activatedRoute: ActivatedRoute,
    ) {
        this.productLineName = this.activatedRoute.snapshot.paramMap.get('productLineName');
        this.merchantType = this.activatedRoute.snapshot.paramMap.get('merchant');
        this.digitalVoucher = DefaultValues;
        this.digitalVoucher.productLineName = this.productLineName;
        this.codeType = this.codeTypeValues;

        switch (this.merchantType.toUpperCase()) {
            case MerchantType.Bhn:
                this.iterators = this.DigitalVoucherValues;
                break;
            case MerchantType.ePAY:
                this.iterators = this.EPayDigitalVoucherValues;
                break;
            case MerchantType.SweePay:
                this.iterators = this.SweePayDigitalVoucherValues;
                break;
            case MerchantType.OFFERZ:
                this.iterators = this.OfferzDigitalVoucherValues;
                this.pdfIterators = PdfValues;
                break;
            default:
                this.iterators = this.DigitalVoucherValues;
                break;
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.errorSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.digitalVoucherProvider.dispatchGetDigitalVoucherByProductLineName(this.productLineName);
        this.errorSubscription = this.digitalVoucherProvider.getError$().subscribe((errorResponse) => {
            if (errorResponse.status === 404) {
                this.isNotFound = true;
            } else {
                this.isNotFound = false;
            }
        });

        this.createFrom();

        this.subscription = this.digitalVoucherProvider.digitalVoucher$.subscribe((digitalVoucher) => {
            debug('got response from backend', digitalVoucher);
            if (digitalVoucher === undefined) {
                this.digitalVoucher = DefaultValues;
                this.digitalVoucher.productLineName = this.productLineName;
            } else {
                this.digitalVoucher = digitalVoucher;
                this.isNotFound = false;
            }

            debug('got response from backend', this.digitalVoucher);
            if (!this.digitalVoucher) {
                this.createFrom();
            } else {
                this.createFrom();
                this.digitalVoucherFrom.patchValue({
                    config: this.digitalVoucher.config,
                    text: this.digitalVoucher.text,
                });
            }
        });
    }

    createFrom() {
        this.digitalVoucherFrom = this.formBuilder.group({
            config: this.getConfigFormGroup(),
            text: new FormGroup({
                'en-us': this.getTexFormGroup(),
                'de-ch': this.getTexFormGroup(),
                'it-ch': this.getTexFormGroup(),
                'fr-ch': this.getTexFormGroup(),
            }),
        });
    }

    submit() {
        const digitalVoucher: IDigitalVoucherConfigurator = {
            ...this.digitalVoucherFrom.value,
            productLineName: this.productLineName,
        };
        this.digitalVoucherProvider.dispatchCreateOrUpdateDigitalVoucher(digitalVoucher);
    }
    get DigitalVoucherValues(): typeof DigitalVoucherConfigAttributes {
        return DigitalVoucherConfigAttributes;
    }

    get DisplayLinkValues(): typeof DisplayLinkValues {
        return DisplayLinkValues;
    }
    get SweePayDigitalVoucherValues(): typeof SweePayDigitalVoucherValues {
        return SweePayDigitalVoucherValues;
    }
    get EPayDigitalVoucherValues(): typeof EPayDigitalVoucherValues {
        return EPayDigitalVoucherValues;
    }

    get OfferzDigitalVoucherValues(): typeof OfferzDigitalVoucherValues {
        return OfferzDigitalVoucherValues;
    }

    get codeTypeValues(): typeof CodeTypes {
        return CodeTypes;
    }

    get config() {
        return this.digitalVoucherFrom.get('config');
    }

    get text() {
        return this.digitalVoucherFrom.get('text');
    }

    get eanType() {
        return this.digitalVoucherFrom.get('eanType');
    }

    private getConfigFormGroup() {
        return new FormGroup({
            code: new FormControl('', Validators.required),
            card: new FormControl('', Validators.required),
            serial: new FormControl('', Validators.required),
            pin: new FormControl('', Validators.required),
            ean: new FormControl('', Validators.required),
            pdf: new FormControl("Don't Display", Validators.required),
            redemption_information: new FormControl('', Validators.required),
            agb: new FormControl('', Validators.required),
            link: new FormControl(''),
            copy: new FormControl('', Validators.required),
            displayLink: new FormControl('', Validators.required),
            variable: new FormControl("Don't Display", Validators.required),
            variable2: new FormControl("Don't Display", Validators.required),
            variable3: new FormControl("Don't Display", Validators.required),
            eanType: new FormControl(CodeTypes.CODE128, Validators.required),
        });
    }

    private getTexFormGroup() {
        return new FormGroup({
            code: new FormControl('', this.getValidationTypeOfTextControl('code')),
            card: new FormControl('', this.getValidationTypeOfTextControl('card')),
            serial: new FormControl('', this.getValidationTypeOfTextControl('serial')),
            pin: new FormControl('', this.getValidationTypeOfTextControl('pin')),
            ean: new FormControl('', this.getValidationTypeOfTextControl('ean')),
            pdf: new FormControl('PDF', this.getValidationTypeOfTextControl('pdf')),
            redemption_information: new FormControl('', this.getValidationTypeOfTextControl('redemption_information')),
            agb: new FormControl('', this.getValidationTypeOfTextControl('agb')),
            link: new FormControl(''),
            copy: new FormControl('', this.getValidationTypeOfTextControl('copy')),
            copyonly: new FormControl('', this.getValidationTypeOfTextControl('copyonly')),
            extraText: new FormControl(''),
        });
    }

    private getValidationTypeOfTextControl(atributeName: string) {
        if (!this.digitalVoucher) return [];
        return this.digitalVoucher['config'][atributeName] === DigitalVoucherConfigAttributes.NoDisplay ?
                []
            :   Validators.required;
    }
}

export const DefaultConfig: IConfigration = {
    card: DigitalVoucherConfigAttributes.NoDisplay,
    code: DigitalVoucherConfigAttributes.NoDisplay,
    serial: DigitalVoucherConfigAttributes.NoDisplay,
    pin: DigitalVoucherConfigAttributes.NoDisplay,
    ean: DigitalVoucherConfigAttributes.NoDisplay,
    redemption_information: DigitalVoucherConfigAttributes.NoDisplay,
    agb: DigitalVoucherConfigAttributes.NoDisplay,
    displayLink: DisplayLinkValues.NoDisplay,
    link: '',
    copy: DigitalVoucherConfigAttributes.NoDisplay,
    extraText: '',
    eanType: CodeTypes.CODE128,
};
export const DefaultText: IMultiLanguageText = {
    'en-us': {
        card: 'Serial Number',
        code: 'Code',
        serial: 'Serial Number',
        pin: 'PIN',
        ean: 'Code',
        redemption_information: 'Redemption information',
        agb: 'Terms and Conditions',
        displayLink: '',
        link: 'Redeem now',
        copy: 'Redeem and transfer',
        copyonly: 'Redeem',
        extraText: '',
        eanType: '',
    },
    'de-ch': {
        card: 'Serial Number',
        code: 'Code',
        serial: 'Serial Number',
        pin: 'PIN',
        ean: 'Code',
        redemption_information: 'Einlösungsinformationen',
        agb: 'AGB',
        displayLink: '',
        link: 'Jetzt einlösen',
        copy: 'Einlösen und übernehmen',
        copyonly: 'Einlösen',
        extraText: '',
        eanType: '',
    },
    'fr-ch': {
        card: 'Serial Number',
        code: 'Code',
        serial: 'Serial Number',
        pin: 'PIN',
        ean: 'Code',
        redemption_information: 'Informations de rachat',
        agb: 'Conditions générales',
        displayLink: '',
        link: 'Utiliser maintenant',
        copy: 'Utiliser et accepter',
        copyonly: 'échanger',
        extraText: '',
        eanType: '',
    },
    'it-ch': {
        card: 'Serial Number',
        code: 'Code',
        serial: 'Serial Number',
        pin: 'PIN',
        ean: 'Code',
        redemption_information: 'Informazioni di rimborso',
        agb: 'Condizioni generali',
        displayLink: '',
        link: 'Riscuotere adesso',
        copy: 'Riscuotere e rilevare',
        copyonly: 'riscattare',
        extraText: '',
        eanType: '',
    },
};

export const DefaultValues: IDigitalVoucherConfigurator = {
    productLineName: '',
    config: DefaultConfig,
    text: DefaultText,
};
