import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportCostFile } from '../../models/interfaces/export-cost-file.interface';
import { MultiSelect } from '@modeso/twint-lib-core-fe';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../../util/dateFormats.helper';
import { FormControl, FormGroup } from '@angular/forms';
import { ExportCostFileRequest } from '../../models/interfaces/export-cost-file-request.interface';
import { Observable, Subscription, combineLatest, filter, map, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-export-cost-file',
    templateUrl: './export-cost-file-dialog.component.html',
    styleUrls: ['./export-cost-file-dialog.component.scss'],
    providers: [
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ExportCostFileDialog implements OnInit, OnDestroy {
    showSeconds = false;
    formGroup: FormGroup | undefined;
    loadingStateHandler$: Subscription | undefined;
    readonly financeParties$: Observable<MultiSelect[]>;
    readonly title: string = 'Export Cost';
    readonly coupons$: Observable<MultiSelect[]>;
    readonly loading$: Observable<boolean>;
    readonly error$: Observable<HttpErrorResponse>;
    readonly success$: Observable<boolean>;
    exportCost: (data: ExportCostFileRequest) => void;

    private selectedCoupons: MultiSelect[] = [];
    private selectedFinanceParties: MultiSelect[] = [];

    constructor(
        public dialogRef: MatDialogRef<ExportCostFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ExportCostFile,
    ) {
        this.title = data.title;
        this.coupons$ = data.coupons;
        this.financeParties$ = data.financeParties;
        this.loading$ = data.loading;
        this.error$ = data.error;
        this.success$ = data.success;
        this.exportCost = data.export;

        this.formGroup = new FormGroup({ startFrom: new FormControl(null), endAt: new FormControl(null) });
    }

    ngOnInit() {
        this.loadingStateHandler$ = combineLatest([this.loading$, this.error$, this.success$])
            .pipe(filter(([loading, error, success]) => success && !loading && error == null))
            .subscribe(() => {
                this.dialogRef.close();
            });
    }

    export(): void {
        const data: ExportCostFileRequest = {
            startFrom: this.startFrom.value,
            endAt: this.endAt.value,
            coupons: this.selectedCoupons.map((coupon) => coupon.id) ?? [],
            financeParties: this.selectedFinanceParties.map((party) => party.name) ?? [],
        };
        this.exportCost(data);
    }

    cancel() {
        this.dialogRef.close();
    }

    selectingFinanceParties(parties: MultiSelect[]): void {
        this.selectedFinanceParties = [...parties];
    }

    selectingCoupons(coupons: MultiSelect[]): void {
        this.selectedCoupons = [...coupons];
    }

    ngOnDestroy(): void {
        if (this.loadingStateHandler$ != null) {
            this.loadingStateHandler$.unsubscribe();
        }
    }

    get startFrom() {
        return this.formGroup.get('startFrom');
    }

    get endAt() {
        return this.formGroup.get('endAt');
    }
}
