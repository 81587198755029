import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { AllTranslationsResponse } from '../models/manageContent/allTranslationsResponse.model';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';

@Injectable({ providedIn: 'root' })
export class AdminManageContentService {
    private server = '';
    private localizationsMicroserviceName = 'localization';
    private readonly route = '/';
    private readonly getAllLocalizationsUrl = 'all/languages';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getAllLocalizations() {
        const url = `${this.server}${this.localizationsMicroserviceName}${this.route}${this.getAllLocalizationsUrl}`;
        return this.http.get<AllTranslationsResponse>(url);
    }

    updateLocalizationByKey(translation: { payload: TranslationsByKeyResponseDetails }) {
        const url = `${this.server}${this.localizationsMicroserviceName}${this.route}${translation.payload.key}`;
        return this.http.put(url, translation.payload.text);
    }
}
