import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AdminProvider } from '@modeso/dgoods-lib-admin-fe';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({ selector: 'app-header', templateUrl: './header.component.html', styleUrls: ['./header.component.scss'] })
export class HeaderComponent implements AfterViewChecked {
    isUserLoggedIn: boolean;

    constructor(
        private router: Router,
        private adminProvider: AdminProvider,
        private localStorageService: LocalStorageService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngAfterViewChecked() {
        this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
        this.changeDetector.detectChanges();
    }

    onLogout() {
        this.adminProvider.logout();
        this.localStorageService.removeAuthorization();
        this.router.navigateByUrl('de-ch/login');
    }
}
