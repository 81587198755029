export const orderHistoryDisplayedColumns: { [key: string]: string } = {
    merchant: 'Merchant',
    productName: 'Product line',
    createdAt: 'Order Creation date/time',
    email: 'End user Email',
    price: 'Product Price',
    orderStatus: 'Order Status',
    orderLanguage: 'Order Language',
    couponCode: 'Coupon Code',
    couponValue: 'Coupon Value',
    totalPaidAmount: 'Amount Paid by Customer',
};
