import { CouponFilterDTO, CreateCouponDTO, DetailedCouponResponseDTO } from '@modeso/types__twint-lib-coupons';
import { createAction, props, Action } from '@ngrx/store';
import { IFinancingParty } from '../models/interfaces/finance';
import { ExportCostFileRequest } from '../models/interfaces/export-cost-file-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { InvalidateCodeRequest } from '../models/interfaces/invalidate-code-request.interface';
import { FindCouponByIdRequest } from '../models/interfaces/find-coupon-by-id-request.interface';
import { InvalidateCodesRequest } from '../models/interfaces/invalidate-codes-request.interface';
import { UpdateCouponPayload } from '../models/interfaces/update-coupon-request.interface';
import { ArchiveCouponRequest } from '../models/interfaces/archive-coupon-request.interface';

export const onAddCoupon = createAction('[TDV Add Coupon] add coupon', props<{ payload: CreateCouponDTO }>());

export const onAddCouponSuccessfully = createAction('[TDV Add Coupon successfully] add coupon successfully');

export const onAddCouponFailed = createAction(
    '[TDV Add Coupon failed] add coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onUpdateCoupon = createAction(
    '[TDV Update Coupon] update coupon',
    props<{ payload: UpdateCouponPayload }>(),
);

export const onUpdateCouponSuccessfully = createAction('[TDV Update Coupon successfully] update coupon successfully');

export const onUpdateCouponFailed = createAction(
    '[TDV Update Coupon failed] update coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const fetchCouponDetailsById = createAction(
    '[TDV Fetch Coupon Details]',
    props<{ payload: FindCouponByIdRequest }>(),
);

export const onFetchCouponDetailsCouponSuccessfully = createAction(
    '[TDV Fetch Coupon Details successfully]',
    props<{ payload: DetailedCouponResponseDTO }>(),
);

export const onFetchCouponDetailsFailed = createAction(
    '[TDV Fetch Coupon Details failed]',
    props<{ payload: HttpErrorResponse }>(),
);

export const onGetCoupons = createAction('[TDV Get Coupons] get coupons', props<{ payload: CouponFilterDTO }>());

export const onGetCouponsSuccessfully = createAction(
    '[TDV Get Coupons successfully] get coupons successfully',
    props<{ payload: any }>(),
);

export const onGetCouponsFailed = createAction(
    '[TDV Get Coupons failed] get coupons failed',
    props<{ payload: any }>(),
);

export const onActivateCoupon = createAction('[TDV Activate Coupon] activate coupon', props<{ payload: string }>());

export const onActivateCouponSuccessfully = createAction(
    '[TDV Activate Coupon successfully] activate coupon successfully',
    props<{ payload: string }>(),
);

export const onActivateCouponFailed = createAction(
    '[TDV Activate Coupon failed] activate coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onDeactivateCoupon = createAction(
    '[TDV Deactivate Coupon] deactivate coupon',
    props<{ payload: string }>(),
);

export const onDeactivateCouponSuccessfully = createAction(
    '[TDV Deactivate Coupon successfully] deactivate coupon successfully',
    props<{ payload: string }>(),
);

export const onDeactivateCouponFailed = createAction(
    '[TDV Deactivate Coupon failed] deactivate coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onDeleteCoupon = createAction('[TDV Delete Coupon] delete coupon', props<{ payload: string }>());

export const onDeleteCouponSuccessfully = createAction(
    '[TDV Delete Coupon successfully] delete coupon successfully',
    props<{ payload: string }>(),
);

export const onDeleteCouponFailed = createAction(
    '[TDV Delete Coupon failed] delete coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onInvalidateCode = createAction(
    '[TDV Invalidate Code] invalidate code',
    props<{ payload: InvalidateCodeRequest }>(),
);

export const onInvalidateCodeSuccessfully = createAction(
    '[TDV Invalidate Code successfully] invalidate code successfully',
    props<{ payload: DetailedCouponResponseDTO }>(),
);

export const onInvalidateCodeFailed = createAction(
    '[TDV Invalidate Code failed] invalidate code failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onInvalidateCodes = createAction(
    '[TDV Invalidate Codes] invalidate codes',
    props<{ payload: InvalidateCodesRequest }>(),
);

export const onInvalidateCodesSuccessfully = createAction(
    '[TDV Invalidate Codes successfully] invalidate codes successfully',
    props<{ payload: DetailedCouponResponseDTO }>(),
);

export const onInvalidateCodesFailed = createAction(
    '[TDV Invalidate Codes failed] invalidate codes failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onToggleCouponsFeature = createAction(
    '[TDV Toggle Coupon Feature] Toggle Coupon Feature',
    props<{ payload: boolean }>(),
);

export const onToggleCouponsFeatureSuccessfully = createAction(
    '[TDV Toggle Coupon Feature successfully] Toggle Coupon Feature successfully',
    props<{ payload: any }>(),
);
export const onToggleCouponsFeatureFailed = createAction(
    '[TDV Toggle Coupon Feature failed] Toggle Coupon Feature failed',
    props<{ payload: any }>(),
);

export const onCheckCouponsFeature = createAction('[TDV Check Coupon Feature] Check Coupon Feature');
export const onCheckCouponsFeatureSuccessfully = createAction(
    '[TDV Check Coupon Feature successfully] Check Coupon Feature successfully',
    props<{ payload: any }>(),
);
export const onCheckCouponsFeatureFailed = createAction(
    '[TDV Check Coupon Feature failed] Check Coupon Feature failed',
    props<{ payload: any }>(),
);

export const onArchiveCoupon = createAction(
    '[TDV Archive Coupon] archive coupon',
    props<{ payload: ArchiveCouponRequest }>(),
);

export const onArchiveCouponSuccessfully = createAction(
    '[TDV Archive Coupon successfully] archive coupon successfully',
    props<{ payload: ArchiveCouponRequest }>(),
);

export const onArchiveCouponFailed = createAction(
    '[TDV Archive Coupon failed] archive coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onControlCoupon = createAction('[TDV Control Coupon] control coupon', props<{ payload: string }>());

export const onControlCouponSuccessfully = createAction(
    '[TDV Control Coupon successfully] control coupon successfully',
);

export const onControlCouponFailed = createAction(
    '[TDV Control Coupon failed] control coupon failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const onExportCostFile = createAction(
    '[TDV Export Cost] export cost file',
    props<{ payload: ExportCostFileRequest }>(),
);

export const onExportCostFileSuccessfully = createAction(
    '[TDV Export Cost successfully] export cost file successfully',
);

export const onExportCostFileFailed = createAction(
    '[TDV Export Cost failed] export cost file failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const resetExportCostState = createAction('[TDV Reset Export Cost State] Reset Export Cost State');

export const flushCouponError = createAction('[TDV Flush Coupon] Flush Coupon Errors');

export const getFinancingParties = createAction('[TDV get Financing Parties] get financing parties');
export const getFinancingPartiesSuccess = createAction(
    '[TDV get Financing Parties Success] get financing parties success',
    props<{ payload: IFinancingParty[] }>(),
);
export const getFinancingPartiesFailure = createAction(
    '[TDV get Financing Parties Failure] get financing parties failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const addFinanceParty = createAction(
    '[TDV Add Financing Party] add financing party',
    props<{ payload: string }>(),
);

export const addFinancePartySuccess = createAction(
    '[TDV Add Financing Party Success] add financing party success',
    props<{ payload: IFinancingParty }>(),
);

export const addFinancePartyFailure = createAction(
    '[TDV Add Financing Party Failure] add financing party failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const onGetCouponTotalDiscount = createAction(
    '[TDV Coupon Total Discount] total coupon discount',
    props<{ payload: string }>(),
);

export const onGetCouponTotalDiscountSuccessfully = createAction(
    '[TDV Coupon Total Discount Success] total coupon discount successfully',
    props<{ payload: number }>(),
);

export const onGetCouponTotalDiscountFailed = createAction(
    '[TDV Coupon Total Discount Failed] total coupon discount failed',
    props<{ payload: HttpErrorResponse }>(),
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
