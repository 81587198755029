import { createAction, props, Action } from '@ngrx/store';
import { AnalyticsReportRequest } from '../models/analytics-reports/analyticsReportRequest.model';
import { Blocked } from '../models/analytics-reports/blocked.model';
import { Goods } from '../models/analytics-reports/goods.model';
import { Users } from '../models/analytics-reports/users.model';
import { Views } from '../models/analytics-reports/views.model';
import { Sessions } from '../models/analytics-reports/session.model';

type reportResponse = Blocked | Goods | Users[] | Views | Sessions;

export const getReport = createAction('[Admin] getReport', props<{ payload: AnalyticsReportRequest }>());

export const onGetReportSuccessfully = createAction(
    '[Admin Report] getReport successfully',
    props<{ payload: reportResponse }>(),
);

export const onGetReportFailed = createAction('[Admin Report] getReport failed', props<{ payload: any }>());

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
