export const orderStatusesMapping = {
    CREATED: 'Created',
    PAYMENT_FAILED: 'Payment Failed',
    PAYMENT_IN_PROGRESS: 'Payment In Progress',
    PAYMENT_CANCELED: 'Payment Canceled',
    PAYMENT_RESERVED: 'Payment Reserved',
    EGIFT_FAILED: 'Egift Failed',
    EGIFT_GENERATED: 'Egift Generated',
    PAYMENT_REVERSED: 'Payment Reversed',
    REVERSE_CONFIRMED: 'Reverse Confirmed',
    CANCEL_FAILED: 'Cancel Failed',
    CONFIRM_FAILED: 'Confirm Failed',
    REVERSE_FAILED: 'Reverse Failed',
    PAYMENT_SUCCESS: 'Payment Success',
    ORDER_SUCCESS: 'Order Success',
};

export const orderStatusesMap: Map<string, string> = new Map(Object.entries(orderStatusesMapping));
