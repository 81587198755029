import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from '../admin.config';
import { IContentBox, IContentBoxResponse } from '@modeso/types__dgoods-products';

@Injectable({ providedIn: 'root' })
export class AdminContentBoxService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly contentBoxRoute = 'contentboxes';
    private readonly all = '';
    private readonly manage = 'manage';

    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    public addContentBox(contentBox: IContentBox) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentBoxRoute}`;
        return this.http.post<IContentBoxResponse>(url, contentBox);
    }

    public getAllContentBoxes() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentBoxRoute}${this.productsRoute}${this.manage}`;
        return this.http.get<IContentBoxResponse[]>(url);
    }

    public getContentBoxById(contentBoxId: string) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentBoxRoute}${this.productsRoute}${contentBoxId}`;
        return this.http.get<IContentBoxResponse>(url);
    }

    public updateContentBox(contentBox: IContentBoxResponse) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentBoxRoute}${this.productsRoute}${contentBox._id}`;
        return this.http.put<IContentBoxResponse>(url, contentBox);
    }

    public deleteContentBox(contentBoxId: string) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.contentBoxRoute}${this.productsRoute}${contentBoxId}`;
        return this.http.delete(url);
    }
}
