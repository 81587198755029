import { ExportOrdersHistory } from '@modeso/types__dgoods-cart';
export const constructExportOrderHistoryQueryParams = (requestParams: ExportOrdersHistory): URLSearchParams => {
    const queryParams = new URLSearchParams();
    if (requestParams.merchant != null) {
        queryParams.append('merchant', requestParams.merchant);
    }
    if (requestParams.productLine != null) {
        queryParams.append('productLine', requestParams.productLine);
    }
    if (requestParams.orderStatus != null && requestParams.orderStatus.length > 0) {
        queryParams.append('orderStatus', requestParams.orderStatus);
    }
    if (requestParams.startDate != null) {
        queryParams.append('startDate', requestParams.startDate);
    }
    if (requestParams.endDate != null) {
        queryParams.append('endDate', requestParams.endDate);
    }
    if (requestParams.includeCoupons == null) {
        queryParams.append('includeCoupons', 'false');
    } else {
        queryParams.append('includeCoupons', requestParams.includeCoupons);
    }

    return queryParams;
};
