import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateUpdateCategoryRequestModel } from '../models/manageProductCategories/categories.models';

export const getAllProductCategory = createAction('[Admin ManageProductCategory] getAll');

export const onGetAllProductCategorySuccessfully = createAction(
    '[Admin ManageProductCategory] getAll successfully',
    props<{ payload: any }>(),
);

export const onGetAllProductCategoryFailed = createAction(
    '[Admin ManageProductCategory] getAll failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const createCategory = createAction(
    '[Admin ManageProductCategory] createCategory',
    props<{ payload: CreateUpdateCategoryRequestModel }>(),
);

export const onCreateCategorySuccessfully = createAction(
    '[Admin ManageProductCategory] onCreateCategorySuccessfully',
    props<{ payload: any }>(),
);

export const onCreateCategoryFailed = createAction(
    '[Admin ManageProductCategory] onCreateCategoryFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateCategory = createAction(
    '[Admin ManageProductCategory] updateCategory',
    props<{ payload: CreateUpdateCategoryRequestModel }>(),
);

export const onUpdateCategorySuccessfully = createAction(
    '[Admin ManageProductCategory] onUpdateCategorySuccessfully',
    props<{ payload: any }>(),
);

export const onUpdateCategoryFailed = createAction(
    '[Admin ManageProductCategory] onUpdateCategoryFailed',
    props<{ payload: HttpErrorResponse }>(),
);
export const deleteCategory = createAction(
    '[Admin ManageProductCategory] deleteCategory',
    props<{ payload: string }>(),
);

export const onDeleteCategorySuccessfully = createAction(
    '[Admin ManageProductCategory] onDeleteCategorySuccessfully',
    props<{ payload: any }>(),
);

export const onDeleteCategoryFailed = createAction(
    '[Admin ManageProductCategory] onDeleteCategoryFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
