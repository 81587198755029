export class AuditLogsResponse {
    public auditLogs: AuditLogsModel[];
    constructor() {
        this.auditLogs = new Array<AuditLogsModel>();
    }
}

export class AuditLogsModel {
    public action: string;
    public userId: string;
    public timestamp: Date;
    public payload?: any;
}
