import { on } from '@ngrx/store';
import * as OrderHistoryActions from '../actions/admin-order-history.actions';
import { CartState } from './cart.reducer';

export const orderHistoryReducers = [
    // Order History Actions
    on(OrderHistoryActions.loadOrders, (state: CartState) => ({
        ...state,
        loading: true,
        error: null
    })),

    on(OrderHistoryActions.loadOrdersSuccess, (state: CartState, { orders }) => {
        return {
            ...state,
            ordersHistoryResponse: { ...orders },
            loading: false,
            error: null
        };
    }),

    on(OrderHistoryActions.loadOrdersFailure, (state: CartState, { error }) => ({
        ...state,
        loading: false,
        error
    })),

    on(OrderHistoryActions.exportOrdersHistory, (state: CartState) => ({
        ...state,
        error: null
    })),

    on(OrderHistoryActions.exportOrdersHistorySuccess, (state: CartState) => ({
        ...state,
        success: true,
        error: null
    })),

    on(OrderHistoryActions.exportOrdersHistoryFailure, (state: CartState, { error }) => ({
        ...state,
        error
    })),

    on(OrderHistoryActions.clearOrderHistoryState, (state: CartState) => ({
        ...state,
        ordersHistoryResponse: null,
        loading: false,
        error: null,
    })),
];
