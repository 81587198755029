import { Component, Injector, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import moment from 'moment';
import { take } from 'rxjs/operators';
import {
    AnalyticsReportRequest,
    AdminReportProvider,
    GoodsBrandDetails,
    PageDetails,
    FraudDetetionLimitsDetails,
    TodayPurchasingAmountOfUsersDetails,
    ProductsLimitDetails,
    TodayPurchasingAmountOfproductLines,
    UsersDetails,
    Sessions,
} from '@modeso/dgoods-lib-admin-fe';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { ExportType, ExcelOptions } from 'mat-table-exporter';

import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';

@Component({ selector: 'app-page-reports', templateUrl: './reports.page.html', styleUrls: ['./reports.page.scss'] })
export class ReportsPage extends BasePageComponent implements OnInit {
    kpis = ['Goods', 'Users', 'Blocked', 'Sessions', 'Views', 'Sales', 'Terminals'];
    timeFrames = ['Hour', 'Day', 'Week', 'Month', 'Year', 'All'];
    selectedKpi: string;
    selectedDate: '';
    selectedTimeFrame: string;
    selectedItem: string;
    kpiControl = new FormControl('', Validators.required);
    timeFrameControl = new FormControl('', Validators.required);
    tableHeaders: string[];
    isGoods = false;
    isSales = false;
    isSessions = false;
    isUsers = false;
    isBlocked = false;
    isViews = false;
    isTerminal = false;
    isServerError = false;
    isBadRequest = false;
    checkBoxIsSelected = false;
    serverError = 'Server Error';
    sessionSummary = 'Summary';
    badRequestErrorMessage: string;
    exportType = ExportType.XLSX;
    exportViewsOptions: ExcelOptions = { fileName: 'Views', sheet: 'Views', columnWidths: undefined };
    exportSessionsOptions: ExcelOptions = { fileName: 'Sessions', sheet: 'Sessions', columnWidths: undefined };
    exportUsersOptions: ExcelOptions = { fileName: 'Users', sheet: 'Users', columnWidths: undefined };
    exportSalesOptions: ExcelOptions = { fileName: 'Sales', sheet: 'Sales', columnWidths: undefined };
    exportGoodsOptions: ExcelOptions = { fileName: 'Goods', sheet: 'Goods', columnWidths: undefined };
    exportTerminalsOptions: ExcelOptions = { fileName: 'Terminals', sheet: 'Terminals', columnWidths: undefined };

    goods: GoodsBrandDetails[] = [];
    sales: any[] = [];
    users: UsersDetails[] = [];
    views: PageDetails[] = [];
    sessions: Sessions[] = [];
    terminals: any[] = [];
    blockedProductsAnalytics: string[] = [];
    blockedShopAnalytics: string[] = [];
    fraudDetectionLimits: FraudDetetionLimitsDetails[] = [];
    totalAmountOfTodayPurchasing: number;
    todayPurchasingAmountOfproductLines: TodayPurchasingAmountOfproductLines[] = [];
    todayPurchasingAmountOfUsers: TodayPurchasingAmountOfUsersDetails[] = [];
    productsLimit: ProductsLimitDetails[] = [];

    // tables' columns
    goodsAndSessionsDisplayedColumns: string[] = [
        'ID',
        'Terminal Id',
        'Total Orders',
        'Cancel by shop',
        'Order success',
        'Use as gift',
        'Used personal',
        'Is own amount',
        'Is predfined value',
        'Sold Total',
        'Sold Average',
        'Discounted',
        'Total Discounts',
    ];
    salesDisplayColums: string[] = [
        'Reference',
        'Terminal',
        'amount',
        'User token',
        'productConfig',
        'createdAt',
        'retrievalReferenceNumber',
        'messageId',
        'Coupon Name',
        'Coupon Code',
        'Coupon Value',
        'Amount Coupon',
    ];

    usersDisplayedColumns: string[] = [
        'ID',
        'Terminal',
        'User token',
        'User Issuer',
        'Created at',
        'Updated at',
        'Email',
        'Twint T&C',
    ];
    viewsDisplayedColumns: string[] = ['ID', 'Terminal Id', 'Total visits', 'Unique users', 'Average per person'];
    sessionsDisplayColumns: string[] = ['Terminal', 'Count of Sessions'];

    blockedProductsAnalyticsColumns: string[] = ['Blocked Products Analytics'];
    blockedShopAnalyticsColumns: string[] = ['Blocked Shop Analytics'];
    fraudDetectionLimitsColumns: string[] = ['Name', 'Value', 'Time frame name', 'Time frame value'];
    todayPurchasingAmountOfproductLinesColumns: string[] = ['ID', 'Terminal', 'Product ID', 'Amount', 'Count'];
    todayPurchasingAmountOfUsersColumns: string[] = ['ID', 'Amount', 'Terminal', 'Count'];
    productsLimitcolumns: string[] = ['Product', 'Status', 'Limits'];
    terminalColumns: string[] = [
        'Terminal',
        'Checkins',
        'Total Checkouts',
        'SuccessFul CheckOuts',
        'Failed CheckOuts',
        'Total Amount of CheckOuts',
        'Conversion Rate',
        'Drop Off Rate',
    ];

    @ViewChild('timePicker', { static: false }) timePicker: ElementRef;
    @ViewChild('goodsPaginator', { static: false }) goodsPaginator: MatPaginator;
    @ViewChild('terminalPaginator', { static: false }) terminalPaginator: MatPaginator;
    @ViewChild('salesPaginator', { static: false }) salesPaginator: MatPaginator;
    @ViewChild('usersPaginator', { static: false }) usersPaginator: MatPaginator;
    @ViewChild('viewsPaginator', { static: false }) viewsPaginator: MatPaginator;

    @ViewChild('blockedUsersPaginator', { static: false }) blockedUsersPaginator: MatPaginator;
    @ViewChild('blockedProductsPaginator', { static: false }) blockedProductsPaginator: MatPaginator;
    @ViewChild('blockedShopPaginator', { static: false }) blockedShopPaginator: MatPaginator;
    @ViewChild('fraudPaginator', { static: false }) fraudPaginator: MatPaginator;
    @ViewChild('todayPurchasingProductPaginator', { static: false }) todayPurchasingProductPaginator: MatPaginator;
    @ViewChild('todayPurchasingUsersPaginator', { static: false }) todayPurchasingUsersPaginator: MatPaginator;
    @ViewChild('sessionsPaginator', { static: false }) sessionsPaginator: MatPaginator;
    @ViewChild('productLimitPaginator', { static: false }) productLimitPaginator: MatPaginator;

    // tables' data sources intialization
    goodsDataSource = new MatTableDataSource<GoodsBrandDetails>(this.goods);
    salesDataSource = new MatTableDataSource<any>(this.sales);
    usersDataSource = new MatTableDataSource<UsersDetails>(this.users);
    viewsDataSource = new MatTableDataSource<PageDetails>(this.views);
    sessionsDataSource: MatTableDataSource<Sessions>;

    terminalsDataSource = new MatTableDataSource<any>(this.terminals);
    blockedProductsAnalyticsDataSource = new MatTableDataSource<string>(this.blockedProductsAnalytics);
    blockedShopAnalyticsDataSource = new MatTableDataSource<string>(this.blockedShopAnalytics);
    fraudDetectionLimitsDataSource = new MatTableDataSource<FraudDetetionLimitsDetails>(this.fraudDetectionLimits);
    // tslint:disable-next-line: max-line-length
    todayPurchasingAmountOfproductLinesDataSource = new MatTableDataSource<TodayPurchasingAmountOfproductLines>(
        this.todayPurchasingAmountOfproductLines,
    );
    todayPurchasingAmountOfUsersDataSource = new MatTableDataSource<TodayPurchasingAmountOfUsersDetails>(
        this.todayPurchasingAmountOfUsers,
    );
    productsLimitDataSource = new MatTableDataSource<ProductsLimitDetails>(this.productsLimit);

    constructor(
        injector: Injector,
        private adminReportProvider: AdminReportProvider,
        private changeDetector: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }
    getSelectedKpi(event: MatSelectChange) {
        this.selectedKpi = event.value;
    }

    getSelectedTimeFilter(event: MatSelectChange) {
        this.selectedTimeFrame = event.value;
    }

    getDateAsString() {
        if (!this.selectedDate) {
            return null;
        }
        return moment(this.selectedDate).format('YYYY-MM-DD');
    }

    getSelectedHour() {
        return this.timePicker.nativeElement.value;
    }
    getDate() {
        const getSelectedDate = this.getDateAsString();

        if (!getSelectedDate) {
            return 'now';
        }
        const selectedTime = this.getSelectedHour();
        if (!selectedTime) {
            return getSelectedDate;
        }
        return getSelectedDate + '-' + selectedTime;
    }

    buttonStatus() {
        return this.kpiControl.invalid || this.timeFrameControl.invalid;
    }

    onChecked(checked) {
        this.checkBoxIsSelected = checked;
    }
    onSearch() {
        if (this.kpiControl.invalid || this.timeFrameControl.invalid) {
            return;
        }

        const reportRequest: AnalyticsReportRequest = {
            kpi: this.selectedKpi,
            timePeriod: this.selectedTimeFrame,
            date: this.getDate(),
            isCouponOrders: this.checkBoxIsSelected,
        };

        this.subscriptions.push(
            this.adminReportProvider
                .getReport$(reportRequest)
                .pipe(take(2))
                .subscribe((adminReportResponse: any) => {
                    if (reportRequest.kpi === 'Goods') {
                        this.goods = [];

                        if (adminReportResponse && adminReportResponse.brands) {
                            this.goods = adminReportResponse.brands;
                            const summary = { ...adminReportResponse.summary, _id: 'Summary' };
                            this.goods = [...this.goods, summary];
                            this.isSessions = false;
                            this.isUsers = false;
                            this.isBlocked = false;
                            this.isViews = false;
                            this.isServerError = false;
                            this.isBadRequest = false;
                            this.isGoods = true;
                            this.isSales = false;
                            this.isTerminal = false;
                            this.goodsDataSource = new MatTableDataSource<GoodsBrandDetails>(this.goods);
                            this.changeDetector.detectChanges();
                            this.goodsDataSource.paginator = this.goodsPaginator;
                        }
                    }
                    if (reportRequest.kpi === 'Terminals') {
                        if (!adminReportResponse) {
                            return;
                        }
                        if (Object.keys(adminReportResponse).length !== 0) {
                            this.terminals = adminReportResponse.data;
                            this.isSessions = false;
                            this.isUsers = false;
                            this.isBlocked = false;
                            this.isViews = false;
                            this.isServerError = false;
                            this.isBadRequest = false;
                            this.isGoods = false;
                            this.isTerminal = true;
                            this.isSales = false;
                            this.terminalsDataSource = new MatTableDataSource<any>(this.terminals);
                            this.changeDetector.detectChanges();
                            this.terminalsDataSource.paginator = this.terminalPaginator;
                        }
                    }

                    if (reportRequest.kpi === 'Sessions') {
                        if (!adminReportResponse) {
                            return;
                        }
                        this.sessions = adminReportResponse.data;

                        this.isUsers = false;
                        this.isBlocked = false;
                        this.isViews = false;
                        this.isServerError = false;
                        this.isBadRequest = false;
                        this.isGoods = false;
                        this.isSales = false;
                        this.isSessions = true;
                        this.isTerminal = false;
                        this.changeDetector.detectChanges();
                        this.sessionsDataSource = new MatTableDataSource<Sessions>(this.sessions);
                        this.sessionsDataSource.paginator = this.sessionsPaginator;
                    }

                    if (reportRequest.kpi === 'Users') {
                        this.users = [];
                        if (adminReportResponse) {
                            if (!adminReportResponse.result) {
                                this.users = [];
                            } else {
                                this.users = adminReportResponse.result;
                                const userSummary: UsersDetails = new UsersDetails();
                                userSummary._id = 'Summary';
                                userSummary.userToken = adminReportResponse.summary.newUsers;
                                userSummary.userIssuer = '';
                                userSummary.createdAt = '';
                                userSummary.updatedAt = '';
                                userSummary.email = adminReportResponse.summary.emailUsers;
                                userSummary.twintTC = adminReportResponse.summary.twintTcUsers;
                                this.users = [...this.users, userSummary];
                            }
                            this.isSessions = false;
                            this.isGoods = false;
                            this.isSales = false;
                            this.isBlocked = false;
                            this.isViews = false;
                            this.isServerError = false;
                            this.isBadRequest = false;
                            this.isUsers = true;
                            this.isTerminal = false;
                            this.usersDataSource = new MatTableDataSource<UsersDetails>(this.users);
                            this.changeDetector.detectChanges();
                            this.usersDataSource.paginator = this.usersPaginator;
                        }
                    }

                    if (reportRequest.kpi === 'Views') {
                        if (adminReportResponse && adminReportResponse.pages) {
                            this.views = [];

                            this.views = adminReportResponse.pages;
                            const summary: PageDetails = new PageDetails();
                            summary._id = 'Summary';
                            summary.totalVisits = adminReportResponse.summary.totalVisits;
                            summary.uniqueUsers = adminReportResponse.summary.nrOfUniqueUsers;
                            summary.avgPerUser = adminReportResponse.summary.avgPerUser;
                            this.views = [...this.views, summary];
                            this.isSessions = false;
                            this.isGoods = false;
                            this.isSales = false;
                            this.isUsers = false;
                            this.isBlocked = false;
                            this.isViews = true;
                            this.isServerError = false;
                            this.isBadRequest = false;
                            this.isTerminal = false;
                            this.viewsDataSource = new MatTableDataSource<PageDetails>(this.views);
                            this.changeDetector.detectChanges();
                            this.viewsDataSource.paginator = this.viewsPaginator;
                        }
                    }

                    if (reportRequest.kpi === 'Blocked') {
                        if (adminReportResponse && adminReportResponse.fraudDetectionLimits) {
                            this.blockedProductsAnalytics = adminReportResponse.blockedProductsAnalytics;
                            this.blockedShopAnalytics = adminReportResponse.blockedShopAnalytics;
                            this.fraudDetectionLimits = adminReportResponse.fraudDetectionLimits;
                            this.totalAmountOfTodayPurchasing = adminReportResponse.totalAmountOfTodayPurchasing;
                            this.todayPurchasingAmountOfproductLines =
                                adminReportResponse.todayPurchasingAmountOfproductLines;
                            this.todayPurchasingAmountOfUsers = adminReportResponse.todayPurchasingAmountOfUsers;
                            this.productsLimit = adminReportResponse.productsLimit;
                            this.isSessions = false;
                            this.isGoods = false;
                            this.isSales = false;
                            this.isUsers = false;
                            this.isBlocked = true;
                            this.isViews = false;
                            this.isTerminal = false;
                            this.isServerError = false;
                            this.isBadRequest = false;
                            this.blockedProductsAnalyticsDataSource = new MatTableDataSource<string>(
                                this.blockedProductsAnalytics,
                            );
                            this.blockedShopAnalyticsDataSource = new MatTableDataSource<string>(
                                this.blockedShopAnalytics,
                            );
                            this.fraudDetectionLimitsDataSource = new MatTableDataSource<FraudDetetionLimitsDetails>(
                                this.fraudDetectionLimits,
                            );
                            // tslint:disable-next-line: max-line-length
                            this.todayPurchasingAmountOfproductLinesDataSource =
                                new MatTableDataSource<TodayPurchasingAmountOfproductLines>(
                                    this.todayPurchasingAmountOfproductLines,
                                );
                            // tslint:disable-next-line: max-line-length
                            this.todayPurchasingAmountOfUsersDataSource =
                                new MatTableDataSource<TodayPurchasingAmountOfUsersDetails>(
                                    this.todayPurchasingAmountOfUsers,
                                );
                            this.productsLimitDataSource = new MatTableDataSource<ProductsLimitDetails>(
                                this.productsLimit,
                            );

                            this.changeDetector.detectChanges();
                            this.blockedProductsAnalyticsDataSource.paginator = this.blockedProductsPaginator;
                            this.blockedShopAnalyticsDataSource.paginator = this.blockedShopPaginator;
                            this.fraudDetectionLimitsDataSource.paginator = this.fraudPaginator;
                            this.todayPurchasingAmountOfproductLinesDataSource.paginator =
                                this.todayPurchasingProductPaginator;
                            this.todayPurchasingAmountOfUsersDataSource.paginator = this.todayPurchasingUsersPaginator;
                            this.productsLimitDataSource.paginator = this.productLimitPaginator;
                        }
                    }

                    if (reportRequest.kpi === 'Sales') {
                        this.sales = [];

                        if (adminReportResponse) {
                            this.sales = adminReportResponse.data;
                            this.isSessions = false;
                            this.isUsers = false;
                            this.isBlocked = false;
                            this.isViews = false;
                            this.isServerError = false;
                            this.isTerminal = false;
                            this.isBadRequest = false;
                            this.isGoods = false;
                            this.isSales = true;
                            this.salesDataSource = new MatTableDataSource<any>(this.sales);
                            this.changeDetector.detectChanges();
                            this.salesDataSource.paginator = this.salesPaginator;
                        }
                    }
                }),
        );

        this.subscriptions.push(
            this.adminReportProvider.getError$().subscribe((errorResponse: any) => {
                if (errorResponse) {
                    if (errorResponse.status.toString() === '400') {
                        this.isServerError = false;
                        this.isBadRequest = true;
                        this.badRequestErrorMessage = errorResponse.error.error;
                    } else {
                        this.isServerError = true;
                        this.isBadRequest = false;
                    }
                }
            }),
        );
    }

    createExcelWorkSheet(data: any[], headers: string[]) {
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(workSheet, [headers]);
        XLSX.utils.sheet_add_json(workSheet, data, { origin: 'A2', skipHeader: true });
        return workSheet;
    }

    onExportBlockedToExcel() {
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();

        // Blocked Products Analytics
        const blockedProductsAnalyticsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.blockedProductsAnalytics.map((item) => ({ value: item })),
            this.blockedProductsAnalyticsColumns,
        );
        XLSX.utils.book_append_sheet(workBook, blockedProductsAnalyticsWorkSheet, 'BlockedProductsAnalytics');

        // Blocked Shop Analytics
        const blockedShopAnalyticsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.blockedShopAnalytics.map((item) => ({ value: item })),
            this.blockedShopAnalyticsColumns,
        );
        XLSX.utils.book_append_sheet(workBook, blockedShopAnalyticsWorkSheet, 'BlockedShopAnalytics');

        // Fraud Limits
        const fraudLimitsWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.fraudDetectionLimits,
            this.fraudDetectionLimitsColumns,
        );
        XLSX.utils.book_append_sheet(workBook, fraudLimitsWorkSheet, 'FraudLimits');

        // Today Purchasing Amount of Product Lines
        const todayPurchasingAmountOfProductLinesWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.todayPurchasingAmountOfproductLines.map((item) => ({
                ...item,
                productLineEntityId: item.productLineEntityId[0],
            })),
            this.todayPurchasingAmountOfproductLinesColumns,
        );
        XLSX.utils.book_append_sheet(
            workBook,
            todayPurchasingAmountOfProductLinesWorkSheet,
            'TodayPurchasingOfProdcutLines',
        );

        // Today Purchasing Amount of Users
        const todayPurchasingAmountOfUsersWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.todayPurchasingAmountOfUsers,
            this.todayPurchasingAmountOfUsersColumns,
        );
        XLSX.utils.book_append_sheet(workBook, todayPurchasingAmountOfUsersWorkSheet, 'TodayPurchasingOfUsers');

        // Products Limit
        const productsLimitWorkSheet: XLSX.WorkSheet = this.createExcelWorkSheet(
            this.productsLimit,
            this.productsLimitcolumns,
        );
        XLSX.utils.book_append_sheet(workBook, productsLimitWorkSheet, 'ProductsLimit');

        XLSX.writeFile(workBook, 'Blocked.xlsx');
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_REPORTING_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
