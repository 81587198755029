<ng-template #noPermission>
    <p>Sorry you don't have read permission.</p>
</ng-template>

<div class="main" *ngIf="hasPermission()" else noPermission>
    <h4>Orders Reports</h4>

    <form [formGroup]="filterForm" class="filters">
        <mat-form-field>
            <mat-label>Merchant</mat-label>
            <mat-select formControlName="merchant">
                <mat-option> -- None -- </mat-option>
                <mat-option *ngFor="let merchant of (merchants$ | async)" [value]="merchant.type">
                    {{ merchant.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Product Line</mat-label>
            <mat-select formControlName="productLine">
                <mat-option> -- None -- </mat-option>
                <mat-option *ngFor="let productLine of (allProductLineNames$ | async)" [value]="productLine">
                    {{ productLine }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Order Status</mat-label>
            <mat-select formControlName="orderStatus" multiple>
                <mat-option *ngFor="let status of orderStatusesSelectionValues" [value]="status">
                    {{ orderStatusesSelectionMap.get(status) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a start date"
                formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSeconds]="showSeconds" [defaultTime]="defaultTime">
                <ngx-mat-datepicker-actions>
                    <button mat-button ngxMatDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
            <mat-error *ngIf="startDateFormControl.errors?.required && startDateFormControl.touched">
                Start date is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>End Date</mat-label>
            <input matInput [ngxMatDatetimePicker]="endpicker" placeholder="Choose an end date"
                formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endpicker [showSeconds]="showSeconds" [defaultTime]="defaultTime">
                <ngx-mat-datepicker-actions>
                    <button mat-button ngxMatDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
            <mat-error *ngIf="endDateFormControl.errors?.required && endDateFormControl.touched">
                End date is required
            </mat-error>
        </mat-form-field>

        <!-- Error messages outside mat-form-field -->

        <div class="checkbox">
            <mat-checkbox formControlName="includeCoupons"> Show only Orders with Coupons </mat-checkbox>
        </div>
        <div class="validation-errors" *ngIf="filterForm.errors?.['dateRange'] && filterForm.touched">
            <div class="error-message" *ngFor="let error of filterForm.errors?.['dateRange']">{{ error }}</div>
        </div>
    </form>

    <div class="search-button">
        <div class="search-container">
            <div class="search-button">
                <button id="saveAddressButton" [disabled]="filterForm.invalid" (click)="loadOrders()">Search</button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="loading$ | async">
        <div class="spinner">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>

    <div class="table-container" *ngIf="!isSearchButtonPressed || (orderReportResponse$ | async)?.totalOrdersCount > 0">
        <mat-table [dataSource]="orderReportOrdersDataSource$ | async" class="mat-elevation-z8">
            <ng-container *ngFor="let columnKey of displayedColumnsKeys" [matColumnDef]="columnKey">
                <mat-header-cell *matHeaderCellDef> {{ displayedColumns[columnKey] }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element[columnKey] }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsKeys"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsKeys;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [length]="(orderReportResponse$ | async)?.totalOrdersCount" [pageSize]="pageSize"
            [pageIndex]="currentPage" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
            (page)="pageChanged($event)"></mat-paginator>
        <div class="export-excel-button">
            <button [disabled]="(orderReportResponse$ | async)?.totalOrdersCount === null"
                [ngClass]="(orderReportResponse$ | async)?.totalOrdersCount === null? 'disabled' : ''"
                (click)="exportToExcel()">Download</button>
            <div *ngIf="success$ | async" class="success-msg">Your request is in progress. You will receive an E-mail
                once its done.</div>
        </div>
    </div>
    <p *ngIf="(orderReportResponse$ | async)?.totalOrdersCount === 0" class="no-data">
        No records match your search criteria.
    </p>
</div>