import { Component, Injector, OnInit } from '@angular/core';
import { AdminManageProductContentProvider, ManageProductsResponse } from '@modeso/dgoods-lib-admin-fe';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AdminManageProductCategoryProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-manageProductCategories.provider';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { EditCategoryDialogComponent } from './edit-category-dialog.component';
import { CategoryDto } from '@modeso/types__dgoods-products';
import { getSvgPath } from '../../shared/models/icons';
import { CreateUpdateCategoryRequestModel } from '@modeso/dgoods-lib-admin-fe/models/manageProductCategories/categories.models';
import { ConfirmationDialogComponent } from '../Dialogs/confirmation-dialog.component';

const debug = Debug('modeso:dgoods-admin:ProductCategoriesPage');

interface Category extends CategoryDto {
    name: string;
    products: Product[];
}

interface Product {
    name: string;
    categories: CategoryDto[];
}

export interface ProductWithCategory {
    productLineName: string;
    category: string;
}

@Component({
    selector: 'app-product-categories',
    templateUrl: './productCategories.page.html',
    styleUrls: ['./productCategories.page.scss'],
})
export class ProductCategoriesPage extends BasePageComponent implements OnInit {
    products: Product[] = [];
    categories: Category[] = [];

    constructor(
        injector: Injector,
        private adminManageProductProvider: AdminManageProductContentProvider,
        private adminManageCategoryProvider: AdminManageProductCategoryProvider,
        private localStorageService: LocalStorageService,
        public dialog: MatDialog,
    ) {
        super(injector);
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            // Check if the category being dropped already exists in the product's categories array
            const categoryToAdd: CategoryDto = event.previousContainer.data[
                event.previousIndex
            ] as unknown as CategoryDto;
            //const product = this.products[event.currentIndex];

            const productId = event.container.id;
            const product = this.products.find((p) => p.name === productId);
            debug('drop', categoryToAdd, productId);
            if (!product.categories.includes(categoryToAdd)) {
                // If category does not exist, add it to the product's categories array

                product.categories.push(categoryToAdd);
                //debug(product)
                this.updateProductCategories(product);
            } else {
                // If category already exists, prevent the drop
                return;
            }
        }
    }

    removeCategory(product: Product, category: CategoryDto) {
        debugger;
        const index = product.categories.indexOf(category);

        product.categories.splice(index, 1);

        this.updateProductCategories(product);
    }

    getIconSvg(iconName: string): string {
        return getSvgPath(iconName);
    }

    editCategory(category: Category): void {
        // Open a dialog or overlay with a form for editing the category properties
        debug('editCat', category);
        const target: CreateUpdateCategoryRequestModel = {
            _id: category._id,
            icon: category.icon,
            text: {
                'de-ch': category.text['de-ch'],
                'fr-ch': category.text['fr-ch'],
                'it-ch': category.text['it-ch'],
                'en-us': category.text['en-us'],
            },
            orderNumber: category.orderNumber,
        };
        const dialogRef = this.dialog.open(EditCategoryDialogComponent, { width: '400px', data: target });
        debug(target);
        // After the dialog is closed, update the category if the user saved the changes
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.updateCategory(result);
            }
        });
    }

    addCategory(): void {
        // Open a dialog or overlay with a form for editing the category properties
        debug('addCategory');
        const category: CreateUpdateCategoryRequestModel = {
            icon: 'gaming',
            text: { 'de-ch': 'UNDEFINED', 'fr-ch': 'UNDEFINED', 'it-ch': 'UNDEFINED', 'en-us': 'UNDEFINED' },
            orderNumber: 90,
        };
        const dialogRef = this.dialog.open(EditCategoryDialogComponent, { width: '400px', data: category });

        // After the dialog is closed, update the category if the user saved the changes
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.createNewCategory(result);
            }
        });
    }

    categoryPredicate(item: any, container: any) {
        return (
            item.data
            && item.data.category == null
            && container.element.nativeElement.classList.contains('product-list-item')
        );
    }

    ngOnInit() {
        this.getAllCategoires();
        this.getAllProducts();
        this.initErrors();
    }

    getAllProducts() {
        this.subscriptions.push(
            this.adminManageProductProvider.getAllProducts$().subscribe((response: ManageProductsResponse) => {
                this.products = [];
                if (response.products) {
                    response.products.forEach((element) => {
                        const { categories } = element;
                        const categoriesMapped = categories.map((cat) => {
                            return { ...cat, name: cat.text['en-us'] };
                        });
                        const product: Product = { name: element.productLineName, categories: [...categoriesMapped] };
                        this.products.push(product);
                    });
                    debug('getAllProducts: ', this.products);
                }
            }),
        );
    }
    getAllCategoires() {
        this.subscriptions.push(
            this.adminManageCategoryProvider.getAllCagetories$().subscribe((response: any) => {
                debug(response);
                if (response) {
                    this.categories = [];
                    response.forEach((element) => {
                        const category: Category = { ...element, name: element.text['en-us'], products: [] };
                        this.categories.push(category);
                    });
                } else {
                    this.categories = [];
                }
            }),
        );
    }

    initErrors() {
        this.subscriptions.push(
            this.adminManageProductProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {
                if (errorResponse) {
                    alert("can't fetch the products");
                }
            }),
        );
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    openDeleteConfirmationDialog(category: Category): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: { message: 'Are you sure you want to delete this category?', category: category },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // User confirmed deletion, perform deletion logic here
                this.deleteCategory(category);
            }
        });
    }

    //** Actions */
    saveProductCategories() {
        alert('Not Implemented');
    }

    createNewCategory(category: CreateUpdateCategoryRequestModel) {
        this.adminManageCategoryProvider.createCategory(category);
    }

    updateCategory(category: CreateUpdateCategoryRequestModel) {
        this.adminManageCategoryProvider.updateCategory(category);
    }

    deleteCategory(category: Category) {
        console.log(category);
        this.adminManageCategoryProvider.deleteCategory(category._id);
    }

    updateProductCategories(product: Product) {
        console.log('Updateeeee');
        debug('updateProductCategories', product);
        const categories = product.categories.map((cat) => cat._id);
        this.adminManageProductProvider.updateProductCategories(product.name, categories);
    }
}
