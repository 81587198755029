<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
<div class="container" *ngIf="hasReadPermission()">
    <div class="row">
        <div class="table-container">
            <div>
                <mat-form-field style="width: 270px">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Username" />
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="dataSource">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell fxFlex="100px" *matHeaderCellDef></th>
                    <td mat-cell fxFlex="100px" *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Key Column -->
                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef>Username</th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <!-- de-ch Column -->
                <ng-container matColumnDef="Role">
                    <th mat-header-cell *matHeaderCellDef>Role</th>
                    <td mat-cell *matCellDef="let element">{{element.role}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="manageColumns;  sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: manageColumns;" (click)="selection.toggle(row)"></tr>
            </table>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

    <div class="row">
        <div class="messages">
            <span class="failure" *ngIf="isError">{{errorMessage}}</span>
            <span class="failure" *ngIf="isServerError">{{serverErrorMessage}}</span>
        </div>

        <div class="action-button" *ngIf="hasWritePermission()">
            <button id="updateUserButton" [disabled]="updateButtonStatus()" (click)="onUpdateUser()">Update</button>
            <button id="deleteUserButton" [disabled]="updateButtonStatus()" (click)="onDeleteUser()">Delete</button>
        </div>
    </div>
</div>
