<div *ngIf="hasReadPermission()">
    <app-add-coupon-component
        [whiteListedProducts]="whiteListedProducts"
        [couponId]="couponId"
        [adminUsers$]="adminUsers$"
        [productsObservable$]="productsSubject"
        (redirectToList)="navigateToCouponList()"
    ></app-add-coupon-component>
</div>

<div *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
