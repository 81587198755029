<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
<div *ngIf="hasReadPermission()">
    <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let link of navLinks" (click)="activeLink = link.link" [routerLink]="[link.link]">
            {{link.label}}
        </a>
    </nav>
</div>
<div>
    <router-outlet></router-outlet>
</div>
