<div class="dgoods-tutorial-element-container">
    <div class="icon">
        <div class="image">
            <img [src]="tutorial.image" />
        </div>
    </div>

    <div class="content">
        <div class="title">
            <span>{{ tutorial.number }})</span> {{ tutorial.title | injectedText | async }}
        </div>
        <div class="description">
            {{ tutorial.description | injectedText | async }}
        </div>
    </div>
</div>
