import { HttpErrorResponse } from '@angular/common/http';
import { ExportOrdersHistory, OrderHistoryResponse, OrdersHistoryRequest } from '@modeso/types__dgoods-cart';
import { createAction, props } from '@ngrx/store';

export const loadOrders = createAction('[Order Reports] Load Orders', props<OrdersHistoryRequest>());
export const loadOrdersSuccess = createAction(
    '[Order Reports] Load Orders Success',
    props<{ orders: OrderHistoryResponse }>(),
);
export const loadOrdersFailure = createAction(
    '[Order Reports] Load Orders Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const exportOrdersHistory = createAction('[Order Reports] Export Orders Report', props<ExportOrdersHistory>());
export const exportOrdersHistorySuccess = createAction('[Order Reports] Export Orders Report Success');
export const exportOrdersHistoryFailure = createAction(
    '[Order Reports] Export Orders Report Failure',
    props<{ error: HttpErrorResponse }>(),
);

export const clearOrderHistoryState = createAction('[Order Reports] Clear Order History State');
