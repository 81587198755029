<ng-container>
    <button style="margin: 1.5rem auto" class="button-greenish-color" (click)="createContentBox()">
        Create Content Box
    </button>

    <div *ngIf="contentBoxes">
        <table mat-table [dataSource]="contentBoxes" class="mat-elevation-z8 table">
            <ng-container matColumnDef="productlineId">
                <th class="centered" mat-header-cell *matHeaderCellDef>ProductLine</th>
                <td class="centered" mat-cell *matCellDef="let contentBox">{{ contentBox.productlineId }}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th class="centered" mat-header-cell *matHeaderCellDef>Title</th>
                <td class="centered" mat-cell *matCellDef="let contentBox">{{ contentBox.title.en_us }}</td>
            </ng-container>

            <ng-container matColumnDef="content">
                <th class="centered" mat-header-cell *matHeaderCellDef>Content</th>
                <td class="centered" mat-cell *matCellDef="let contentBox">
                    {{ contentBox.content.en_us | slice:0:20 }}{{ contentBox.content.en_us.length > 20 ? '...' : '' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="published">
                <th class="centered" mat-header-cell *matHeaderCellDef>Published</th>
                <td class="centered" mat-cell *matCellDef="let contentBox">
                    {{ contentBox.published ? 'Yes' : 'No' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="centered" mat-header-cell *matHeaderCellDef>Actions</th>
                <td class="centered" mat-cell *matCellDef="let contentBox">
                    <button
                        class="button-blue-color"
                        (click)="editContentBox(contentBox)"
                        [disabled]="contentBox.published"
                    >
                        Edit
                    </button>
                    <button
                        class="button-blue-color fixed-width"
                        (click)="togglePublish(contentBox)"
                        [disabled]="checkNotPublishable(contentBox)"
                    >
                        {{ contentBox.published ? 'Unpublish' : 'Publish' }}
                    </button>
                    <button
                        class="button-blue-color"
                        (click)="deleteContentBox(contentBox)"
                        [disabled]="contentBox.published"
                    >
                        Delete
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>
