import { Component, Input } from '@angular/core';

@Component({
    selector: 'dgoods-title-with-content',
    templateUrl: './dgoods-title-with-content.component.html',
    styleUrls: ['./dgoods-title-with-content.component.scss'],
})
export class DgoodsTitleWithContentComponent {
    @Input() title: string;
    constructor() {}
}
