<div class="container" *ngIf="!userHasReadPermissions">Sorry you don't have read permission.</div>

<div class="container" *ngIf="userHasReadPermissions">
    <div class="my-alert my-alert-danger" *ngIf="error">
        <span class="display-message"> {{errorMessage}} </span>
        <button class="close-btn" (click)="resetErrorMessage()"><mat-icon>cancel</mat-icon></button>
    </div>

    <div class="my-alert my-alert-success" *ngIf="success">
        <span class="display-message"> {{successMessage}} </span>
        <button class="close-btn" (click)="resetSuccessMessage()"><mat-icon>cancel</mat-icon></button>
    </div>

    <ng-container *ngIf="getAllProducts$ | async as data">
        <h3 class="available-products-title">Public available Products</h3>
        <button type="button" (click)="saveOrder(data.whiteListed)">Save Order</button>

        <button *ngIf="userHasWritePermissions" style="margin-left: 100px" (click)="synchronizeProducts()">
            Synchronize Products
        </button>

        <div class="table-layout" *ngFor="let whiteListedProduct of data.whiteListed;let id=index" id="id{{id}}">
            <table class="table table-striped table-bordered table-hover table-flex-style">
                <thead>
                    <tr>
                        <th scope="col">
                            {{whiteListedProduct.productLineName}} ({{ whiteListedProduct.merchantType }})
                        </th>
                        <th scope="col">Locale</th>
                        <th scope="col">Available Prices</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let availablePrice of whiteListedProduct.availablePrices; let availablePricesId=index">
                        <th scope="row">{{availablePricesId}}</th>
                        <td>{{availablePrice.locale}}</td>
                        <td>{{availablePrice.prices}}</td>
                    </tr>
                </tbody>

                <div class="buttons-container" *ngIf="userHasWritePermissions">
                    <button (click)="unWhiteListProduct(whiteListedProduct.productLineName)">Unpublish</button>
                    <button
                        [ngClass]="{'btn_configure-digital-voucher': true, hasConfig: whiteListedProduct.hasDigitalVocherConfig, hasNoConfig: !whiteListedProduct.hasDigitalVocherConfig}"
                        routerLink="../digitalvoucher/{{whiteListedProduct.productLineName}}/{{whiteListedProduct.merchantType}}"
                    >
                        Configure Digital Voucher
                    </button>
                </div>
            </table>

            <div class="div-flex-style">
                <div class="cursor-buttons-style">
                    <div (click)="sortProducts('top',id, data.whiteListed)">
                        <span class="material-symbols-outlined cursor-top">double_arrow</span>
                    </div>
                    <div (click)="sortProducts('up',id, data.whiteListed)">
                        <span class="material-icons cursor-top"> keyboard_arrow_right </span>
                    </div>
                    <div (click)="sortProducts('down',id, data.whiteListed)">
                        <span class="material-icons cursor-down"> keyboard_arrow_right </span>
                    </div>
                    <div (click)="sortProducts('bottom',id, data.whiteListed)">
                        <span class="material-symbols-outlined cursor-down">double_arrow</span>
                    </div>
                </div>
            </div>
        </div>

        <h3 id="test">Unpublished Products</h3>
        <table
            *ngFor="let unWhiteListedProduct of data.unWhiteListed let id=index"
            class="table table-striped table-bordered table-hover"
        >
            <thead>
                <tr>
                    <th scope="col">
                        {{unWhiteListedProduct.productLineName}} ({{ unWhiteListedProduct.merchantType }})
                    </th>
                    <th scope="col">Locale</th>
                    <th scope="col">Available Prices</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let availablePrice of unWhiteListedProduct.availablePrices; let availablePricesId=index">
                    <th scope="row">{{availablePricesId}}</th>
                    <td>{{availablePrice.locale}}</td>
                    <td>{{availablePrice.prices}}</td>
                </tr>
            </tbody>
            <div style="display: flex" *ngIf="userHasWritePermissions">
                <button
                    (click)="whiteListProduct(unWhiteListedProduct.productLineName)"
                    [disabled]="!unWhiteListedProduct.hasDigitalVocherConfig"
                >
                    Publish
                </button>
                <button
                    [ngClass]="{'btn_configure-digital-voucher': true, hasConfig: unWhiteListedProduct.hasDigitalVocherConfig, hasNoConfig: !unWhiteListedProduct.hasDigitalVocherConfig}"
                    routerLink="../digitalvoucher/{{unWhiteListedProduct.productLineName}}/{{unWhiteListedProduct.merchantType}}"
                >
                    Configure Digital Voucher
                </button>
            </div>
        </table>
    </ng-container>
</div>
