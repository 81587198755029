import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ParamMap, Router } from '@angular/router';
import { LoginUser, AdminProvider, UserResponse } from '@modeso/dgoods-lib-admin-fe';
import { filter, map, take } from 'rxjs/operators';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
@Component({ selector: 'app-page-login', templateUrl: './login.page.html', styleUrls: ['./login.page.scss'] })
export class LoginPage extends BasePageComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;

    validLogin = false;
    invalidUser = false;
    loginErrorMessage = 'User name or password is wrong!';
    serverError = 'Server Error';
    isLoginFailed = false;
    isServerError = false;
    isBadRequest = false;
    badRequestErrorMessage: string;
    loginWithSamlUrl = environment.modeso.apiGateway + 'admin/saml/login';
    showLoginForm = environment.allowLoginWithUsernameAndPassword;
    private queryParamSubscription: Subscription | undefined;

    constructor(
        injector: Injector,
        private router: Router,
        private adminProvider: AdminProvider,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.localStorageService.removeAuthorization();
        this.loginForm = new FormGroup({
            userName: new FormControl(null, { validators: [Validators.required] }),
            password: new FormControl(null, { validators: [Validators.required] }),
        });

        this.queryParamSubscription = this.activatedRoute.queryParamMap
            .pipe(
                map((params: ParamMap) => params.get('samlError')),
                filter((value) => value === 'true'),
            )
            .subscribe(() => {
                this.isLoginFailed = true;
                this.loginErrorMessage = 'Authentication Failed';
            });
    }

    login(): void {
        if (this.loginForm.invalid) {
            return;
        }

        const userData: LoginUser = { name: this.loginForm.value.userName, password: this.loginForm.value.password };

        this.subscriptions.push(
            this.adminProvider
                .login$(userData)
                .pipe(take(2))
                .subscribe((loginResponse: UserResponse) => {
                    if (loginResponse && loginResponse.user) {
                        this.isLoginFailed = false;
                        this.isServerError = false;
                        this.localStorageService.setAuthorization();
                        this.router.navigate(['/de-ch']);
                    } else {
                        this.adminProvider.getError$().subscribe((errorResponse: any) => {
                            if (errorResponse) {
                                if (errorResponse.status.toString() === '404') {
                                    this.isLoginFailed = true;
                                    this.isServerError = false;
                                    this.isBadRequest = false;
                                } else if (errorResponse.status.toString() === '400') {
                                    this.isLoginFailed = false;
                                    this.isBadRequest = true;
                                    this.isServerError = false;
                                    this.badRequestErrorMessage = errorResponse.error.error;
                                } else {
                                    this.isServerError = true;
                                    this.isLoginFailed = false;
                                    this.isBadRequest = false;
                                }
                            }
                        });
                    }
                }),
        );
    }

    buttonStatus() {
        return this.loginForm.invalid;
    }

    ngOnDestroy(): void {
        if (this.queryParamSubscription != null) {
            this.queryParamSubscription.unsubscribe();
        }
    }
}
