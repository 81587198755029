import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/admin-reports.action';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { AdminReportService } from '../services/admin-report.service';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminReportEffects');

@Injectable()
export class AdminReportEffects {
    constructor(
        private actions$: Actions,
        private service: AdminReportService,
    ) {}
    getReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getReport.type),
            mergeMap((payload) => {
                return this.service.getReport(payload).pipe(
                    map((response) => fromActions.onGetReportSuccessfully({ payload: response })),
                    catchError((error) => of(fromActions.onGetReportFailed({ payload: error }))),
                );
            }),
        ),
    );

    errorOnGetReport = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onGetReportFailed.type),
                tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)),
            ),
        { dispatch: false },
    );

    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}
