import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminContentBoxProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-content-box.provider';
import { BasePageComponent } from '../../base.page';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../Dialogs/confirmation-dialog.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-content-box-list',
    templateUrl: './content-boxes-list.page.html',
    styleUrls: ['./content-boxes-list.page.scss'],
})
export class ContentBoxListComponent extends BasePageComponent implements OnInit {
    contentBoxes: IContentBoxResponse[];

    displayedColumns: string[] = ['productlineId', 'title', 'content', 'actions'];

    constructor(
        private router: Router,
        private contentBoxProvider: AdminContentBoxProvider,
        public dialog: MatDialog,
        private injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.loadContentBoxes();
    }

    loadContentBoxes() {
        this.subscriptions.push(
            this.contentBoxProvider.getAllContentBoxes().subscribe((response) => {
                this.contentBoxes = response;
            }),
        );
    }

    createContentBox() {
        this.router.navigate(['/admin/create-contentboxes']);
    }

    editContentBox(contentBox: IContentBoxResponse) {
        this.router.navigate(['/admin/create-contentboxes', contentBox._id]);
    }
    deleteContentBox(contentBox: IContentBoxResponse) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: { title: 'Confirm delete', message: 'Are you sure you want to delete this contentBox?' },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((result) => {
                if (result) {
                    this.contentBoxProvider.deleteContentBox(contentBox._id);
                }
            });
    }

    togglePublish(contentBox: IContentBoxResponse) {
        const updatedContentBox = { ...contentBox, published: !contentBox.published };
        this.contentBoxProvider.updateContentBox(updatedContentBox);
    }
    checkNotPublishable(contentBox: IContentBoxResponse) {
        if (contentBox.published) {
            return false;
        }

        for (const item of this.contentBoxes) {
            if (item.published && item.productlineId === contentBox.productlineId) {
                return true;
            }
        }
        return false;
    }
}
