export interface UpdateProductSettingsRequest {
    productLineName: string;
    couponsEnabled: boolean;
    displayOnIOS: boolean;
    displayOnAndroid: boolean;
}

export class ProductSettingsUpdateResponse {
    public productLineName: string;
    public couponsEnabled: boolean;
    public displayOnIOS: boolean;
    public displayOnAndroid: boolean;
}
