import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ISpotLight } from '@modeso/types__dgoods-products';

export const getAllSpotLights = createAction('[Admin ManageSpotLights] getAll');

export const onGetAllSpotLightsSuccessfully = createAction(
    '[Admin ManageSpotLights] getAll successfully',
    props<{ payload: any }>(),
);

export const onGetAllSpotLightsFailed = createAction(
    '[Admin ManageSpotLights] getAll failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const createSpotLight = createAction(
    '[Admin ManageSpotLights] createSpotLight',
    props<{ payload: ISpotLight }>(),
);

export const onCreateSpotLightSuccessfully = createAction(
    '[Admin ManageSpotLights] onCreateSpotLightSuccessfully',
    props<{ payload: any }>(),
);

export const onCreateSpotLightFailed = createAction(
    '[Admin ManageSpotLights] onCreateSpotLightFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateSpotLight = createAction(
    '[Admin ManageSpotLights] updateSpotLight',
    props<{ payload: ISpotLight }>(),
);

export const onUpdateSpotLightSuccessfully = createAction(
    '[Admin ManageSpotLights] onUpdateSpotLightSuccessfully',
    props<{ payload: any }>(),
);

export const onUpdateSpotLightFailed = createAction(
    '[Admin ManageSpotLights] onUpdateSpotLightFailed',
    props<{ payload: HttpErrorResponse }>(),
);
export const deleteSpotLight = createAction('[Admin ManageSpotLights] deleteSpotLight', props<{ payload: string }>());

export const onDeleteSpotLightSuccessfully = createAction(
    '[Admin ManageSpotLights] onDeleteSpotLightSuccessfully',
    props<{ payload: any }>(),
);

export const onDeleteSpotLightFailed = createAction(
    '[Admin ManageSpotLights] onDeleteSpotLightFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const getSpotLightSetting = createAction('[Admin ManageSpotLightSetting] getSpotLightSetting');

export const onGetSpotLightSettingSuccessfully = createAction(
    '[Admin ManageSpotLightSetting] onGetSpotLightSettingSuccessfully',
    props<{ payload: any }>(),
);

export const onGetSpotLightSettingFailed = createAction(
    '[Admin ManageSpotLightSetting] onGetSpotLightSettingFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateSpotLightSetting = createAction(
    '[Admin ManageSpotLightSetting] updateSpotLightSetting',
    props<{ payload: any }>(),
);

export const onUpdateSpotLightSettingSuccessfully = createAction(
    '[Admin ManageSpotLightSetting] onUpdateSpotLightSettingSuccessfully',
    props<{ payload: any }>(),
);

export const onUpdateSpotLightSettingFailed = createAction(
    '[Admin ManageSpotLightSetting] onUpdateSpotLightSettingFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export const storeCopiedSpotLight = createAction(
    '[Admin ManageSpotLights] storeCopiedSpotLight',
    props<{ payload: ISpotLight }>(),
);

export const removeCopiedSpotLight = createAction('[Admin ManageSpotLights] removeCopiedSpotLight');

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
