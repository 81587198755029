import { fromBuffer, FileTypeResult } from 'file-type/core';
import { Buffer } from 'buffer';

export class FileUploadUtil {
    renameFile(originalFile: File, newName: string): File {
        return new File([originalFile], newName, { type: originalFile.type, lastModified: originalFile.lastModified });
    }

    async validateFile(file: File) {
        // @ts-ignore
        window.Buffer = window.Buffer || Buffer;

        const realFileType = await this.getTrueFileType(file);
        if (!realFileType) {
            return false;
        }
        if (realFileType.mime === 'image/jpeg' || realFileType.mime === 'image/png') {
            return true;
        }
        return false;
    }

    private async getTrueFileType(file: any): Promise<FileTypeResult | undefined> {
        return file.arrayBuffer().then((buffer: any) => fromBuffer(buffer));
    }

    isFileSizeAllowed(fileSize: number) {
        return fileSize <= 15000000;
    }
}
