import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { AllTranslationsResponse } from '../models/manageContent/allTranslationsResponse.model';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';
import { ManageProductsResponse } from '../models/manageProductContent/managedProductsResponse.model';
import {
    ProductContentUpdate,
    ProductContentUpdateRequest,
    ProductContentUpdateRequestModel,
    ProductContentUpdateResponse,
} from '../models/manageProductContent/updateProductContent.model';
import Debug from 'debug';
import {
    ProductCategoriesUpdateRequest,
    ProductCategoriesUpdateRequestModel,
} from '../models/manageProductContent/updateProductCategories.model';
import { UpdateProductSettingsRequest } from '../models/manageProductContent/updateProductSettings.model';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductContentService');
@Injectable({ providedIn: 'root' })
export class AdminManageProductContentService {
    private server = '';
    private microserviceName = 'products';
    private readonly route = '/manage';
    private readonly updateSettingsRoute = '/updateProductSettings';
    private readonly updateProductCategoriesRoute = '/updateProductCategories';
    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getAllProducts() {
        const url = `${this.server}${this.microserviceName}${this.route}`;
        debug(url);
        return this.http.get<ManageProductsResponse>(url);
    }

    updateContentByProductLine(content: { payload: ProductContentUpdateRequest }) {
        const url = `${this.server}${this.microserviceName}${this.route}/${content.payload.productLineName}`;
        return this.http.post(
            url,
            new ProductContentUpdateRequestModel(content.payload.locale, content.payload.description),
        );
    }

    updateSettingsByProductLine(settings: { payload: UpdateProductSettingsRequest }) {
        const url = `${this.server}${this.microserviceName}${this.updateSettingsRoute}/${settings.payload.productLineName}`;
        return this.http.post(url, settings.payload);
    }

    updateProductCategories(update: { payload: ProductCategoriesUpdateRequest }) {
        const url = `${this.server}${this.microserviceName}${this.updateProductCategoriesRoute}/${update.payload.productLineName}`;
        return this.http.post(url, new ProductCategoriesUpdateRequestModel(update.payload.categories));
    }
}
