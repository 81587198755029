<div class="dgoods-table">
    <div class="dgoods-table-section" *ngFor="let section of sections">
        <span class="dgoods-table-section-title">{{ section.title }}</span>
        <dgoods-table-cell
            *ngFor="let data of section.datasource"
            [delegate]="this"
            [datasource]="data"
            (clickedData)="onClickedData($event)"
        >
        </dgoods-table-cell>
    </div>
</div>
