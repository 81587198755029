import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as FraudRulesActions from '../actions/fraud-rules.actions';
import { FraudRulesService } from '../service/fraud-rules.service';

@Injectable()
export class FraudRulesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly fraudRulesService: FraudRulesService,
    ) {}

    loadFraudRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FraudRulesActions.loadFraudRules),
            switchMap(() =>
                this.fraudRulesService.getFraudRules().pipe(
                    map((rules) => FraudRulesActions.loadFraudRulesSuccess({ rules })),
                    catchError((error: HttpErrorResponse) => of(FraudRulesActions.loadFraudRulesFailure({ error }))),
                ),
            ),
        ),
    );

    enableFraudRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FraudRulesActions.enableFraudRule),
            mergeMap(({ id }) =>
                this.fraudRulesService.enableFraudRule(id).pipe(
                    map(() => FraudRulesActions.enableFraudRuleSuccess({ id })),
                    catchError((error: HttpErrorResponse) => of(FraudRulesActions.enableFraudRuleFailure({ error }))),
                ),
            ),
        ),
    );

    disableFraudRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FraudRulesActions.disableFraudRule),
            mergeMap(({ id }) =>
                this.fraudRulesService.disableFraudRule(id).pipe(
                    map(() => FraudRulesActions.disableFraudRuleSuccess({ id })),
                    catchError((error: HttpErrorResponse) => of(FraudRulesActions.disableFraudRuleFailure({ error }))),
                ),
            ),
        ),
    );

    deleteFraudRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FraudRulesActions.deleteFraudRule),
            mergeMap(({ id }) =>
                this.fraudRulesService.deleteFraudRule(id).pipe(
                    map(() => FraudRulesActions.deleteFraudRuleSuccess({ id })),
                    catchError((error: HttpErrorResponse) => of(FraudRulesActions.deleteFraudRuleFailure({ error }))),
                ),
            ),
        ),
    );
}
