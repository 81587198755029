import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from '../actions/admin.whiteListingProducts.actions';
import { AdminWhiteListingProductsService } from '../services/admin.whiteListingProducts.service';
import { ProductsStateActionsSource } from '../models/enums/product-management-state-actions-source.enum';
import { HttpErrorResponse } from '@angular/common/http';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminWhiteListingProductsEffects ');

@Injectable()
export class AdminWhiteListingProductsEffects {
    getAllProductsAllLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllProductsInAllLanguages.type),
            mergeMap(() => {
                return this.service.getAllProductsAllLanguages().pipe(
                    map((response) => {
                        return fromActions.getAllProductsInAllLanguagesSuccessfully({ payload: response });
                    }),
                    catchError((error) => {
                        return of(
                            fromActions.getAllProductsInAllLanguagesFailed({
                                payload: error,
                                source: ProductsStateActionsSource.FETCHING_ALL_PRODUCTS_FAILED,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    getAllProductLineNames$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllProductLineNames.type),
            mergeMap(() => {
                return this.service.getAllProductLineNames().pipe(
                    map((response: string[]) => {
                        return fromActions.getAllProductLineNamesSuccessfully({ payload: response });
                    }),
                    catchError((error) => {
                        return of(fromActions.getAllProductLineNamesFailed({ payload: error }));
                    }),
                );
            }),
        ),
    );

    errorOnGetAllProductsAllLanguages$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.getAllProductsInAllLanguagesFailed.type),
                tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllProductErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    whiteListProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.whiteListProduct.type),
            mergeMap((payload: fromActions.ActionWithPayload<string>) => {
                return this.service.whiteListProduct(payload).pipe(
                    map((response) => {
                        return fromActions.whiteListProductSuccessfully({
                            payload: response,
                            productLineName: payload.payload,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            fromActions.whiteListProductFailed({
                                payload: error,
                                source: ProductsStateActionsSource.WHITELISTING_PRODUCTS_FAILED,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    errorOnWhiteListProduct$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.whiteListProductFailed.type),
                tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllProductErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    unWhiteListProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.unWhiteListProduct.type),
            mergeMap((payload: fromActions.ActionWithPayload<string>) => {
                return this.service.unWhiteListProduct(payload).pipe(
                    map((response) => {
                        return fromActions.unWhiteListProductSuccessfully({
                            payload: response,
                            productLineName: payload.payload,
                        });
                    }),
                    catchError((error) => {
                        return of(
                            fromActions.unWhiteListProductFailed({
                                payload: error,
                                source: ProductsStateActionsSource.UN_WHITELISTING_PRODUCTS_FAILED,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    errorOnUnWhiteListProduct$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.unWhiteListProductFailed.type),
                tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllProductErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    synchronizeProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.synchronizeProducts.type),
            mergeMap(() => {
                return this.service.synchronizeProducts().pipe(
                    map((response) => {
                        return fromActions.synchronizeProductsSuccessfully({ payload: response });
                    }),
                    catchError((error) => {
                        return of(
                            fromActions.synchronizeProductsFailed({
                                payload: error,
                                source: ProductsStateActionsSource.SYNCHRONIZATION_FAILED,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    errorOnSynchronizeProducts$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.synchronizeProductsFailed.type),
                tap((action: fromActions.ActionWithPayload<HttpErrorResponse>) =>
                    this.handleOnLoadAllProductErrors(action.payload),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private service: AdminWhiteListingProductsService,
    ) {}

    public handleOnLoadAllProductErrors(error) {
        debug(error);
        return error;
    }
}
