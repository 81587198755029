<div mat-dialog-content>
    <form class="add-or-update-issuer-form" [formGroup]="addOrUpdateIssuerForm">
        <div class="form-group">
            <label>Issuer Name</label>
            <div class="input-wrapper">
                <input id="name" class="form-control" type="text" formControlName="name" placeholder="Issuer Name" />
            </div>
        </div>

        <div class="form-group">
            <label>Limit</label>
            <div class="input-wrapper">
                <input
                    id="value"
                    class="form-control"
                    type="text"
                    formControlName="value"
                    placeholder="Value"
                    oninput="this.value = this.value.replace(/[^1-9.]/g, '') && (parseInt(this.value));"
                    min="1"
                />
            </div>
        </div>

        <div class="form-group">
            <label>Timeframe Name</label>
            <div class="input-wrapper">
                <mat-form-field appearance="fill">
                    <mat-select
                        formControlName="timeframeName"
                        (selectionChange)="getSelectedTimeFilter($event)"
                        required
                    >
                        <mat-option *ngFor="let timeFrame of timeFrames" [value]="timeFrame">
                            {{timeFrame}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="addOrUpdateIssuerForm.get('timeframeName').hasError('required')"
                        >Please select timeframe name</mat-error
                    >
                </mat-form-field>
            </div>
        </div>

        <div class="form-group">
            <label>Timeframe Value</label>
            <div class="input-wrapper">
                <input
                    id="timeframeValue"
                    class="form-control"
                    type="text"
                    formControlName="timeframeValue"
                    placeholder="Timeframe Value"
                    oninput="this.value = this.value.replace(/[^1-9.]/g, '') && (parseInt(this.value));"
                    min="1"
                />
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="dialog-buttons">
    <div class="action-button">
        <button mat-button (click)="onNoClick()" class="button">Cancel</button>
        <button
            mat-button
            [mat-dialog-close]="data"
            [disabled]="buttonStatus()"
            cdkFocusInitial
            (click)="save()"
            class="button"
        >
            Confirm
        </button>
    </div>
</div>
