import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IContentPage, IContentPageResponse } from '../models/content.pages';

export const getAllContentPages = createAction('[Admin Content Pages] Get All Content Pages');

export const getAllContentPagesSuccess = createAction(
    '[Admin Content Pages] Get All Content Pages Success',
    props<{ payload: IContentPageResponse[] }>(),
);

export const getAllContentPagesFailure = createAction(
    '[Admin Content Pages] Get All Content Pages Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const addContentPage = createAction(
    '[Admin Content Pages] Add Content Page',
    props<{ payload: IContentPage }>(),
);

export const addContentPageSuccess = createAction(
    '[Admin Content Pages] Add Content Page Success',
    props<{ payload: IContentPageResponse }>(),
);

export const addContentPageFailure = createAction(
    '[Admin Content Pages] Add Content Page Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateContentPage = createAction(
    '[Admin Content Pages] Update Content Page',
    props<{ payload: IContentPageResponse }>(),
);

export const updateContentPageSuccess = createAction(
    '[Admin Content Pages] Update Content Page Success',
    props<{ payload: IContentPageResponse }>(),
);

export const updateContentPageFailure = createAction(
    '[Admin Content Pages] Update Content Page Failure',
    props<{ payload: HttpErrorResponse }>(),
);

export const deleteContentPage = createAction(
    '[Admin Content Pages] Delete Content Page',
    props<{ payload: string }>(),
);

export const deleteContentPageSuccess = createAction(
    '[Admin Content Pages] Delete Content Page Success',
    props<{ payload: string }>(),
);

export const deleteContentPageFailure = createAction(
    '[Admin Content Pages] Delete Content Page Failure',
    props<{ payload: HttpErrorResponse }>(),
);
