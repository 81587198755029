import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import Debug from 'debug';
import { CreateUpdateCategoryRequestModel } from '../models/manageProductCategories/categories.models';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductCategoryService');
@Injectable({ providedIn: 'root' })
export class AdminManageProductCategoryService {
    private server = '';
    private microserviceName = 'products';
    private readonly route = '/categories';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getAllCategories() {
        debug('getAllCategories');
        const url = `${this.server}${this.microserviceName}${this.route}`;
        debug(url);
        return this.http.get<any>(url);
    }

    create(content: { payload: CreateUpdateCategoryRequestModel }) {
        debug('create', content);
        const url = `${this.server}${this.microserviceName}${this.route}/`;
        return this.http.post(url, content.payload);
    }

    update(content: { payload: CreateUpdateCategoryRequestModel }) {
        debug('update', content);
        const url = `${this.server}${this.microserviceName}${this.route}/${content.payload._id}`;
        return this.http.put(url, content.payload);
    }

    delete(content: { payload: string }) {
        debug('delete', content);
        const url = `${this.server}${this.microserviceName}${this.route}/${content.payload}`;
        return this.http.delete(url);
    }
}
