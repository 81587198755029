import { createAction, props, Action } from '@ngrx/store';
import { AllTranslationsDetails } from '../models/manageContent/allTranslationsResponse.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationsByKeyResponseDetails } from '../models/manageContent/translationByKeyResponse.model';

export const getAllLocalizations = createAction('[Admin ManageContent] getAllLocalizations');

export const onGetAllLocalizationsSuccessfully = createAction(
    '[Admin ManageContent] getAllLocalizations successfully',
    props<{ payload: AllTranslationsDetails[] }>(),
);

export const onGetAllLocalizationFailed = createAction(
    '[Admin ManageContent] getAllLocalizations failed',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateLocalizationByKey = createAction(
    '[Admin ManageContent] updateLocalizationByKey',
    props<{ payload: TranslationsByKeyResponseDetails }>(),
);

export const onUpdateLocalizationByKeySuccessfully = createAction(
    '[Admin ManageContent] updateLocalizationByKey successfully',
    props<{ payload: TranslationsByKeyResponseDetails }>(),
);

export const onUpdateLocalizationByKeyFailed = createAction(
    '[Admin ManageContent] updateLocalizationByKey failed',
    props<{ payload: HttpErrorResponse }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
