<div class="container" cdkDropListGroup *ngIf="hasReadPermission()">
    <div class="list-container">
        <h2>Categories</h2>
        <div cdkDropList id="categoriesList" [cdkDropListData]="categories" class="categories-list">
            <div *ngFor="let category of categories" cdkDrag [cdkDragData]="category.name">
                <img src="{{getIconSvg(category.icon)}}" alt="previous" width="19px" height="19px" class="icon" />
                <span class="category-name">{{category.name}}</span>
                <div class="category-buttons">
                    <button class="edit-btn" (click)="editCategory(category)">Edit</button>
                    <button class="delete-btn" (click)="openDeleteConfirmationDialog(category)">Delete</button>
                </div>
            </div>
        </div>
        <div class="category add-category">
            <button class="add-btn" (click)="addCategory()">Add</button>
        </div>
    </div>
    <div class="list-container">
        <h2>Products</h2>
        <div class="ProductCategories">
            <div *ngFor="let product of products" class="product">
                <h3>{{product.name}}</h3>
                <div
                    cdkDropList
                    id="{{product.name}}"
                    [cdkDropListData]="product.categories"
                    [cdkDropListConnectedTo]="['categoriesList']"
                    (cdkDropListDropped)="drop($event)"
                >
                    <div class="default-item">
                        <span>Drop category here</span>
                        <div *ngFor="let category of product.categories" class="category">
                            <div class="chip">
                                <img
                                    src="{{getIconSvg(category.icon)}}"
                                    alt="previous"
                                    width="19px"
                                    height="19px"
                                    class="icon"
                                /><span> {{category.name}} </span>
                                <button class="remove-btn" (click)="removeCategory(product, category)">x</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
