<form [formGroup]="form.get('config')">
    <div class="input-row">
        <mat-label>{{ title }}</mat-label>
        <mat-form-field>
            <mat-select [formControlName]="name" (selectionChange)="change($event)">
                <mat-option
                    *ngFor="let digitalVoucherConfigAttributes of iterators | keyvalue"
                    [value]="digitalVoucherConfigAttributes.value"
                >
                    {{ digitalVoucherConfigAttributes.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-error *ngIf="form.get('config').get(name).touched && form.get('config').get(name).errors?.required"
        >{{ title }} is Required</mat-error
    >
</form>
