import { createAction, props, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IMerchant, IMerchantResponse } from '@modeso/types__dgoods-products';

// Merchant
export const getAllMerchants = createAction('[Admin Merchant get Merchants List] getAllMerchants request');

export const onGetAllMerchantsSucceeded = createAction(
    '[Admin Merchant get Merchants List] getAllMerchants success',
    props<{ payload: Array<IMerchantResponse> }>(),
);

export const onGetAllMerchantsFailed = createAction(
    '[Admin Merchant get Merchants List] getAllMerchants fail',
    props<{ payload: any }>(),
);

export const createOrUpdateMerchant = createAction(
    '[Admin Merchant] createOrUpdateMerchant',
    props<{ payload: IMerchant }>(),
);
export const onCreateOrUpdateMerchantSuccessfully = createAction(
    '[Admin Merchant] onCreateOrUpdateMerchantSuccessfully',
    props<{ payload: IMerchantResponse }>(),
);
export const onCreateOrUpdateMerchantFailed = createAction(
    '[Admin Merchant] onCreateOrUpdateMerchantFailed',
    props<{ payload: HttpErrorResponse }>(),
);

export interface IActionWithPayload<T> extends Action {
    payload: T;
}
