import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import { AuditLogsResponse } from '../models/auditLogs/auditLogsResponse.model';
import { AuditLogsRequest } from '../models/auditLogs/auditLogsRequest.model';

@Injectable({ providedIn: 'root' })
export class AdminAuditLogsService {
    private server = '';
    private analyticsMicroserviceName = 'analytics';
    private readonly route = '/';
    private readonly auditLogs = 'auditLogs';

    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }

    getAuditLogs(requestParams: { payload: AuditLogsRequest }) {
        const url = `${this.server}${this.analyticsMicroserviceName}${this.route}${this.auditLogs}${this.route}${requestParams.payload.action}${this.route}${requestParams.payload.timePeriod}${this.route}${requestParams.payload.date}`;
        return this.http.get<AuditLogsResponse>(url);
    }
}
