import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-input-values-confirmation',
    templateUrl: './confirmCouponInputValues.html',
    styleUrls: ['./confirmCouponInputValues.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmCouponInputValuesDialog {
    text;

    constructor(
        public dialogRef: MatDialogRef<ConfirmCouponInputValuesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.text = data.text;
    }

    confirm(): void {
        this.dialogRef.close();
    }
}
