import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
@Component({
    selector: 'app-page-contentManagement',
    templateUrl: './contentManagement.page.html',
    styleUrls: ['./contentManagement.page.scss'],
})
export class ContentManagementPage extends BasePageComponent implements OnInit {
    background: string = '#0076bf';
    navLinks = [
        { label: 'Text and Labels', link: 'text-and-labels', index: 0 },
        { label: 'Products', link: 'product-content', index: 1 },
        { label: 'FAQ', link: 'faq', index: 2 },
    ];
    activeLink = this.navLinks[0].link;
    constructor(
        injector: Injector,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
