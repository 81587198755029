import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-add-financing-party',
    templateUrl: './addFinancingParty.html',
    styleUrls: ['./addFinancingParty.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddFinancingPartyDialog {
    formGroup = new FormGroup({ name: new FormControl('', [Validators.required]) });

    get name() {
        return this.formGroup.get('name');
    }

    constructor(private readonly dialogRef: MatDialogRef<AddFinancingPartyDialog>) {}

    confirm(): void {
        this.dialogRef.close(this.name.value);
    }
    cancel(): void {
        this.dialogRef.close(null);
    }
}
