<ng-container>
    <button style="margin: 1.5rem auto" (click)="goToCreateMerchantPage()">Create new merchant</button>
    <div *ngIf="merchantList">
        <table mat-table [dataSource]="merchantList" class="mat-elevation-z8 table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let merchant">
                    <div>{{merchant.name}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="merchantId">
                <th mat-header-cell *matHeaderCellDef>External Merchant Id</th>
                <td mat-cell *matCellDef="let merchant">
                    <div>{{merchant.externalMerchantId}}</div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr (click)="editMerchant(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</ng-container>
