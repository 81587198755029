import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '../../base.page';
import { AdminContentPageProvider } from '@modeso/dgoods-lib-admin-fe/provider/admin-content-page.provider';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../Dialogs/confirmation-dialog.component';
import { take } from 'rxjs';
import { IconMapping, iconMapping, getSvgPath } from '../../../shared/models/icons';

@Component({
    selector: 'app-create-content-page',
    templateUrl: './create-content-page.page.html',
    styleUrls: ['./create-content-page.page.scss'],
})
export class CreateContentPageComponent extends BasePageComponent implements OnInit {
    contentPageForm: FormGroup;
    selectedLanguage: string;
    isError: boolean;
    errorMessage: string;
    contentPage: IContentPageResponse;
    updatedContentPage: IContentPageResponse;
    isUnsavedChanges = false;
    validForm: boolean = true;
    icons: string[];
    iconMapping: IconMapping = iconMapping;
    selectedIcon: string;
    languagesTabsData: any[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private contentPageProvider: AdminContentPageProvider,
        public dialog: MatDialog,
        injector: Injector,
    ) {
        super(injector);
        this.icons = Object.keys(this.iconMapping);
        this.contentPageForm = this.formBuilder.group({
            // sortId shoould be number
            sortId: ['', [Validators.required, Validators.min(0)]],
            // title max length is 30
            title: ['', [Validators.required, Validators.maxLength(30)]],
            content: ['', Validators.required],
            icon: ['', Validators.required],
        });
        this.selectedLanguage = 'en_us';
        this.selectedIcon = '';
        this.isError = false;
        this.errorMessage = '';

        this.languagesTabsData = [
            { label: 'English (en_us)', value: 'en_us' },
            { label: 'German (de_ch)', value: 'de_ch' },
            { label: 'French (fr_ch)', value: 'fr_ch' },
            { label: 'Italian (it_ch)', value: 'it_ch' },
        ];

        this.contentPage = {
            title: { en_us: '', de_ch: '', fr_ch: '', it_ch: '' },
            content: { en_us: '', de_ch: '', fr_ch: '', it_ch: '' },
            sortId: 0,
            published: false,
            images: [],
            icon: '',
        };
        this.updatedContentPage = { ...this.contentPage };
    }

    loadContentPage() {
        this.subscriptions.push(
            this.contentPageProvider.getAllContentPages().subscribe((response) => {
                for (const contentPage of response) {
                    if (contentPage._id === this.activatedRoute.snapshot.params.id) {
                        this.contentPage = JSON.parse(JSON.stringify(contentPage));
                        if (!this.updatedContentPage._id) {
                            this.updatedContentPage = JSON.parse(JSON.stringify(contentPage));
                        }
                        this.populateFieldsFromContentPage();
                        this.contentPageForm.patchValue({
                            sortId: this.updatedContentPage.sortId,
                            icon: contentPage.icon,
                        });
                        break;
                    }
                }
            }),
        );
    }

    ngOnInit(): void {
        this.loadContentPage();

        this.subscriptions.push(
            this.contentPageForm.get('title')?.valueChanges.subscribe((value) => {
                this.updatedContentPage.title[this.selectedLanguage] = value;
                this.isUnsavedChanges = true;
            }),
        );
        this.subscriptions.push(
            this.contentPageForm.get('sortId')?.valueChanges.subscribe((value) => {
                this.updatedContentPage.sortId = +value;
                this.isUnsavedChanges = true;
            }),
        );

        this.subscriptions.push(
            this.contentPageForm.get('icon')?.valueChanges.subscribe((value) => {
                this.updatedContentPage.icon = value;
                this.isUnsavedChanges = true;
            }),
        );
    }

    populateFieldsFromContentPage(): void {
        if (this.updatedContentPage) {
            this.contentPageForm.patchValue({
                title: this.updatedContentPage.title[this.selectedLanguage],
                content: this.updatedContentPage.content[this.selectedLanguage],
            });
        } else {
            this.contentPageForm.patchValue({ title: '', content: '' });
        }
    }

    save(): void {
        if (this.validForm) {
            // Perform save operation here
            const response = this.contentPageProvider.updateContentPage(this.updatedContentPage);
            this.router.navigate(['/admin/contentpages']); // Redirect to content pages list
        } else {
            this.isError = true;
        }
    }

    cancel(): void {
        if (this.isUnsavedChanges) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '400px',
                data: {
                    title: 'Unsaved Changes',
                    message: 'Are you sure you want to proceed? Any unsaved content changes will be lost.',
                },
            });

            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result) => {
                    if (result) {
                        this.router.navigate(['/admin/contentpages']); // Redirect to content pages list
                    }
                });
        } else {
            this.router.navigate(['/admin/contentpages']); // Redirect to content pages list
        }
    }

    onContentValueChanged(value: string) {
        this.errorMessage = '';
        this.validForm = true;
        this.updatedContentPage.content[this.selectedLanguage] = value;
        this.isUnsavedChanges = true;

        for (const language in this.updatedContentPage.content) {
            if (
                this.updatedContentPage.content[language].length === 0
                || /^\s*$/.test(this.updatedContentPage.content[language])
            ) {
                this.validForm = false;
                this.errorMessage = `Please fill in all required fields. (Content cannot be empty for ${language} language.)`;
                break;
            }
        }
    }

    onImageUploaded(imageUrl: string) {
        this.updatedContentPage.images = [...this.updatedContentPage.images, imageUrl];

        this.updateContentPageWithImages();
    }
    onImageDeleted(imageUrl: string) {
        this.updatedContentPage.images = this.updatedContentPage.images.filter((image) => {
            return image !== imageUrl;
        });
        this.updateContentPageWithImages();

        this.replaceDeletedUrls(imageUrl);

        if (this.updatedContentPage) {
            this.contentPageForm.patchValue({ content: this.updatedContentPage.content[this.selectedLanguage] });
        }
    }

    updateContentPageWithImages() {
        const updatedContentPage = {
            _id: this.updatedContentPage._id,
            title: null,
            content: null,
            sortId: null,
            published: null,
            images: this.updatedContentPage.images,
            icon: null,
        };
        this.contentPageProvider.updateContentPage(updatedContentPage);
    }

    replaceDeletedUrls(imageUrl: string) {
        //define the replace function
        const replaceURLs = (input: string, oldURL: string, newURL: string): string => {
            const regex = new RegExp(`!\\[[^\\]]+\\]\\(${oldURL}\\)`, 'g');
            if (regex.test(input)) {
                this.isUnsavedChanges = true;
            }
            return input.replace(regex, newURL);
        };

        // apply the replace function to all content fields
        for (const key in this.updatedContentPage.content) {
            this.updatedContentPage.content[key] = replaceURLs(this.updatedContentPage.content[key], imageUrl, '');
        }
    }
    getIconSvg(iconName: string): string {
        return getSvgPath(iconName);
    }

    languageTabSelectionChanged(event) {
        this.selectedLanguage = this.languagesTabsData[event].value;

        this.populateFieldsFromContentPage();
    }
}
