<div class="center-content">
    <h2 mat-dialog-title>Warning</h2>
    <div mat-dialog-content style="width: 100%; margin: 0px">
        <h4>{{message}}</h4>
        <h5>Warning: this action is irreversible</h5>
    </div>
    <mat-dialog-actions align="center">
        <button mat-button class="save-btn" (click)="confirm()">Yes</button>
        <button mat-button class="cancel-btn" [mat-dialog-close]="false">Cancel</button>
    </mat-dialog-actions>
</div>
