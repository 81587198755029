import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthHandlerDelegate } from '@modeso/dgoods-lib-admin-fe';
import Debug from 'debug';
import { LocalStorageService } from '../shared/services/localStorage.service';
const debug = Debug('dgoods:admin:SessionOrchestrator');

@Injectable({ providedIn: 'root' })
export class SessionOrchestrator implements IAuthHandlerDelegate {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
    ) {}

    onSessionExpired() {
        debug(this.router.url);
        if (!this.router.isActive('de-ch/login', false)) {
            debug('onSessionExired redirect to login');
            this.localStorageService.clear();
            this.router.navigateByUrl('de-ch/login');
        }
    }
}
