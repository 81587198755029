/*
 * Public API Surface of coupon
 */

export * from './twint.coupons.config';
export * from './twint.coupons.module';
export * from './providers/twint.coupons.provider';
export * from './reducers/twint.coupons.reducer';

// components
export * from './components/list-coupons/list-coupons.component';
export * from './components/add-coupon/add-coupon.component';

//pages
export * from './pages/manage-coupons/manage-coupons.page';

// dialogs
export * from './dialog/errorDialog/errorDialog';
export * from './dialog/confirmFeatureDialog/confirmFeatureDialog';
