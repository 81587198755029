import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';

export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'DD.MM.YYYY HH:mm';
export const MAX_DATE = '31-12-9999';
export const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
    parse: { dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT },
    display: {
        dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export const INTL_DATE_INPUT_FORMAT = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hourCycle: 'h23',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

export const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: { dateInput: INTL_DATE_INPUT_FORMAT },
    display: {
        dateInput: INTL_DATE_INPUT_FORMAT,
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
