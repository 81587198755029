/* eslint-disable @angular-eslint/component-selector */
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    AdminSortingOrderProvider,
    AdminWhiteListingProductsProvider,
    ProductsStateActionsSource,
    WhiteListingResponseModel,
} from '@modeso/dgoods-lib-admin-fe';
import { ProductsWithAvailablePricesDto, SortingOrderRequest } from '@modeso/types__dgoods-products';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { ExtendedHttpError } from '../../shared/models/interfaces/extendedHttpError.interface';
import { ProductsByWhiteListStatus } from '../../shared/models/interfaces/productsByWhitelistStatus.interface';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { PopUpComponent } from '../popUp/popUp';

@Component({
    selector: 'app-page-whiteListing',
    templateUrl: './whiteListing.page.html',
    styleUrls: ['./whiteListing.page.scss'],
})
export class WhiteListingPage implements OnInit, OnDestroy {
    successMessage: string;
    errorMessage: string;
    error: boolean;
    success: boolean;
    getAllProducts$: Observable<ProductsByWhiteListStatus> | undefined;
    userHasReadPermissions: boolean;
    userHasWritePermissions: boolean;

    private error$: Subscription | undefined;

    constructor(
        private adminWhiteListingProvider: AdminWhiteListingProductsProvider,
        private sortingOrderProvider: AdminSortingOrderProvider,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
    ) {
        this.resetSuccessMessage();
        this.resetErrorMessage();
        this.userHasReadPermissions = this.hasReadPermission();
        this.userHasWritePermissions = this.hasWritePermission();
    }

    ngOnInit() {
        this.adminWhiteListingProvider.dispatchGetAllProductsInAllLanguages$();

        this.getAllProducts$ = this.adminWhiteListingProvider.getAllProductsInAllLanguages$().pipe(
            filter((products: ProductsWithAvailablePricesDto[]) => products != null && products.length !== 0),
            map((products) => {
                return {
                    whiteListed: products.filter((p) => p.whiteListed),
                    unWhiteListed: products.filter((p) => !p.whiteListed),
                };
            }),
        );

        this.error$ = this.adminWhiteListingProvider
            .getError$()
            .pipe(filter((error: ExtendedHttpError) => error != null))
            .subscribe((errorResponse: ExtendedHttpError) => {
                this.error = true;
                if (errorResponse.source === ProductsStateActionsSource.SYNCHRONIZATION_FAILED) {
                    if (errorResponse.status === 409) {
                        this.errorMessage =
                            'The synchronization is still running.You will be notified via Email once it is done.';
                    } else {
                        this.errorMessage =
                            'Failed to initiate synchronization. Please try again later or contact support.';
                    }
                } else if (errorResponse.source === ProductsStateActionsSource.WHITELISTING_PRODUCTS_FAILED) {
                    this.errorMessage = 'Failed to publish product';
                } else if (errorResponse.source === ProductsStateActionsSource.FETCHING_ALL_PRODUCTS_FAILED) {
                    this.errorMessage = 'Internal Server Error..';
                } else if (errorResponse.source === ProductsStateActionsSource.UN_WHITELISTING_PRODUCTS_FAILED) {
                    this.errorMessage = 'Failed to un-publish product';
                } else if (errorResponse.source === ProductsStateActionsSource.SORTING_PRODUCTS) {
                    this.errorMessage = 'Failed to sort products';
                }
            });
    }

    ngOnDestroy(): void {
        if (this.error$ != null) {
            this.error$.unsubscribe();
        }
    }

    unWhiteListProduct(productLineName: string): void {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Unpublished Product',
                confirmationMessage: 'Are you sure you want to unpublish ' + productLineName + ' ?',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                first((result) => result === true),
                switchMap(() =>
                    this.adminWhiteListingProvider
                        .unWhiteListProduct$(productLineName)
                        .pipe(first((message: WhiteListingResponseModel) => message != null)),
                ),
            )
            .subscribe(() => {
                this.success = true;
                this.successMessage = `You have successfully un-published: ${productLineName}`;
            });
    }

    whiteListProduct(productLineName: string): void {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Published Product',
                confirmationMessage: 'Are you sure you want to publish ' + productLineName + ' ?',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                first((result) => result === true),
                switchMap(() =>
                    this.adminWhiteListingProvider
                        .whiteListProduct$(productLineName)
                        .pipe(first((message: WhiteListingResponseModel) => message != null)),
                ),
            )
            .subscribe(() => {
                this.success = true;
                this.successMessage = `You have successfully published: ${productLineName}`;
            });
    }

    synchronizeProducts(): void {
        const dialogRef = this.dialog.open(PopUpComponent, {
            data: {
                title: 'Synchronize Products',
                confirmationMessage: 'Are you sure you want to synchronize products?',
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                first((result) => result === true),
                switchMap(() =>
                    this.adminWhiteListingProvider
                        .synchronizeProducts$()
                        .pipe(first((message: string) => message != null)),
                ),
            )
            .subscribe(() => {
                this.success = true;
                this.successMessage = 'Synchronization is in progress...';
            });
    }

    sortProducts(direction: string, index: number, whiteListedProducts: ProductsWithAvailablePricesDto[]): void {
        switch (direction) {
            case 'top':
                document
                    .getElementById(`id${0}`)
                    .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                return moveItemInArray(whiteListedProducts, index, 0);

            case 'up':
                return moveItemInArray(whiteListedProducts, index, index - 1);

            case 'down':
                return moveItemInArray(whiteListedProducts, index, index + 1);

            case 'bottom':
                document
                    .getElementById(`id${whiteListedProducts.length - 1}`)
                    .scrollIntoView({ behavior: 'smooth', block: 'start' });
                return moveItemInArray(whiteListedProducts, index, whiteListedProducts.length - 1);

            default:
                throw new Error(`Invalid direction: ${direction}`);
        }
    }

    saveOrder(whiteListedProducts: ProductsWithAvailablePricesDto[]): void {
        const whiteListedProductIds = whiteListedProducts.map((product) => product.productLineName);

        const products: SortingOrderRequest = { productIds: whiteListedProductIds };
        this.sortingOrderProvider
            .dispatchCreateOrUpdateSortingOrder(products)
            .pipe(first((result) => result === true))
            .subscribe(() => {
                this.success = true;
                this.successMessage = `Published products are sorted successfully`;
            });
    }

    private hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_FRAUD_VIEWER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    private hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [UserRoles.DIGITAL_VAUCHERS_ADMIN, UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    resetSuccessMessage(): void {
        this.success = false;
        this.successMessage = '';
    }

    resetErrorMessage(): void {
        this.error = false;
        this.errorMessage = '';
    }
}
