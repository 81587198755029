import { on } from '@ngrx/store';
import * as FraudRulesActions from '../actions/fraud-rules.actions';
import { CartState, fraudRulesAdapter } from './cart.reducer';

export const fraudRulesReducers = [
    // Fraud Rules Actions
    on(FraudRulesActions.loadFraudRules, (state: CartState) => {
        return { ...state, loading: true };
    }),

    on(FraudRulesActions.loadFraudRulesSuccess, (state: CartState, { rules }) => {
        return { ...state, fraudRules: fraudRulesAdapter.setAll(rules, state.fraudRules), loading: false };
    }),

    on(FraudRulesActions.loadFraudRulesFailure, (state: CartState, { error }) => {
        return { ...state, loading: false, error };
    }),

    on(FraudRulesActions.enableFraudRule, (state: CartState) => ({ ...state, loading: true })),

    on(FraudRulesActions.enableFraudRuleSuccess, (state: CartState, { id }) => ({
        ...state,
        fraudRules: fraudRulesAdapter.updateOne({ id, changes: { isEnabled: true } }, state.fraudRules),
        loading: false,
    })),

    on(FraudRulesActions.enableFraudRuleFailure, (state: CartState, { error }) => ({
        ...state,
        loading: false,
        error,
    })),

    on(FraudRulesActions.disableFraudRule, (state: CartState) => ({ ...state, loading: true })),

    on(FraudRulesActions.disableFraudRuleSuccess, (state: CartState, { id }) => ({
        ...state,
        fraudRules: fraudRulesAdapter.updateOne({ id, changes: { isEnabled: false } }, state.fraudRules),
        loading: false,
    })),

    on(FraudRulesActions.disableFraudRuleFailure, (state: CartState, { error }) => ({
        ...state,
        loading: false,
        error,
    })),

    on(FraudRulesActions.deleteFraudRule, (state: CartState) => ({ ...state, loading: true })),

    on(FraudRulesActions.deleteFraudRuleSuccess, (state: CartState, { id }) => {
        return { ...state, fraudRules: fraudRulesAdapter.removeOne(id, state.fraudRules), loading: false };
    }),

    on(FraudRulesActions.deleteFraudRuleFailure, (state: CartState, { error }) => ({
        ...state,
        loading: false,
        error,
    })),

    on(FraudRulesActions.clearFraudRulesState, (state: CartState) => ({
        ...state,
        fraudRules: fraudRulesAdapter.getInitialState(),
        loading: false,
        error: null,
    })),
];
