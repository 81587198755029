import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminConfigService } from './../admin.config';
import Debug from 'debug';
import { IMerchant, IMerchantResponse } from '@modeso/types__dgoods-products';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminMerchantService');
@Injectable({ providedIn: 'root' })
export class AdminMerchantService {
    private server = '';
    private readonly productMicroServiceName = 'products';
    private readonly productsRoute = '/';
    private readonly merchants = 'merchants';

    /**
     *
     * @param http
     * @param config
     */
    constructor(
        private http: HttpClient,
        @Inject(AdminConfigService) private config,
    ) {
        this.server = config.apiGateway;
    }
    public getAllMerchants() {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.merchants}`;
        return this.http.get<IMerchantResponse[]>(url);
    }

    public createOrUpdateMerchant(merchant: IMerchant) {
        const url = `${this.server}${this.productMicroServiceName}${this.productsRoute}${this.merchants}`;
        return this.http.post<IMerchant>(url, merchant);
    }
}
