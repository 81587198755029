<div class="container" *ngIf="hasReadPermission()">
    <div class="row col-md-7">
        <h4>Product Settings</h4>
        <div class="alert alert-success" *ngIf="isSettingsUpdatedSuccesfully">{{settingsUpdatedSuccsfullyMessage}}</div>
        <div class="alert alert-danger" *ngIf="isError">{{errorMessage}}</div>
    </div>

    <div *ngIf="true" class="table">
        <table mat-table [dataSource]="products" class="mat-elevation-z8">
            <ng-container matColumnDef="productLineName">
                <th mat-header-cell *matHeaderCellDef>Product Line Name</th>
                <td mat-cell *matCellDef="let product">{{ product.productLineName }}</td>
            </ng-container>

            <ng-container matColumnDef="couponsEnabled">
                <th mat-header-cell *matHeaderCellDef>couponsEnabled</th>
                <td mat-cell *matCellDef="let product">
                    <mat-slide-toggle
                        color="warn"
                        [checked]="product['couponsEnabled']"
                        disabled="true"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="displayOnIOS">
                <th mat-header-cell *matHeaderCellDef>displayOnIOS</th>
                <td mat-cell *matCellDef="let product">
                    <mat-slide-toggle
                        color="warn"
                        [checked]="product['displayOnIOS']"
                        disabled="true"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="displayOnAndroid">
                <th mat-header-cell *matHeaderCellDef>displayOnAndroid</th>
                <td mat-cell *matCellDef="let product">
                    <mat-slide-toggle
                        color="warn"
                        [checked]="product['displayOnAndroid']"
                        disabled="true"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let product">
                    <button
                        mat-stroked-button
                        class="states"
                        *ngIf="hasWritePermission()"
                        (click)="displayDialog(product)"
                    >
                        Edit
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
<div class="container" *ngIf="!hasReadPermission()">Sorry you don't have read permission.</div>
