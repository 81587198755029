import { Component, Injector, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AdminManageContentProvider,
    AllTranslationsDetails,
    TranslationsByKeyResponseDetails,
} from '@modeso/dgoods-lib-admin-fe';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateLocalizationByKeyDialog } from './updatelocalizationByKeyDialog/updateLocalizationByKeyDialog';
import { HttpErrorResponse } from '@angular/common/http';
import Debug from 'debug';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from '../../shared/enum/userrole.enum';
import { PermissionHelper } from '../../shared/util/permission.helper';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

const debug = Debug('modeso:dgoods-admin:ManageContentPage');

const filterPredicate = (data: AllTranslationsDetails, filter: string) => {
    if (data.key.toLowerCase().indexOf(filter) !== -1) {
        return true;
    }
    if (data.text['en-us'].toLowerCase().indexOf(filter) !== -1) {
        return true;
    }
    if (data.text['de-ch'].toLowerCase().indexOf(filter) !== -1) {
        return true;
    }
    if (data.text['fr-ch'].toLowerCase().indexOf(filter) !== -1) {
        return true;
    }
    if (data.text['it-ch'].toLowerCase().indexOf(filter) !== -1) {
        return true;
    }
    return false;
};
@Component({
    selector: 'app-page-manage-content',
    templateUrl: './manageContent.page.html',
    styleUrls: ['./manageContent.page.scss'],
})
export class ManageContentPage extends BasePageComponent implements OnInit {
    localizations: AllTranslationsDetails[] = [];
    dataSource: MatTableDataSource<AllTranslationsDetails>;
    selection = new SelectionModel<any>();

    isFAQ: boolean;
    isLocalizationsError = false;
    isServerError = false;

    manageContentColumns: string[] = ['select', 'Key', 'de-ch', 'en-us', 'fr-ch', 'it-ch'];
    localizationUpdatedNotificationMessage = 'Localization is updated successfully';
    serverErrorMessage = 'Server Error';
    errorMessage: string;
    filterValue = '';
    @ViewChild('localizationsPaginator', { static: false }) localizationsPaginator: MatPaginator;

    constructor(
        injector: Injector,
        private adminLocalizationProvider: AdminManageContentProvider,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef,
        private localStorageService: LocalStorageService,
    ) {
        super(injector);
    }

    ngOnInit() {
        // this.localizationselectionList.selectedOptions = new SelectionModel<MatListOption>(false);
        this.isFAQ = this.route.snapshot.data['isFAQ'];
        this.getAllLocalizations();
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.filterValue = filterValue;
    }

    getAllLocalizations() {
        this.subscriptions.push(
            this.adminLocalizationProvider
                .getAllLocalizations$()
                .pipe(first())
                .subscribe((localizationsResponse: AllTranslationsDetails[]) => {
                    this.isLocalizationsError = false;
                    this.isServerError = false;

                    if (this.isFAQ) {
                        this.localizations = localizationsResponse.filter(
                            (x) => x.key.toLowerCase().indexOf('faq_q') !== -1,
                        );
                    } else {
                        this.localizations = localizationsResponse.filter(
                            (x) => x.key.toLowerCase().indexOf('faq_q') == -1,
                        );
                    }

                    this.dataSource = new MatTableDataSource<AllTranslationsDetails>(this.localizations);
                    this.dataSource.filterPredicate = filterPredicate;
                    this.changeDetector.detectChanges();
                    this.dataSource.paginator = this.localizationsPaginator;
                }),
        );

        this.subscriptions.push(
            this.adminLocalizationProvider.getError$().subscribe((errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 404) {
                    this.isServerError = false;
                    this.isLocalizationsError = true;
                    this.errorMessage = errorResponse.error.message;
                } else {
                    this.isLocalizationsError = false;
                    this.isServerError = true;
                }
            }),
        );
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, null, { duration: 2000, panelClass: ['blue-snackbar'] });
    }

    selectedRow() {
        if (!this.selection.selected[0]) {
            return null;
        }
        return this.selection.selected[0];
    }

    localizationButtonStatus() {
        return !this.selectedRow();
    }

    onUpdateLocalization() {
        const selectedLocalization: AllTranslationsDetails = { ...this.selectedRow() };
        const dialogRef = this.dialog.open(UpdateLocalizationByKeyDialog, {
            width: '50%',
            data: { localization: selectedLocalization },
        });

        dialogRef.afterClosed().subscribe((result: TranslationsByKeyResponseDetails) => {
            if (!result) {
                return;
            }
            selectedLocalization.key = result.key;
            selectedLocalization.text = result.text;

            this.subscriptions.push(
                this.adminLocalizationProvider
                    .updateLocalizationByKey$(result)
                    .pipe(first())
                    .subscribe((updateLocalizationByKeyResponse: AllTranslationsDetails[]) => {
                        this.isLocalizationsError = false;
                        this.isServerError = false;

                        this.localizations.map((localization: AllTranslationsDetails) => {
                            const localizationToBeUpdated = { ...localization };
                            localizationToBeUpdated.key === selectedLocalization.key ?
                                (localizationToBeUpdated.text = selectedLocalization.text)
                            :   localization;
                            localization = { ...localizationToBeUpdated };
                        });
                        this.dataSource = new MatTableDataSource<AllTranslationsDetails>(this.localizations);
                        this.changeDetector.detectChanges();
                        this.dataSource.filterPredicate = filterPredicate;
                        this.dataSource.paginator = this.localizationsPaginator;
                        this.openSnackBar(this.localizationUpdatedNotificationMessage);
                        this.dataSource.filter = this.filterValue;
                    }),
            );

            this.subscriptions.push(
                this.adminLocalizationProvider
                    .getError$()
                    .pipe(first())
                    .subscribe((errorResponse: HttpErrorResponse) => {
                        if (errorResponse.status === 404) {
                            this.isServerError = false;
                            this.isLocalizationsError = true;
                            this.errorMessage = errorResponse.error.message;
                        } else {
                            this.isLocalizationsError = false;
                            this.isServerError = true;
                        }
                    }),
            );
        });
    }

    hasReadPermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }

    hasWritePermission(): boolean {
        const role = this.localStorageService.getUserRole();
        const priviledgedRoles = [
            UserRoles.DIGITAL_VAUCHERS_ADMIN,
            UserRoles.DIGITAL_VAUCHERS_CONTENT_MANAGER,
            UserRoles.DIGITAL_VAUCHERS_PRODUCT_MANAGER,
        ];
        return PermissionHelper.hasPermission(role, priviledgedRoles);
    }
}
