import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, retry, tap, throttleTime } from 'rxjs/operators';
import Debug from 'debug';
import * as fromActions from '../actions/admin-newsticker.action';
import { INewsticker } from '@modeso/types__dgoods-products';
import { Router } from '@angular/router';
import { AdminNewsTickerService } from '../services/admin-newsticker.service';

const debug = Debug('modeso:modeso-lib-admin-fe:AdminNewsTickerEffect');

@Injectable()
export class AdminNewsTickerEffect {
    constructor(
        private actions$: Actions,
        private service: AdminNewsTickerService,
        private router: Router,
    ) {}

    getAllnewstickers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllNewstickers.type),
            mergeMap(() => {
                return this.service.getAllNewsticker().pipe(
                    retry(1),
                    map((response) => fromActions.onGetAllNewstickersSucceeded({ payload: response })),
                    catchError((error) => of(fromActions.onGetAllNewstickersFailed({ payload: error }))),
                );
            }),
        ),
    );

    addNewsticker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addNewstickers.type),
            throttleTime(1000),
            mergeMap((action: fromActions.IActionWithPayload<INewsticker>) => {
                return this.service.addNewsticker(action.payload).pipe(
                    //retry(1),
                    map((response) => fromActions.onAddNewstickerSucceeded({ payload: response })),
                    catchError((error) => of(fromActions.onAddNewstickerFailed({ payload: error }))),
                );
            }),
        ),
    );

    successOnAddNewsticker$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onAddNewstickerSucceeded.type),
                tap((action: fromActions.IActionWithPayload<INewsticker>) => {
                    this.router.navigate([`/de-ch/newstickers/`]);
                }),
            ),
        { dispatch: false },
    );

    errorOnAddNewsticker$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onAddNewstickerFailed.type),
                tap((action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)),
            ),
        { dispatch: false },
    );

    updateNewsticker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateNewsticker.type),
            throttleTime(1000),
            mergeMap((action: fromActions.IActionWithPayload<INewsticker>) => {
                return this.service.updateNewsticker(action.payload).pipe(
                    // retry(1),
                    map((response) => fromActions.onUpdateNewstickerSucceeded({ payload: response })),
                    catchError((error) => of(fromActions.onUpdateNewstickerFailed({ payload: error }))),
                );
            }),
        ),
    );

    successOnUpdateNewsticker$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUpdateNewstickerSucceeded.type),
                tap((action: fromActions.IActionWithPayload<INewsticker>) => {
                    this.router.navigate([`/de-ch/newstickers/`]);
                }),
            ),
        { dispatch: false },
    );

    errorOnUpdateNewsticker$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.onUpdateNewstickerFailed.type),
                tap((action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllErrors(action.payload)),
            ),
        { dispatch: false },
    );

    handleOnLoadAllErrors(error) {
        debug(error);
        return error;
    }
}
