import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromActions from '../actions/admin-manageSpotLights.actions';
import * as fromFeature from '../reducers/admin.reducer';
import { ISpotLight } from '@modeso/types__dgoods-products';

const debug = Debug('modeso:dgoods-lib-admin-fe:AdminManageProductCategoryProvider');
@Injectable({ providedIn: 'root' })
export class AdminManageSpotLightsProvider {
    constructor(private store: Store<fromFeature.AppState>) {}

    public fetchSpotLights() {
        debug('Get All SpotLights from BE');
        this.store.dispatch(fromActions.getAllSpotLights());
    }

    public getSpotLights$(): Observable<ISpotLight[]> {
        debug('Get All SpotLights from store');
        return this.store.pipe(select(fromFeature.selectFeatureSpotLights));
    }

    public getSpotLightById$(spotlightId: string): Observable<ISpotLight> {
        debug('Get SpotLight By id called');
        return this.store.pipe(select(fromFeature.selectFeatureSpotLight(spotlightId)));
    }

    public createSpotLight(spotLight: ISpotLight) {
        this.store.dispatch(fromActions.createSpotLight({ payload: spotLight }));
    }

    public updateSpotLight(spotLight: ISpotLight) {
        this.store.dispatch(fromActions.updateSpotLight({ payload: spotLight }));
    }

    public storeCopiedSpotlight(spotLight: ISpotLight) {
        this.store.dispatch(fromActions.storeCopiedSpotLight({ payload: spotLight }));
    }

    public getCopiedSpotlight() {
        return this.store.pipe(select(fromFeature.selectCopiedSpotlight));
    }

    public removeCopiedSpotlightFromStore() {
        this.store.dispatch(fromActions.removeCopiedSpotLight());
    }

    public deleteSpotLight(id: string) {
        this.store.dispatch(fromActions.deleteSpotLight({ payload: id }));
    }

    public getSpotLightSetting$(): Observable<any> {
        debug('Get SpotLight called');
        this.store.dispatch(fromActions.getSpotLightSetting());
        return this.store.pipe(select(fromFeature.selectFeatureSpotLightSetting));
    }

    public updateSpotLightSetting$(spotLightSetting) {
        debug('Get SpotLight called');
        this.store.dispatch(fromActions.updateSpotLightSetting({ payload: spotLightSetting }));
    }

    public getError$(): Observable<HttpErrorResponse> {
        return this.store.pipe(
            select(fromFeature.featureError),
            filter((error: HttpErrorResponse) => error != null),
        );
    }
}
